import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RelatoriosZabbixService {
  
  apiUrl = "https://api-controller.vagalumewifi.com.br";

  constructor(private http: HttpClient) { }

  getClients(): Observable<any>{
    return this.http.get<any>(`${environment.configApiUrlCaptiveStaging}/clients?filter={"fields": ["groupZabbix"]}`)
  }

  getListHostByGroup(groupID): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getlistHostByGroup/${groupID}`);
  }

  getInterfaceLists(groupname): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getlistIfaceByGroup/${groupname}`)
  }

  getSsidLists(groupId): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getlistSsidByGroup/${groupId}`)
  }

  getReportDataLan(startdate, endDate, hostname, iface): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getTraficInHostByIface/${startdate}/${endDate}/${hostname}/${iface}`);
  }

  getReportSpeedigb(startdate, endDate, hostname, iface): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getTraficByIface/${startdate}/${endDate}/${hostname}/${iface}`);
  }

  getReportDataUsage(startdate, endDate, hostname, iface): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getDataUsage/${startdate}/${endDate}/${hostname}/${iface}`);
  }

  getReportAvailability(startdate, endDate, hostname): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getAvailability/${startdate}/${endDate}/${hostname}`);
  }
  
  getDataWlanNetOutByGroup(startDate, endDate, groupID, iface): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getExternalTraficByIface/${startDate}/${endDate}/${groupID}/${iface}`)
  }

  getDataWlanNetInByGroup(startDate, endDate, groupID, iface): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getInternalTraficByIface/${startDate}/${endDate}/${groupID}/${iface}`)
  }

  getDataUsageByGroup(startDate, endDate, groupID, iface): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getUsersAssociatedByIface/${startDate}/${endDate}/${groupID}/${iface}`)
  }

  getDataUsageBySsid(startDate, endDate, groupID, ssid): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getUsersAssociatedBySsid/${startDate}/${endDate}/${groupID}/${ssid}`)
  }

  getDatNetOutGroupBySsid(startDate, endDate, groupID, ssid): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getExternalTraficBySsid/${startDate}/${endDate}/${groupID}/${ssid}`)
  }
  
  getDataNetinGroupBySsid(startDate, endDate, groupID, ssid): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getInternalTraficBySsid/${startDate}/${endDate}/${groupID}/${ssid}`)
  }

  getDataUsageByTotalGroup(startDate, endDate, groupID, iface): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getTotalUsersAssociatedByGroup/${startDate}/${endDate}/${groupID}`);
  }

  getHostnames(groupID): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getlistHostByGroup/${groupID}`);
  }

  getDataUsageByHostname(startDate, endDate, hostname): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/zabbixReport/getUsersAssociatedByHostname/${startDate}/${endDate}/${hostname}`);
  }
}
