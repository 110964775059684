import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RelatoriosZabbixService } from '../services/relatorios-zabbix.service';
import { ChartOptions } from './shared/chartOptions';
const moment = require('moment');

@Component({
  selector: 'app-relatorios-zabbix',
  templateUrl: './relatorios-zabbix.component.html',
  styleUrls: ['./relatorios-zabbix.component.scss']
})
export class RelatoriosZabbixComponent implements OnInit {

  form: FormGroup;
  formPeriod: FormGroup;
  
  reportType: string = "";
  filterOn: boolean = false;
  maxValueOn: boolean = false;
  ssidOn: boolean = false;

  zabbixReport: boolean = false;

  clients: any[] = [];
  hostnames: any[] = [];
  interfacesSsid: any[] = [];
  loadingZabbixReport: boolean = false;

  initialDate: string = moment().subtract(7,"day").format().split('T')[0];
  endDate: string = moment().format().split('T')[0];

  chartOptions: Partial<ChartOptions>;
  validateDataLan: boolean = false;

  groupId: number;
  loadingInterfacesSsid: boolean = false;

  hostnamesList = [];
  loadingHostname: boolean = false;

  showOnlyGroup: boolean = false;
  showHostNames: boolean = false;
  

  gbList = [
    1000000,
    2000000,
    3000000,
    4000000,
    5000000
  ]

  @ViewChild("maxYaxis") maxYaxis: ElementRef;
  @ViewChild('teams') teams!: ElementRef;
	selectedTeam = '';

  constructor(private formBuilder: FormBuilder, private relatoriosZabbixService: RelatoriosZabbixService) {
    this.chartOptions = {
      series: [
        {
          name: "Media diária",
          data: []
        },
        {
          name: "Max Value",
          data: []
        },
        {
          name: "Min Radio",
          data: []
        },
        {
          name: "RadioWithMaxValue",
          data: []
        }
      ],
      chart: {
        height: 400,
        type: "line",

        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: false
        },

        toolbar: {
          autoSelected: 'zoom',
          show: true,
          export: {
            csv: {
              filename: '',
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            },
            svg: {
              filename: '',
            },
            png: {
              filename: '',
            }
          },
        },
      },
      dataLabels: {
        enabled: false,

      },
      stroke: {
        curve: "straight",
        width: 0.5,
        dashArray: [5, 0, 0, 0]
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        },
        xaxis: {
          lines: {
              show: true
          }
        },
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 30 
      },
      yaxis: {
        show: true,
        labels:{
          formatter: function(val: number): string {
    
              if (val < 500) {
                // Menos de500, considera estacao associada
                return (val).toFixed(0);
              } else if (val < 1e6) {
                // Menos de 1 Mbps, exibe em kbps
                return (val / 1e3).toFixed(2) + ' kbps';
              } else if (val < 1e9) {
                // Menos de 1 Gbps, exibe em Mbps
                return (val / 1e6).toFixed(2) + ' Mbps';
              } else {
                // Mais de 1 Gbps, exibe em Gbps
                return (val / 1e9).toFixed(2) + ' Gbps';
              }
            }
          
        },
        
        min: 0,
        max: undefined
      }
    };
   }

  ngOnInit(): void {
    this.initForm();
    this.initFormPeriod();
    this.getClientsData();
  }

  initForm(){
    this.form = this.formBuilder.group({
      selectReport: ['', Validators.required],
      selectClient: [''],
      selectFilter: [''],
      selectInterfaceSsid: ['']
    })
  }

  initFormPeriod(){
    this.formPeriod = this.formBuilder.group({
      initialDate: [moment().subtract(7,"day").format().split('T')[0]],
      endDate: [moment().format().split('T')[0]]
    })
  }

  changeDate(selectType){
    let initialDate = moment(this.formPeriod.controls['initialDate'].value);
    let endDate = moment(this.formPeriod.controls['endDate'].value);

    if (selectType == 1){
      this.formPeriod.controls['endDate'].setValue('');
    }
    
    if (endDate.isSameOrAfter(initialDate) && this.formPeriod.controls['endDate'].value != ''){
      this.initialDate = this.formPeriod.controls['initialDate'].value;
      this.endDate = this.formPeriod.controls['endDate'].value;

      if (this.form.controls['selectClient'].value != ''){
        this.getSelectedReport();
      }
    }
  }

  getClients(){
    this.form.controls['selectInterfaceSsid'].setValue('');
    this.form.controls['selectClient'].setValue('');

    if (this.form.controls['selectReport'].value == 'dataWlanNetOut' || this.form.controls['selectReport'].value == 'dataWlanNetIn'){
      this.filterOn = true;
    } else {
      this.filterOn = false;
    }

    if (this.form.controls['selectReport'].value == 'getDataUsageByTotalGroup'){
      this.showOnlyGroup = true;
    } else {
      this.showOnlyGroup = false;
    }

    if (this.form.controls['selectReport'].value == 'getDataUsageByHostname'){
      this.showHostNames = true;
    } else {
      this.showHostNames = false;
    }

    if (this.form.controls['selectReport'].value == 'getDataUsageSsid' || this.form.controls['selectReport'].value == 'getDatNetOutGroupBySsid' || this.form.controls['selectReport'].value == 'getDataNetinGroupBySsid'){
      this.ssidOn = true;
    } else {
      this.ssidOn = false;
    }
  }
  
  getClientsData(){
    this.relatoriosZabbixService.getClients().subscribe(
      (data) => {
        let clients = data.filter((obj) => obj.groupZabbix);
        this.clients = clients;
    })
  }

  // getListHost(){
  //   console.log(this.form.controls['selectClient'].value);
  //   this.relatoriosZabbixService.getListHostByGroup(this.form.controls['selectClient'].value)
  //   .subscribe(
  //     (data) => {
  //       this.hostnames = data;
  //   })
  // }
  
  getInterfacesSsid(){
    this.loadingInterfacesSsid = true;
    this.interfacesSsid = [];
    this.hostnamesList = [];
    
    let client = this.clients.filter((obj) => obj.groupZabbix.name == this.form.controls['selectClient'].value);
    
    if (this.ssidOn){
      this.relatoriosZabbixService.getSsidLists(client[0].groupZabbix.id)
      .subscribe(
        (data) => {
          this.interfacesSsid = data;
      }).add(() => {
        this.loadingInterfacesSsid = false;
      })
    } else if (this.showHostNames){
      this.loadingHostname = true;

      this.relatoriosZabbixService.getHostnames(client[0].groupZabbix.id)
      .subscribe(
        (data) => {
        this.hostnamesList = data;
      }).add(() => {
        this.loadingHostname = false;
      })
    } else {
      this.relatoriosZabbixService.getInterfaceLists(this.form.controls['selectClient'].value)
      .subscribe(
        (data) => {
          if (this.filterOn){
            if (this.form.controls['selectFilter'].value == "ap"){
              data.forEach(element => {
                if (element.includes('eth') || element.includes('wlan') || element.includes('wifinet')){
                  this.interfacesSsid.push(element);
                }
              });
            } else if (this.form.controls['selectFilter'].value == "swarm"){
              data.forEach(element => {
                if (element.includes('igb') || element.includes('en')){
                  this.interfacesSsid.push(element);
                }
              });
            }
          } else {
            this.interfacesSsid = data;
          }

      }).add(() => {
        this.loadingInterfacesSsid = false;
      })
    }

  }

  getReportInfo(value:any){
    let report = {
      name :"",
      id : 0
    }
    switch(value){
      case "dataWlanNetOut" : 
        report.name = "Tráfego de saída na interface";
        report.id = 1;
      break;
      case "dataWlanNetIn" : 
        report.name = "Tráfego de entrada na interface";
        report.id = 2;
      break;
      case "getDataUsage" : 
        report.name = "Estações associadas na Interface por AP";
        report.id = 3;
      break;
      case "getDataUsageSsid" : 
        report.name = "Estações associadas no SSID por AP";
        report.id = 4;
      break;
      case "getDatNetOutGroupBySsid" : 
        report.name = "Trafego de saída por SSID";
        report.id = 5;
      break;
      case "getDataNetinGroupBySsid" : 
        report.name = "Trafego de entrada por SSID";
        report.id = 6;
      break;
      case "getDataUsageByTotalGroup" :
        report.name = "getDataUsageByTotalGroup";
        report.id = 7;
      break;
      case "getDataUsageByHostname" :
        report.name = "getDataUsageByHostname";
        report.id = 8;
      break;
      
    }
    return report
  }

  getSelectedReport(){
    this.chartOptions.series = [
      {
        name: "Media diária",
        data: []
      },
      {
        name: "Max Value",
        data: []
      },
      {
        name: "Min Radio",
        data: []
      },
      {
        name: "RadioWithMaxValue",
        data: []
      }
    ]

    this.reportType = this.form.controls['selectReport'].value;
    let groupId = this.clients.filter((obj) => obj.groupZabbix.name == this.form.controls['selectClient'].value);
    this.groupId = groupId[0].groupZabbix.id;
    
    this.zabbixReport = true;
    this.loadingZabbixReport = true;

    if (this.reportType == 'dataWlanNetOut'){
      this.getReportData("getDataWlanNetOutByGroup");
    } else if (this.reportType == 'dataWlanNetIn'){
      this.getReportData("getDataWlanNetInByGroup");
    } else if (this.reportType == 'getDataUsage'){
      this.getReportData("getDataUsageByGroup");
    } else if (this.reportType == 'getDataUsageSsid'){
      this.getReportData("getDataUsageBySsid");
    } else if (this.reportType == 'getDatNetOutGroupBySsid'){
      this.getReportData('getDatNetOutGroupBySsid');
    } else if (this.reportType == 'getDataNetinGroupBySsid'){
      this.getReportData('getDataNetinGroupBySsid');
    } else if (this.reportType == 'getDataUsageByTotalGroup'){
      this.getReportData('getDataUsageByTotalGroup');
    } else if (this.reportType == 'getDataUsageByHostname'){
      this.getReportData('getDataUsageByHostname');
    }
  }

  getReportData(reportType){
    this.relatoriosZabbixService[reportType](this.initialDate, this.endDate, this.groupId, this.form.controls['selectInterfaceSsid'].value)
    .subscribe(
      (data) => {
        if (this.getReportInfo(reportType).id != 5) {//Tráfego na interface da Swarm
        this.chartOptions.series[0].data = data.avarageDay;
        this.chartOptions.series[0].name = "Média diária geral";
        
        this.chartOptions.series[1].data = data.minRadio.value;
        this.chartOptions.series[1].name = "Menor média: " + data.minRadio.hostName;

        this.chartOptions.series[2].data = data.maxRadio.value;
        this.chartOptions.series[2].name = "Maior média: " + data.maxRadio.hostName;
        
        if (data.maxRadio.hostName == data.radioWithMaxValue.hostName) {
          this.chartOptions.series.splice(3, 1);
        } else {
          this.chartOptions.series[3].data = data.radioWithMaxValue.value;
          this.chartOptions.series[3].name = "Maior pico: " + data.radioWithMaxValue.hostName;
          
        }
      } else {
        this.chartOptions.series[0].data = data.avarageDay;
        this.chartOptions.series[0].name = "Média diária";

        this.chartOptions.series.splice(1, 3);
      }
        
        this.chartOptions.xaxis.categories = data.ts;

        this.validateDataLan = true;
    }).add(() => {
      this.loadingZabbixReport = false;
    })
  }

  onSelected(){
    let maxYaxis = this.maxYaxis.nativeElement.value.replace(/£|\$|€|,|\./g,'');
    
    if (maxYaxis != ''){
      this.chartOptions.yaxis.max = Number(maxYaxis);
    } else {
      this.chartOptions.yaxis.max = undefined;
    }

    this.getSelectedReport();
  }


 
  // convertUnit(valorEmBps: number): string {
    
  //   if (valorEmBps < 500) {
  //     // Menos de500, considera estacao associada
  //     return (valorEmBps).toFixed(0);
  //   } else if (valorEmBps < 1e6) {
  //     // Menos de 1 Mbps, exibe em kbps
  //     return (valorEmBps / 1e3).toFixed(2) + ' kbps';
  //   } else if (valorEmBps < 1e9) {
  //     // Menos de 1 Gbps, exibe em Mbps
  //     return (valorEmBps / 1e6).toFixed(2) + ' Mbps';
  //   } else {
  //     // Mais de 1 Gbps, exibe em Gbps
  //     return (valorEmBps / 1e9).toFixed(2) + ' Gbps';
  //   }
  // }

}
