<ngb-tabset [activeId]="tabsOpts ? tabsOpts.defaultTabId : ''" class="tabs-vagalume">
  <ng-container  *ngFor="let tab of (tabsOpts ? tabsOpts.tabs : [])">
    <ngb-tab [id]="tab.id">
      <ng-template ngbTabTitle>
        <ng-container *ngTemplateOutlet="tab.title"></ng-container>
      </ng-template>
      <ng-template ngbTabContent>
        <ng-container *ngTemplateOutlet="tab.content"></ng-container>
      </ng-template>
    </ngb-tab>
  </ng-container>
</ngb-tabset>
