import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RootComponent } from './root/root.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './helpers/auth.guard';
import { ConfigurationService } from 'src/app/services/configuration.service';

const routes: Routes = [
  {
    path: '',
    component: RootComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Home'
    },
    children: [
      {
        path: 'vagalume-status',
        data: {
          breadcrumb: 'Vagalume Status'
        },
        loadChildren: () => import('./vagalume-status/vagalume-status.module').then(m => m.VagalumeStatusModule)
      },
      {
        path: 'service-status',
        data: {
          breadcrumb: 'Service Status'
        },
        loadChildren: () => import('./service-status/service-status.module').then(m => m.ServiceStatusModule)
      },
      {
        path: 'user',
        data: {
          breadcrumb: 'Users'
        },
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
      {
        path: 'organization',
        data: {
          //TODO remover aninhamento
          breadcrumb:
            ConfigurationService.prototype.getMode_url() == 'sedu'
              ? 'Escolas'
              : ConfigurationService.prototype.getMode_url() == 'elcoma'
              ? 'Organização'
              : 'Terminais'
        },
        loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule)
      },
      {
        path: 'bulkconfig',
        data: {
          breadcrumb: 'Configurações em Massa'
        },
        loadChildren: () => import('./bulkconfig/bulkConfig.module').then(m => m.BulkConfigModule)
      },
      {
        path: 'notifications-page',
        data: {
          breadcrumb: 'Notifications'
        },
        loadChildren: () =>
          import('./notifications-page/notifications-page.module').then(m => m.NotificationsPageModule)
      },
      {
        path: 'dashboard',
        data: {
          breadcrumb: 'Dashboard'
        },
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'maps',
        data: {
          breadcrumb: 'Maps'
        },
        loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)
      },
      {
        path: 'videos',
        data: {
          breadcrumb: 'Campanhas'
        },
        loadChildren: () => import('./videos/videos.module').then(m => m.VideosModule)
      },
      {
        path: 'perguntas',
        data: {
          breadcrumb: 'Enquetes'
        },
        loadChildren: () => import('./perguntas/perguntas.module').then(m => m.PerguntasModule)
      },
      // {
      //   path: 'sms',
      //   data: {
      //     breadcrumb: 'SMS'
      //   },
      //   loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)
      // },
      // {
      //   path: 'emails',
      //   data: {
      //     breadcrumb: 'E-mail'
      //   },
      //   loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)
      // },
      // {
      //   path: 'radios',
      //   data: {
      //     breadcrumb: 'Vagalumes'
      //   },
      //   loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)
      // },
      // {
      //   path: 'voucher',
      //   data: {
      //     breadcrumb: 'Voucher'
      //   },
      //   loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)
      // },
      {
        path: 'diagrama-fluxo',
        data: {
          breadcrumb: 'Diagrama Fluxo'
        },
        loadChildren: () => import('./diagrama-fluxo/diagrama-fluxo.module').then(m => m.DiagramaFluxoModule)
      },
      {
        path: 'publico',
        data: {
          breadcrumb: 'Público'
        },
        loadChildren: () => import('./publico/publico.module').then(m => m.PublicoModule)
      },
      {
        path: 'recurrence',
        data: {
          breadcrumb: 'Recorrência'
        },
        loadChildren: () => import('./recurrence/recurrence.module').then(m => m.RecurrenceModule)
      },
      {
        path: 'relatorios',
        data: {
          breadcrumb: 'Relatórios'
        },
        loadChildren: () => import('./relatorios/relatorios.module').then(m => m.RelatoriosModule)
      },
      // {
      //   path: 'clientes',
      //   data: {
      //     breadcrumb: 'Clientes'
      //   },
      //   loadChildren: () => import('./clientes/clientes.module').then(m => m.ClientesModule)
      // },
      {
        path: 'relatorios-zabbix',
        data: {
          breadcrumb: 'Relatórios Zabbix'
        },
        loadChildren: () => import('./relatorios-zabbix/relatorios-zabbix.module').then(m => m.RelatoriosZabbixModule)
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
