  <!-- AP Detail Component -->
<div class="ap-detail-table bg-light" style="overflow-y: auto;">
  <div class="d-flex p-3 header" >
    <div class="col-md">
      <div class="row">

        <div class="col-md pl-0">
          <span class="text-primary pointer" (click)="onVisualizarAPClick()">
            {{ ap.name }}
            <img *ngIf="ap['outdated']" src="assets/icons/load.svg" width="26" ngbTooltip="Dados estão sendo atualizados"/>
          </span>
        </div>
        <!-- <div class="col-1">
          <span class="text-primary pointer">
            <img *ngIf="ap.la && ap.la > 0.75" src="assets/icons/sobrecarga.png" width="26" ngbTooltip="Sobrecarga."/>
          </span>
        </div>
        <div class="col-1">
          <span class="text-primary pointer">
            <img *ngIf="ap.mac && ap.mac == group.mac_reference" src="assets/icons/referencia.png" width="26" ngbTooltip="AP Referência."/>
          </span>
        </div>
        <div class="col-1">
          <span class="text-primary pointer">
            <img *ngIf="different" src="assets/icons/diferente.png" width="26" ngbTooltip="Configuração Diferente da Referência."/>
          </span>
        </div> -->


      </div>
    </div>
    <div class="col-6">
      <div class="row justify-content-end">
        <!--

          <div class="col-4">
            <span class="history">Histórico: </span>

            <span class="history-badge bg-danger pt-1 pb-1">{{ ap['historic'] ? ap['historic'] : 0 }}</span>
          </div>
        -->
        <div class="col-4">
          <span class="online-badge {{ap['uptime'] && !ap['flag_wisp'] ? 'bg-success' : 'bg-warning'}} pt-1 pb-1">{{ap['uptime'] && !ap['flag_wisp'] ? 'Online' : 'Offline'}} <!-- ({{ totalOnliner }}) --> </span>
        </div>
      </div>
    </div>
  </div>
  <!-- Body
    <form [formGroup]="apForm" (ngSubmit)="onFilterSubmit(apForm)">
      <div formArrayName="radios">

      -->

  <div class="d-flex body">
    <div class="col border-right">
      <div class="row border-bottom justify-content-start pl-3 mt-1">
        <p class="title font-weight-bold">Geral</p>
      </div>
      <div class="general-col mt-2 ml-2 mr-2">
        <div class="row">
          <div class="ml-2 mr-2">
            <p class="subtitle text-primary">{{ getAPIP() }}</p>
          </div>
        </div>
        <div class="row">
          <div class="ml-2 mr-2">
            <p class="subtitle">Uptime:</p>
          </div>
          <div class="ml-2 mr-2">
            <p class="value">{{ ap['uptime'] | secToDhms }}</p>
          </div>
        </div>
        <div class="row">
          <div class="ml-2 mr-2">
            <p class="subtitle">Firmware:</p>
          </div>
          <div class="ml-2 mr-2">
            <p class="value">{{ ap['firmware'] }}</p>
          </div>
        </div>
        <div class="row">
          <div class="ml-2 mr-2">
            <!-- en-us <p class="subtitle">Version:</p> -->
            <p class="subtitle">End. MAC:</p>
          </div>
          <div class="ml-2 mr-2">
            <p class="value">{{ ap['mac'] }}</p>
          </div>
        </div>
        <div class="row">
          <div class="ml-2 mr-2">
            <p class="subtitle">Mapa:</p>
          </div>
          <div class="ml-2 mr-2">
            <p *ngIf="ap['interfaces'] == 0" class="value"><i class="fas fa-times"></i></p>
            <p *ngIf="ap['interfaces'] == 1" class="value"><i class="fas fa-check"></i></p>
            <p *ngIf="ap['interfaces'] > 1" class="value"><i class="fas fa-exclamation"></i></p>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col">
            
            <p class="subtitle">IP:</p>
          </div>
          <div class="col">
            <p class="value">{{ ap['ip'] }}</p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="col-10 table-no-gutters">
      <table class="table table-bordered" style="overflow-y: auto;" *ngIf="ap['hostid'] != '' else noData">
        <thead>
          <!-- en-us <tr>
            <th scope="col">Interface</th>
            <th scope="col">MAC Address</th>
            <th scope="col">SSID</th>
            <th scope="col">Channel</th>
            <th scope="col">HT</th>
            <th scope="col">Tx-Power</th>
            <th scope="col">Signal Avg</th>
            <th scope="col">Noise</th>
            <th scope="col">Stations</th>
          </tr> -->
          <tr>
            <th scope="col">Interface</th>
            <th scope="col">Endereço MAC</th>
            <th scope="col">SSID</th>
            <th scope="col">Canal</th>
            <th scope="col">HT</th>
            <th scope="col">Tx-Power</th>
            <th scope="col">Sinal Médio</th>
            <th scope="col">Ruído</th>
            <th scope="col">Estações</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let radio of ap.vagalumeRadios; let i = index">
            <!-- <ng-container *ngFor="let radio of apForm.get('radios').controls; let i = index">-->
              <ng-container *ngFor="let interface of radio.vagalumeInterfaces; let j = index">
                <tr>
                  <td>
                    <ng-container>
                      {{ interface.name }}
                    </ng-container>
                    <img *ngIf="interface['outdated']" src="assets/icons/load.svg" width="26" ngbTooltip="Interface está sendo atualizada."/>
                  </td>
                  <td class="{{interface.mac == 'Desativada' ? 'text-primary' : '' }}">
                    <ng-container>
                      {{ interface.mac }}
                    </ng-container>
                  </td>
                  <td>
                    <ng-container>
                      {{ interface.ssid }}
                    </ng-container>
                  </td>
                  <td class="pt-1 pb-1" *ngIf="j < 1" [attr.rowspan]="radio.vagalumeInterfaces.length">
                    <div *ngIf="radio.channel <= 11" style="background-color: rgb(235, 235, 235); border-radius: 5px; border: solid 1px rgb(179, 179, 179); padding: 5px">
                      {{ radio.channel }}
                    </div>

                    <div *ngIf="radio.channel > 11" style="background-color: rgb(235, 235, 235); border-radius: 5px; border: solid 1px rgb(179, 179, 179); padding: 5px">
                      {{ radio.channel }}
                    </div>
                  </td>
                  
                <td class="pt-1 pb-1" *ngIf="j < 1" [attr.rowspan]="radio.vagalumeInterfaces.length">
                  <div style="background-color: rgb(235, 235, 235); border-radius: 5px; border: solid 1px rgb(179, 179, 179); padding: 5px">
                    {{ radio.htmode }}
                  </div>
                </td>

                <td class="pt-1 pb-1" *ngIf="j < 1" [attr.rowspan]="radio.vagalumeInterfaces.length">
                  <div style="background-color: rgb(235, 235, 235); border-radius: 5px; border: solid 1px rgb(179, 179, 179); padding: 5px">
                    {{ radio.txpower }}
                  </div>
                </td>
                
                <td>
                  <ng-container>
                    {{  (interface.stations_count != "0") ? interface.signal + " dBm": 0 }}
                    </ng-container>
                  </td>
                <td class="bg-light-success">
                  <ng-container>
                    {{ radio.noise + " dBm" }}
                    </ng-container>
                  </td>
                <td>{{ interface.stations_count }}</td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <!--
  </div>
</form>
-->
</div>

<ng-template #noData>
  <div class="text-center mt-4" style="font-size: 14px;">
    Sem dados para esse AP.
  </div>
</ng-template>
