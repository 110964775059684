import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChartDataSets } from 'chart.js';
import { DashboardService } from '../services/dashboard.service';
import { SingleDataSet } from 'ng2-charts';
import { PublicoService } from '../services/publico.service';
const moment = require('moment');
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { SharedService } from '../services/shared.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-publico',
  templateUrl: './publico.component.html',
  styleUrls: ['./publico.component.scss']
})
export class PublicoComponent implements OnInit {

  controllerId = JSON.parse(localStorage.getItem('currentClient')).controller_id;
  client = JSON.parse(localStorage.getItem('currentClient')).name;
  clientId = JSON.parse(localStorage.getItem('currentClient')).id;

  leads: any[] = [];
  keys: any[] = [];
  leadsOn: boolean = false;
  loadingLeads: boolean = false;
  p: number = 1;

  form: FormGroup;

  initialDate: string = moment().subtract(7,"day").format().split('T')[0];
  endDate: string = moment().format().split('T')[0];

  // Faixa Etária
  ageGroupData: ChartDataSets[] = [];
  sumAgeGroupData = 0;
  ageGroupLabels: any[] = [];
  index: number = 0;
  graphObject: any = {
    data: [],
    label: ''
  }
  loading: boolean = false;
  validateAgeGroupData: boolean = false;
  ageGroupOptions = {
    scaleShowVerticalLines: false,
    legend: {
      display: false,
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            suggestedMin: 0
          }
        }
      ]
    }
  };

  // Gênero
  genderData: SingleDataSet = [];
  genderLabels = [];
  genderOptions = {
    responsive: false,
    legend: { 
      position: 'right'
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] + '%';
          }
        }
      }
      
  };
  
  // Faixa Etária e Gênero
  ageGroupGender: ChartDataSets[] = [];
  indexAgeGroupGender: number = 0;
  validateAgeGroupGenderData: boolean = false;
  sumAgeGroupGender: number = 0;
  ageGroupGenderLabels = [];
  
  arrFem = {
    label: 'Feminino',
    data: []
  }
  arrMasc = {
    label: 'Masculino',
    data: []
  }
  arrNotInf = {
    label: 'Não Informado/Outros',
    data: []
  }

  // Fields
  fields: any;
  hasGender: boolean = false;
  hasBirth_date: boolean = false;

  constructor(private formBuilder: FormBuilder, private dashboardService: DashboardService, private publicoService: PublicoService, public sharedService: SharedService) { }

  ngOnInit() {
    this.initializeForm();
    this.getAuthenticationsData();
    this.checkDataFromClient();
    this.getLeads();
  }

  initializeForm(){
    this.form = this.formBuilder.group({
      initialDate: [moment().subtract(7,"day").format().split('T')[0]],
      endDate: [moment().format().split('T')[0]]
    })
  }
  
  changeDate(){
    let initialDate = moment(this.form.controls['initialDate'].value);
    let endDate = moment(this.form.controls['endDate'].value);
    
    if (endDate.isSameOrAfter(initialDate)){
      this.initialDate = this.form.controls['initialDate'].value;
      this.endDate = this.form.controls['endDate'].value;

      this.index = 0;
      this.indexAgeGroupGender = 0;
      this.graphObject.data = [];
      this.arrFem.data = [];
      this.arrMasc.data = [];
      this.arrNotInf.data = [];
      this.ageGroupData = [];
      this.ageGroupGender = [];
      this.genderData = [];
      this.getAuthenticationsData();
    }
  }

  getLeads(){
    this.loadingLeads = true;
    
    this.dashboardService.getLeads(this.controllerId, this.initialDate, this.endDate)
    .subscribe(
      (data) => {
        if (data.length > 0){
          this.leads = data;

          this.keys = Object.keys(this.leads[0]);
        } else {
          this.leads = [];
        }
    },
    (error) => {
      console.log(error);
    },
    () => {
      this.loadingLeads = false;
    })
  }

  downloadCsv(){
    this.dashboardService.getLeads(this.controllerId, this.initialDate, this.endDate)
    .subscribe(
      (data) => {
        this.downloadFile(data);
    })
  }

  downloadFile(data: any) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, 'leads');
  }
  
  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + `_${this.controllerId}` + EXCEL_EXTENSION);
  }

  getAuthenticationsData(){
    this.validateAgeGroupData = false;
    this.loading = true;

    this.ageGroupLabels = ['0 a 14', '15 a 20', '21 a 30', '31 a 40', '41 a 50', '51 a 60', '61 ou mais', 'Não informado'];
    this.genderLabels = ['Feminino', 'Masculino', 'Não informado'];
    this.ageGroupGenderLabels = ['0 a 14', '15 a 20', '21 a 30', '31 a 40', '41 a 50', '51 a 60', '61 ou mais', 'Não informado']

    this.dashboardService.getSummaries(this.controllerId, this.initialDate, this.endDate).subscribe((data) => {
      if (data){
        this.getAgeGroup(data);
        this.getGenderData(data);
        this.getAgeGroupGender(data);
      }
    }).add(() => {
      this.loading = false;
    })
  }

  getAgeGroup(data){
    let sum = 0;

    data.forEach(element => {
      element.age_ranges[this.index].forEach(element => {
        sum += element;
      });
    });

    this.sumAgeGroupData = this.sumAgeGroupData = sum;
    this.graphObject.data.push(sum);
    
    this.index++;

    if (this.index == 8){
      if (this.sumAgeGroupData > 0){
        this.ageGroupData.push(this.graphObject);
        this.validateAgeGroupData = true;
      } else {
        this.ageGroupData = [];
        this.validateAgeGroupData = false;
      }
    }

    if (this.index < 8){
      this.getAgeGroup(data);
    }
  }

  getGenderData(data){
    let sumMasc = 0;
    let sumFem = 0;
    let sumNotInf = 0;

    let total = 0;

    let obj: any[] = [];

    data.forEach(element => {
      element.age_ranges.forEach(element => {
        sumMasc += element[0];
        sumFem += element[1];
        sumNotInf += element[2];
      });
    });

    total = sumMasc + sumFem + sumNotInf;

    if (total > 0){
      obj.push((sumFem / total * 100).toFixed(1));
      obj.push((sumMasc / total * 100).toFixed(1));
      obj.push((sumNotInf / total * 100).toFixed(1));

      this.genderData.push(obj);
    } else {
      this.genderData = [];
    }

    
  }

  getAgeGroupGender(data){
    let sumMasc = 0;
    let sumFem = 0;
    let sumNotInf = 0;

    data.forEach(element => {
      sumMasc += element.age_ranges[this.indexAgeGroupGender][0];
      sumFem += element.age_ranges[this.indexAgeGroupGender][1];
      sumNotInf += element.age_ranges[this.indexAgeGroupGender][2];
    })

    this.sumAgeGroupGender = this.sumAgeGroupGender + sumMasc + sumFem + sumNotInf;
    this.arrFem.data.push(sumFem);
    this.arrMasc.data.push(sumMasc);
    this.arrNotInf.data.push(sumNotInf);
    
    this.indexAgeGroupGender++;

    if (this.indexAgeGroupGender == 8){
      if (this.sumAgeGroupGender > 0){
        this.ageGroupGender.push(this.arrFem);
        this.ageGroupGender.push(this.arrMasc);
        this.ageGroupGender.push(this.arrNotInf);

        this.validateAgeGroupGenderData = true;
      } else {
        this.ageGroupGender = [];
        this.validateAgeGroupGenderData = false;
      }
      
    }

    if (this.indexAgeGroupGender < 8){
      this.getAgeGroupGender(data);
    }
  }

  checkDataFromClient(){
    this.publicoService.getClientFields(this.clientId)
    .subscribe(
      (data) => {
        if (data){
          this.fields = data.fields.filter(
            el => el.type != "label" && el.is_active
          );
  
          this.fields = this.fields.map(el => el.name);
  
          this.fields.forEach(el => {
            if (el === "gender") {
              this.hasGender = true;
              
            }
            if (el === "birth_date") {
              this.hasBirth_date = true;
            }
          });
        }
      })
  }

}
