import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DiagramaFluxoRoutingModule } from './diagrama-fluxo-routing.module';
import { DiagramaFluxoComponent } from './diagrama-fluxo.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    DiagramaFluxoComponent
  ],
  imports: [
    CommonModule,
    DiagramaFluxoRoutingModule,
    GoogleChartsModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class DiagramaFluxoModule { }
