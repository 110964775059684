import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Task } from '../data/api/task.model';
import { User } from '../data/models/user.model';
import { AuthenticationService } from '../services/authentication.service';
import { ModalChangeClientsService } from '../services/modal-change-clients.service';

@Injectable()
export class LoginService {
  loginTask: Task<User> = Task.idle();

  groupsFather: any;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private modalChangeClientsService: ModalChangeClientsService
  ) {}

  async onSubmit(username: string, password: string) {
    this.loginTask = Task.inProgress();
    this.loginTask = await this.authenticationService.login(username, password);
    if (this.loginTask.isSuccessful) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      let user = JSON.parse(localStorage.getItem('currentUser')).user;

      localStorage.setItem('validateMap', '');
      localStorage.setItem('vagamais', '');
      if ('group' in user) {
        if (user.group.text == 'ROOT' || user.group.text != '') {
          this.modalChangeClientsService.getFatherGroup().subscribe(data => {
            if (user.group.text == 'ROOT') {
              this.groupsFather = data.filter(obj => obj.father == 'ROOT' || obj.father == '');
              this.getOrganizationByFatherId(this.groupsFather[0].id, this.groupsFather[0].text);
            } else {
              this.groupsFather = data.filter(obj => obj.text == user.group.text);
              this.getOrganizationByFatherId(this.groupsFather[0].id, this.groupsFather[0].text);
            }
          });
        } else {
          this.getClientsByControllerId(user);
        }
      } else {
        this.getClientsByControllerId(user);
      }
      if (currentUser.user.userid) {
        this.authenticationService.getClients(currentUser.user.userid).subscribe(data => {
          if (data) {
            if (data.group) {
              localStorage.setItem('group', 'true');
            } else {
              localStorage.setItem('group', 'false');
            }
          }
        });
      }
    }
  }

  getClientsByControllerId(user) {
    this.modalChangeClientsService.getClientsByControllerId(user.client.controller_id).subscribe(data => {
      localStorage.setItem('currentClient', JSON.stringify(data[0]));
      localStorage.setItem('clientName', data[0].name);

      if (data[0].vagamais) {
        localStorage.setItem('vagamais', 'true');
      } else {
        localStorage.setItem('vagamais', 'false');
      }

      this.router.navigate(['/']);
    });
  }

  getOrganizationByFatherId(fatherId, text) {
    this.modalChangeClientsService.getClientsByFatherId(fatherId, text).subscribe(data => {
      if (data) {
        localStorage.setItem('currentClient', JSON.stringify(data[0]));
        localStorage.setItem('clientName', data[0].name);

        if (data[0].vagamais) {
          localStorage.setItem('vagamais', 'true');
        } else {
          localStorage.setItem('vagamais', 'false');
        }

        this.router.navigate(['/']);
      }
    });
  }
}
