<div class="col-12">
  <div class="list-item row border-bottom pb-2 pt-2">
    <div ngif class="col-1 align-self-center">
      <div class="justify-content-start">
        <img [src]="listItem.icon" />
      </div>
    </div>
    <div class="col">
      <div class="row">
        <div class="col description">
          <span>{{ listItem.description }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col sub-description">
          <small>{{ listItem.subDescription }}</small>
        </div>
      </div>
    </div>
    <div *ngIf="listItem.actionsColumn" class="col item-actions">
      <div class="row justify-content-end">
        <button
          class="btn btn-light"
          type="button"
          (click)="isCollapsed = !isCollapsed"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          ...
        </button>
      </div>
    </div>
    <div [collapse]="isCollapsed">
      <div class="card card-body">
        {{ listItem.content }}
      </div>
    </div>
  </div>
</div>
