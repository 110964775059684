import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { RootComponent } from './root/root.component';
import { SharedModule } from './shared/shared.module';
import { CollapseModule } from './util/collapse';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { fakeBackendProvider } from './helpers/fake-backend';
import { LoginComponent } from './login/login.component';

//SELECT
import { SelectDropDownModule } from 'ngx-select-dropdown';
//LA
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as solidGauge from 'highcharts/modules/solid-gauge.src';
import { DashboardModule } from './dashboard/dashboard.module';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { MenuComponent } from './menu/menu.component';
import { MapsModule } from './maps/maps.module';
import { DiagramaFluxoModule } from './diagrama-fluxo/diagrama-fluxo.module';
import { MarkerService } from './maps/shared/marker.service';
import { PublicoModule } from './publico/publico.module';
import { RecurrenceModule } from './recurrence/recurrence.module';
import { ClientesModule } from './clientes/clientes.module';
import { RelatoriosComponent } from './relatorios/relatorios.component';
import { RelatoriosModule } from './relatorios/relatorios.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { RelatoriosZabbixModule } from './relatorios-zabbix/relatorios-zabbix.module';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    AppComponent,
    RootComponent,
    BreadcrumbsComponent,
    LoginComponent,
    MenuComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    SelectDropDownModule,
    ChartModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule,
    CollapseModule,
    SharedModule,
    DashboardModule,
    MapsModule,
    ChartsModule,
    DiagramaFluxoModule,
    PublicoModule,
    RecurrenceModule,
    ClientesModule,
    RelatoriosModule,
    RelatoriosZabbixModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, solidGauge] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ThemeService,
    MarkerService,
    // provider used to create fake backend
    fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
