import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'secToDhms' })
export class SecondsToDhmsPipe implements PipeTransform {
  transform(value: string): string {
    return value === undefined || value === null ? null : secToDhms(value);
  }
}

const secToDhms = seconds => {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);
  // en-us var dDisplay = d > 0 ? d + ' days ' : '';
  var dDisplay = d > 0 ?( d > 1 ? d + ' dias ' : d + ' dia ' ) : '';
  var hDisplay = h > 0 && d == 0 ? h + 'h ' : '';
  var mDisplay = m > 0 && d == 0  ? m + 'm ' : '';
  var sDisplay = s > 0 && d == 0  ? s + 's' : '';
  return dDisplay + hDisplay + mDisplay + sDisplay;
};
