import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { ClientService } from 'src/app/services/client.service';
import { ModalChangeClientsService } from 'src/app/services/modal-change-clients.service';
import { ValidateMenuService } from 'src/app/services/validate-menu.service';

@Component({
  selector: 'app-modal-change-clients',
  templateUrl: './modal-change-clients.component.html',
  styleUrls: ['./modal-change-clients.component.scss']
})
export class ModalChangeClientsComponent implements OnInit {
  @Output() groupsByClient: EventEmitter<any> = new EventEmitter();

  form: FormGroup;
  groupsFather: any;
  groups: any;
  clients: any;

  allGroups: any;

  loadingClients: boolean = false;

  user = JSON.parse(localStorage.getItem('currentUser')).user;
  userClient = JSON.parse(localStorage.getItem('currentUser')).user.client;

  groupId: string;
  isClient: boolean = false;
  userType: string = '';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private modalChangeClientsService: ModalChangeClientsService,
    public client: ClientService,
    private validateMenuService: ValidateMenuService
  ) {}

  ngOnInit(): void {
    this.initializeForm();

    this.validateUser();

    this.getClientsMaceio();
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      groupsFather: [''],
      organization: ['']
    });
  }

  getClientsMaceio() {
    this.modalChangeClientsService.getClientsMaceio().subscribe(data => {
      localStorage.setItem('clientMaps', JSON.stringify(data));
    });
  }

  validateUser() {
    if ('group' in this.user) {
      if (this.user.group.text == 'ROOT' || this.user.group.text != '') {
        this.getGroups();
      } else {
        this.isClient = true;
        this.getClientsByControllerId();
      }
    } else {
      this.isClient = true;
      this.getClientsByControllerId();
    }
  }

  getGroups() {
    this.modalChangeClientsService.getFatherGroup().subscribe(data => {
      if (this.user.group.text == 'ROOT') {
        this.groupsFather = data;
      } else {
        this.groupsFather = data.filter(obj => obj.text == this.user.group.text);
      }
    });
  }

  getOrganizationByFatherId(fatherId, text) {
    this.groups = [];
    this.modalChangeClientsService.getClientsByFatherId(fatherId, text).subscribe(data => {
      if (data) {
        this.groups = data;
        this.groupsByClient.emit(data);
      }
    });
  }

  getOrganization() {
    this.form.controls['organization'].setValue('');
    let text = this.groupsFather.filter(obj => obj.id == this.form.controls['groupsFather'].value);
    this.getOrganizationByFatherId(this.form.controls['groupsFather'].value, text[0].text);
  }

  getClientData() {
    let client = this.groups.filter(obj => obj.id == this.form.controls['organization'].value);

    if (client.length > 0) {
      localStorage.setItem('currentClient', JSON.stringify(client[0]));
      localStorage.setItem('clientName', client[0].name);

      if (client[0].main_controller_id == 'tacaruna') {
        this.validateMenuService.validateDiagram = true;
      } else {
        this.validateMenuService.validateDiagram = false;
      }

      if (client[0].coordinates != undefined) {
        localStorage.setItem('validateMap', 'true');
      } else {
        localStorage.setItem('validateMap', 'false');
      }

      if (client[0].vagamais) {
        localStorage.setItem('vagamais', 'true');
      } else {
        localStorage.setItem('vagamais', 'false');
      }

      this.client.setClient(1);
    } else {
      this.client.setClient(1);
    }
  }

  getClientsByControllerId() {
    this.modalChangeClientsService.getClientsByControllerId(this.user.client.controller_id).subscribe(data => {
      this.groups = data;
    });
  }
}
