import { Component } from '@angular/core';
import { ClientService } from './../../../services/client.service';
import { ValidateMenuService } from 'src/app/services/validate-menu.service';
@Component({
  selector: 'app-search-bar-clients',
  templateUrl: './search-bar-clients.component.html',
  styleUrls: ['./search-bar-clients.component.scss'],
  providers: [ClientService]
})
export class SearchBarClientComponent {
  constructor(public client: ClientService, private validateMenuService: ValidateMenuService) {}
  public isSearchActive = false;
  public shoppings = [];
  public buttomText = 'Mudar Cliente';
  public clients: any;
  public loading = false;
  config = {
    displayKey: 'name', //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: this.getClientName() ? this.getClientName() : 'Select Client', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    //limitTo:10,// a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    //limitto:10,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Procurar', // label thats displayed in search input,
    //en-us searchPlaceholder:'Search', // label thats displayed in search input,
    //searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
  };

  clientName: string;
  async loadClients() {
    this.clients = await this.client.getClients();
    if (this.clients) {
      // this.clientName = this.clients[0].name;
      // this.client.getClientsByGroupId(this.clients.user.group.id)
      // .subscribe(
      //   (data) => {
      //     localStorage.setItem('client', JSON.stringify(data[0]));
      // })
    }
    localStorage.setItem('currentGroupClients', JSON.stringify(this.clients));
    return [];
    // return this.clients.map(el => {
    //   if(el.name.split(" ")[1]){
    //     let name_parcial = el.name.split(" ")
    //     name_parcial[0] = name_parcial[0].toUpperCase()
    //     el.name = name_parcial.join(" ")
    //   }
    //   return [{name:el.name,id:el.id,controller_id : el.controller_id,openwisp:el.openwisp}];
    // });
  }

  async loadPostSchool() {
    this.loading = true;
    this.clients = await this.client.getClients();
    localStorage.setItem('currentGroupClients', JSON.stringify(this.clients));
    // localStorage.setItem('currentClient', JSON.stringify(this.clients[this.clients.length - 1]));
    this.client.setClient(1);
  }

  getClientName() {
    if (localStorage.getItem('currentClient') && localStorage.getItem('currentClient') != 'undefined') {
      return JSON.parse(localStorage.getItem('currentClient')).name;
    }
  }
  async ngDoCheck() {
    this.config = {
      displayKey: 'name', //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: '250px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: this.getClientName() ? this.getClientName() : 'Select Client', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      //limitTo:10,// a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      //limitto:10,
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Procurar', // label thats displayed in search input,
      //en-us searchPlaceholder:'Search', // label thats displayed in search input,
      //searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
      inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
    };
  }
  async ngOnInit() {
    this.shoppings = await this.loadClients();
    if (localStorage.getItem('currentClient') != 'undefined' && JSON.parse(localStorage.getItem('currentClient'))) {
    } else {
      localStorage.setItem('currentClient', JSON.stringify(this.clients));
    }
    if (localStorage.getItem('currentClient') != 'undefined' && !JSON.parse(localStorage.getItem('currentClient'))) {
      this.client.setClient(1);
    }
    setInterval(async () => {
      if (localStorage.getItem('loadPostClient')) {
        localStorage.removeItem('loadPostClient');
        await this.loadPostSchool();
        this.loading = false;
      }
    }, 250);
  }
  selectionChanged(client) {
    this.getClientInfo(client.value.id);
    if (client.value) {
      localStorage.setItem('currentClient', JSON.stringify(client.value));
      if (client.value.main_controller_id == 'tacaruna') {
        this.validateMenuService.validateDiagram = true;
      } else {
        this.validateMenuService.validateDiagram = false;
      }
      this.client.setClient(1);
    } else {
      localStorage.setItem('currentClient', JSON.stringify(this.clients[0]));
      this.client.setClient(1);
    }
  }

  onClick(e) {
    if (e.target.textContent) {
      this.clients.map(el => {
        if (el.name == e.target.textContent) {
          localStorage.setItem('currentClient', JSON.stringify(el));
          this.client.setClient(1);
        }
      });
    }
    this.isSearchActive = true;
  }

  getClientInfo(id) {
    this.client.getClientsByGroupId(id).subscribe(data => {
      localStorage.setItem('groupZabbixName', JSON.stringify(data[0].groupZabbix.name));
    });
  }
}
