<div *ngIf="show">

  <app-card class="history" [cardOpts]="cardOpts">
    <div actions>
      <div class="row align-items-center justify-content-end">
        <div class="col justify-content-end">
          <div class="row justify-content-end align-items-center">
            <div class="btn-toolbar" role="toolbar">
              <div class="btn-group mr-2" role="group">
                <button type="button" class="btn btn-light" (click)="showHide()" >
                  Hide
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--
      <div actions>
        <div class="row justify-content-end">

          <div class="select-date text-dark bg-light p-2">
            <img src="../../../assets/icons/calendar.svg" class="calendar-icon ml-2 mr-2" />
          <span>26/07/2018 - 26/07/2018</span>
          <img src="../../../assets/icons/arrow-down.svg" class="arrow-icon mt-2 ml-2 mr-2" />
        </div>
      </div>
    </div>
  -->
  
  <div content *ngIf="show">
    <div class="mt-2 body">
      <ul class="timeline">
        <li class="online">
          <span class="tag online mr-3 p-2">Online</span>
          <span class="text-dark">9 de Junho ás 20:00</span>
        </li>
        <li class="offline">
          <span class="tag offline mr-3 p-2">Offline</span>
          <span class="text-dark">9 de Junho ás 20:00</span>
        </li>
        <li class="normal">
          <span class="tag normal mr-3 p-2">Lorem</span>
          <span class="text-dark">9 de Junho ás 20:00</span>
        </li>
        <li class="normal">
          <span class="tag normal mr-3 p-2">Lorem</span>
          <span class="text-dark">9 de Junho ás 20:00</span>
        </li>
        <li class="online">
          <span class="tag online mr-3 p-2">Online</span>
          <span class="text-dark">9 de Junho ás 20:00</span>
        </li>
        <li class="offline">
          <span class="tag offline mr-3 p-2">Offline</span>
          <span class="text-dark">9 de Junho ás 20:00</span>
        </li>
        <li class="online">
          <span class="tag online mr-3 p-2">Online</span>
          <span class="text-dark">9 de Junho ás 20:00</span>
        </li>
        <li class="offline">
          <span class="tag offline mr-3 p-2">Offline</span>
          <span class="text-dark">9 de Junho ás 20:00</span>
        </li>
      </ul>
    </div>
  </div>
</app-card>
</div>

<!-- 
<div class="card history m-2">
  <div class="d-flex p-2 header">
    <div class="col-4 p-2">
      <div class="row ml-3">
        <img src="../../../assets/icons/vagalume-status-selected.svg" class="mr-2" />
        <p class="title">Histórico</p>
      </div>
    </div>
    <div class="col-8 d-flex justify-content-around">
      <div class="row select-date p-2">
        <img src="../../../assets/icons/calendar.svg" class="calendar-icon ml-2 mr-2" />
        <span>26/07/2018 - 26/07/2018</span>
        <img src="../../../assets/icons/arrow-down.svg" class="arrow-icon mt-2 ml-2 mr-2" />
      </div>
    </div>
  </div>

  <div class="mt-2 body">
    <ul class="timeline">
      <li class="online">
        <span class="tag online mr-3 p-2">Online</span>
        <span class="date">9 de Junho ás 20:00</span>
      </li>
      <li class="offline">
        <span class="tag offline mr-3 p-2">Offline</span>
        <span class="date">9 de Junho ás 20:00</span>
      </li>
      <li class="normal">
        <span class="tag normal mr-3 p-2">Lorem</span>
        <span class="date">9 de Junho ás 20:00</span>
      </li>
      <li class="normal">
        <span class="tag normal mr-3 p-2">Lorem</span>
        <span class="date">9 de Junho ás 20:00</span>
      </li>
      <li class="online">
        <span class="tag online mr-3 p-2">Online</span>
        <span class="date">9 de Junho ás 20:00</span>
      </li>
      <li class="offline">
        <span class="tag offline mr-3 p-2">Offline</span>
        <span class="date">9 de Junho ás 20:00</span>
      </li>
      <li class="online">
        <span class="tag online mr-3 p-2">Online</span>
        <span class="date">9 de Junho ás 20:00</span>
      </li>
      <li class="offline">
        <span class="tag offline mr-3 p-2">Offline</span>
        <span class="date">9 de Junho ás 20:00</span>
      </li>
    </ul>
  </div>
</div> -->
