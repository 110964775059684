import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MapsRoutingModule } from './maps-routing.module';
import { MapsComponent } from './maps.component';
import { CustomControlComponent } from './shared/custom-control/custom-control.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';


@NgModule({
  declarations: [MapsComponent, CustomControlComponent],
  imports: [
    CommonModule,
    MapsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    LeafletModule
  ]
})
export class MapsModule { }
