import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { TabsOptions } from 'src/app/shared/tabs/tabs.component';

@Component({
  selector: 'app-notifications-dropdown',
  templateUrl: './notifications-dropdown.component.html',
  styleUrls: ['./notifications-dropdown.component.scss']
})
export class NotificationsDropdownComponent implements OnInit {
  tabsOpts: TabsOptions;

  @Input() captivePortalNotificationsCount: number;
  @Input() serviceStatusNotificationsCount: number;
  @Input() networkNotificationsCount: number;
  notificationsTotal: number | string;

  @ViewChild('captivePortalTab', { static: true }) captivePortalTab: TemplateRef<any>;
  @ViewChild('captivePortalTabTitle', { static: true }) captivePortalTabTitle: TemplateRef<any>;

  @ViewChild('serviceStatusTab', { static: true }) serviceStatusTab: TemplateRef<any>;
  @ViewChild('serviceStatusTabTitle', { static: true }) serviceStatusTabTitle: TemplateRef<any>;

  @ViewChild('networkTab', { static: true }) networkTab: TemplateRef<any>;
  @ViewChild('networkTabTitle', { static: true }) networkTabTitle: TemplateRef<any>;

  constructor() {}

  ngOnInit() {
    this.setTemplateOptions();
    this.notificationsTotal = this.getNotificationsTotal();
  }

  getNotificationsTotal() {
    let total =
      this.captivePortalNotificationsCount + this.serviceStatusNotificationsCount + this.networkNotificationsCount;
    return total > 99 ? '99+' : total;
  }

  setTemplateOptions() {
    this.tabsOpts = {
      defaultTabId: 'content-1',
      tabs: [
        {
          id: 'content-1',
          title: this.captivePortalTabTitle,
          content: this.captivePortalTab
        },
        {
          id: 'content-2',
          title: this.serviceStatusTabTitle,
          content: this.serviceStatusTab
        },
        {
          id: 'content-3',
          title: this.networkTabTitle,
          content: this.networkTab
        }
      ]
    };
  }
}
