import { Component, ElementRef, OnInit, ViewChild, KeyValueDiffers, DoCheck } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
// import { Browser, Map, map, tileLayer } from 'leaflet';
import * as L from 'leaflet';
import { MapsService } from '../services/maps.service';
import { MarkerService } from './shared/marker.service';
const moment = require('moment');
declare var $: any;

import * as LHeat from 'leaflet.heat';

import 'leaflet.heat/dist/leaflet-heat.js';
import 'leaflet-arrowheads';
import { addressPoints } from '../../assets/heat';

import 'leaflet-heatmap';
import { ChartOptions } from '../dashboard/dashboard.component';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit, DoCheck {
  formulario: FormGroup;
  dateForm: FormGroup;

  aps = [];

  client = JSON.parse(localStorage.getItem('currentClient'));
  floors = [];

  points = [];
  heatmap = [];

  imageBounds: any;

  userText =
    'group' in JSON.parse(localStorage.getItem('currentUser')).user
      ? JSON.parse(localStorage.getItem('currentUser')).user.group.text
      : '';
  validateAreas: boolean = false;
  colors = ['green', 'blue', 'red', 'orange', 'yellow', 'grey', 'violet', 'gold', 'green', 'blue'];
  circle = [];

  connecteds = [];
  detecteds = [];

  dataSelecionada: string = moment()
    .set({ hour: moment().hour(), minute: 0 })
    .format('YYYY-MM-DDTHH:mm:ss');

  validateConnecteds: boolean = true;
  validateDetecteds: boolean = false;
  validateCaminhada: boolean = false;

  floorAtual: any = '';

  steps = [];
  macs = [];

  allDateInput: boolean = false;

  // Conexões
  chartOptionsConnections: Partial<ChartOptions>;
  loadingConnections: boolean = false;
  validateConnections: boolean = false;

  validateSteps: boolean = false;

  stepsArrows: any = [];

  initialDate = moment()
    .subtract(7, 'day')
    .format()
    .split('T')[0];
  endDate = moment()
    .format()
    .split('T')[0];

  private map;

  private differ: any;

  mapOn: boolean = false;
  mainZoom: number = 18.5;
  radius: number = 40;

  constructor(
    private markerService: MarkerService,
    private mapsService: MapsService,
    private formBuilder: FormBuilder,
    private differs: KeyValueDiffers
  ) {
    this.differ = this.differs.find({}).create();

    this.chartOptionsConnections = {
      series: [],
      chart: {
        height: 220,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          export: {
            csv: {
              filename: 'authenticatednew',
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              }
            },
            svg: {
              filename: 'authenticatednew'
            },
            png: {
              filename: 'authenticatednew'
            }
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#F26522', '#6d6e71', '#672c0f'],
      stroke: {
        curve: 'straight',
        width: 1.5
      },
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: 'vertical',
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 10
      }
      // yaxis: [
      //   {
      //     seriesName: 'Experiência',
      //     show: true,
      //     labels:{
      //       formatter: function(val: number): string {
      //         return val.toFixed(0) + '%';
      //       }
      //     },
      //   },
      //   {
      //     seriesName: '2.4',
      //     show: false,
      //     labels:{
      //       formatter: function(val: number): string {
      //         return val.toFixed(0);
      //       }
      //     },
      //   },
      //   {
      //     seriesName: '2.4',
      //     show: true,
      //     opposite: true,
      //     labels:{
      //       formatter: function(val: number): string {
      //         return val.toFixed(0);
      //       }
      //     },
      //     max: 400,
      //     min: 100
      //   }
      // ]
    };
  }

  ngOnInit() {
    this.inicializarForm();
    this.getApConnections();

    $(document).ready(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  ngDoCheck() {
    if (this.mapOn) {
      if (this.map._zoom !== this.mainZoom) {
        if (this.heatmap.length > 0) {
          if (this.map._zoom == 20) {
            this.heatmap[0].setOptions({
              radius: 70
            });
          } else if (this.map._zoom == 19) {
            this.heatmap[0].setOptions({
              radius: 40
            });
          } else if (this.map._zoom == 18) {
            this.heatmap[0].setOptions({
              radius: 30
            });
          } else if (this.map._zoom == 17) {
            this.heatmap[0].setOptions({
              radius: 20
            });
          } else if (this.map._zoom == 16) {
            this.heatmap[0].setOptions({
              radius: 10
            });
          }
        }

        this.mainZoom = this.map._zoom;
      }
    }
  }

  ngAfterViewInit() {
    this.getConnecteds();

    let lat;
    let lng;

    if (this.client) {
      lat = (this.client.coordinates.East + this.client.coordinates.West) / 2;
      lng = (this.client.coordinates.North + this.client.coordinates.South) / 2;

      this.initMap(lat, lng, this.client);
    }
  }

  initMap(lat, lng, client): void {
    this.map = L.map('map', {
      center: [lng, lat],
      zoom: 19
    });

    this.mapOn = true;

    const tiles = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
      maxZoom: 20,
      minZoom: 17,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    this.imageBounds = [
      [client.coordinates.North, client.coordinates.West],
      [client.coordinates.South, client.coordinates.East]
    ];

    var imageUrl = `../../assets/plant-info/${this.client.radio_prefix.toLowerCase()}/${
      this.client.Floors[0].name
    }.png`;
    L.imageOverlay(imageUrl, this.imageBounds).addTo(this.map);

    this.getFloors();
  }

  getFloors() {
    let lengthFloors = Object.keys(this.client.Floors).length;
    for (let i = 0; i < lengthFloors; i++) {
      this.floors.push(this.client.Floors[i]);
      this.floorAtual = this.floors[0];
    }
  }

  changeImage(floor, update?: boolean) {
    let indexFloor = this.floors.findIndex(object => {
      return object.name === floor;
    });

    this.floorAtual = floor;

    update = true;

    if (update) {
      var imageUrl = `../../assets/plant-info/${this.client.radio_prefix.toLowerCase()}/${floor}.png`;

      L.imageOverlay(imageUrl, this.imageBounds).addTo(this.map);
    }

    for (let i = 0; i < this.points.length; i++) {
      this.map.removeLayer(this.points[i]);
    }
    this.points = [];

    for (let i = 0; i < this.heatmap.length; i++) {
      this.map.removeLayer(this.heatmap[i]);
    }

    this.heatmap = [];

    for (let i = 0; i < this.stepsArrows.length; i++) {
      this.map.removeLayer(this.stepsArrows[i]);
    }

    for (let i = 0; i < this.circle.length; i++) {
      this.map.removeLayer(this.circle[i]);
    }

    this.stepsArrows = [];

    this.getApsCoords(indexFloor);
  }

  getStepsByMac() {
    this.mapsService
      .getStepsByMac(this.client.controller_id, this.initialDate, this.endDate, this.formulario.controls['mac'].value)
      .subscribe(data => {
        data.forEach((element, index) => {
          this.steps = data;
          if (index < data.length - 2) {
            if (element.associeted_ap == data[index + 1].associeted_ap) {
              if (element.associeted_ap == data[index + 2].associeted_ap) {
                this.steps.splice(index + 1, 2);
              } else {
                this.steps.splice(index + 1, 1);
              }
            }
          } else if (index < data.length) {
            if (element.associeted_ap == data[index - 1].associeted_ap) {
              this.steps.splice(index + 1, 1);
            }
          }
        });

        this.validateSteps = true;
      });
  }

  getApConnections() {
    this.loadingConnections = true;
    this.validateConnections = false;
    this.mapsService
      .getApsConnections(
        this.client.radio_prefix,
        this.dateForm.controls['initialDate'].value,
        this.dateForm.controls['endDate'].value
      )
      .subscribe(data => {
        // if (data.length > 0){
        this.getConnectionsData(data);
        // }
      })
      .add(() => {
        this.loadingConnections = false;
      });
  }

  getConnectionsData(data) {
    this.chartOptionsConnections.series = [];
    this.chartOptionsConnections.xaxis.categories = data.date;
    this.chartOptionsConnections.series.push({ name: 'Detectados', data: data.detected });

    this.validateConnections = true;
  }

  reloadChart() {
    if (!this.loadingConnections) {
      this.loadingConnections = true;

      setTimeout(() => {
        this.loadingConnections = false;
      }, 500);
    }
  }

  getAps() {
    let radioPrefix = this.client.radio_prefix;
    this.mapsService.getRadiosByRadioPrefix(radioPrefix).subscribe(data => {
      this.aps = data;
      this.getApsCoords(0);
    });
  }

  getApsCoords(indexAtual) {
    let floorAtual = this.client.Floors[indexAtual];

    const width = floorAtual.Width;
    const height = floorAtual.Height;
    const heightC = this.client.coordinates.North - this.client.coordinates.South;
    const widthC = this.client.coordinates.East - this.client.coordinates.West;
    let latlngList = [];
    this.heatmap = [];
    let stepsAps = [];

    this.aps.forEach((element, index) => {
      if ('map' in element) {
        if (Number(element.map.floor) == Number(indexAtual)) {
          var ap_map = element.map;
          let apCord1 = (ap_map.y * heightC) / height;
          let apCord2 = (ap_map.x * widthC) / width;
          let toolTip;

          const lat = this.client.coordinates.North - apCord1;
          const lon = this.client.coordinates.West + apCord2;

          const iconRetinaUrl = 'assets/favicon.ico';
          const iconUrl = 'assets/favicon.ico';
          // const shadowUrl = 'assets/marker-shadow.png';
          let iconDefault = L.icon({
            iconRetinaUrl,
            iconUrl,
            // shadowUrl,
            iconSize: [32, 32],
            iconAnchor: [16, 14],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28],
            shadowSize: [41, 41]
          });

          if (this.validateConnecteds) {
            let connecteds = this.connecteds.find(el => el.ap_id == element.ip);

            toolTip =
              `<b>Nome:</b> ${element.name.split('--')[1]}<br>` +
              `<b>IP:</b> ${element.ip}<br>` +
              `<b>Conectados:</b> ${connecteds != undefined ? String(connecteds.total).split('.')[0] : 0}<br>` +
              `<b>Aglomeração:</b> Não`;

            const marker = L.marker([lat, lon]);
            L.Marker.prototype.options.icon = iconDefault;
            let point = marker.addTo(this.map).bindPopup(toolTip);
            this.points.push(point);

            let newAddressPoints = addressPoints.map(function(p) {
              return [p[0], p[1], 1000];
            });

            if (connecteds == undefined) {
              connecteds = {
                total: 0
              };
            }

            latlngList.push(L.latLng(lat, lon, connecteds.total));
          } else if (this.validateDetecteds) {
            let detecteds = this.detecteds.find(el => el.ap_id == element.ip);

            toolTip =
              `<b>Nome:</b> ${element.name.split('--')[1]}<br>` +
              `<b>IP:</b> ${element.ip}<br>` +
              `<b>Detectados:</b> ${detecteds != undefined ? String(detecteds.total).split('.')[0] : 0}<br>` +
              `<b>Aglomeração:</b> Não`;

            const marker = L.marker([lat, lon]);
            L.Marker.prototype.options.icon = iconDefault;
            let point = marker.addTo(this.map).bindPopup(toolTip);
            this.points.push(point);

            let newAddressPoints = addressPoints.map(function(p) {
              return [p[0], p[1], 1000];
            });

            if (detecteds == undefined) {
              detecteds = {
                total: 0
              };
            }

            latlngList.push(L.latLng(lat, lon, detecteds.total));
          } else if (this.validateCaminhada) {
            toolTip = `<b>Nome:</b> ${element.name.split('--')[1]}<br>` + `<b>IP:</b> ${element.ip}<br>`;

            iconDefault = L.icon({
              iconRetinaUrl,
              iconUrl,
              // shadowUrl,
              iconSize: [18, 18],
              iconAnchor: [16, 14],
              popupAnchor: [1, -34],
              tooltipAnchor: [16, -28],
              shadowSize: [41, 41]
            });

            const marker = L.marker([lat, lon]);
            L.Marker.prototype.options.icon = iconDefault;
            let point = marker.addTo(this.map).bindPopup(toolTip);
            this.points.push(point);

            let newAddressPoints = addressPoints.map(function(p) {
              return [p[0], p[1], 1000];
            });

            let selectedAp;

            let apBoolean = this.steps.find((el, index) => {
              if (el.associeted_ap == element.name.split('--')[1]) {
                let index2 = index + 1;
                if (index2 != this.steps.length) {
                  selectedAp = this.steps[index + 1].associeted_ap;
                  return true;
                } else {
                  return false;
                }
              } else {
                selectedAp = '';
                return false;
              }
            });

            if (apBoolean) {
              let indexdAp = this.aps.findIndex(object => {
                return object.name.split('--')[1] === selectedAp;
              });

              var ap_map2 = this.aps[indexdAp].map;
              let ap2Cord1 = (ap_map2.y * heightC) / height;
              let ap2Cord2 = (ap_map2.x * widthC) / width;

              const latAp2 = this.client.coordinates.North - ap2Cord1;
              const lonAp2 = this.client.coordinates.West + ap2Cord2;

              stepsAps.push([
                [lat, lon],
                [latAp2, lonAp2]
              ]);
            }

            let arrows: any = L.polyline(stepsAps, { color: 'red' }).arrowheads({ size: '10px', fill: true });
            arrows.addTo(this.map);

            this.stepsArrows.push(arrows);
          } else if (this.validateAreas) {
            let connecteds = this.connecteds.find(el => el.ap_id == element.ip);

            toolTip =
              `<b>Nome:</b> ${element.name.split('--')[1]}<br>` +
              `<b>IP:</b> ${element.ip}<br>` +
              `<b>Área:</b> ${element.map.area}<br>`;

            const marker = L.marker([lat, lon]);
            L.Marker.prototype.options.icon = iconDefault;
            let point = marker.addTo(this.map).bindPopup(toolTip);
            this.points.push(point);
            const circle = L.circleMarker([lat, lon], { radius: 20 }).addTo(this.map);
            this.circle.push(circle);
            circle.setStyle({ color: this.colors[element.map.area - 1] });

            let newAddressPoints = addressPoints.map(function(p) {
              return [p[0], p[1], 1000];
            });

            if (connecteds == undefined) {
              connecteds = {
                total: 0
              };
            }

            latlngList.push(L.latLng(lat, lon, connecteds.total));
          }
        }
      }
    });

    if (!this.validateAreas) {
      let heatmap = L.heatLayer(latlngList, { radius: this.radius, max: 15 }).addTo(this.map);

      this.heatmap.push(heatmap);
    }
  }

  inicializarForm() {
    this.formulario = this.formBuilder.group({
      dataInicial: [
        moment()
          .format()
          .split('T')[0]
      ],
      hour: [String(moment().hour()) + ':' + '00'],
      mac: [''],
      dataFinal: [
        moment()
          .format()
          .split('T')[0]
      ],
      dateHour: [
        moment()
          .set({ hour: moment().hour(), minute: moment().minute() })
          .format('YYYY-MM-DDTHH:mm')
      ],
      date: [
        moment()
          .format()
          .split('T')[0]
      ],
      period: ['1'],
      allDay: false
    });

    this.dateForm = this.formBuilder.group({
      initialDate: [
        moment()
          .subtract(1, 'day')
          .format()
          .split('T')[0]
      ],
      endDate: [
        moment()
          .format()
          .split('T')[0]
      ]
    });
  }

  changeAllDay() {
    this.allDateInput = this.formulario.controls['allDay'].value;
    this.trocarData(2);
  }

  getConnecteds() {
    this.mapsService
      .getRadiosConnect(this.client.controller_id, this.dataSelecionada)
      .subscribe(data => {
        this.connecteds = data;
        // this.changeImage('Floor_L0');
      })
      .add(() => {
        this.getDetecteds();
        this.getMacs();
      });
  }

  getDetecteds() {
    this.mapsService
      .getRadiosSensor(this.client.radio_prefix, this.dataSelecionada)
      .subscribe(data => {
        this.detecteds = data;
        // this.changeImage('Floor_L0');
      })
      .add(() => {
        this.getAps();
      });
  }

  trocarData(selectType) {
    if (!this.validateCaminhada) {
      if (!this.allDateInput) {
        this.dataSelecionada = moment(this.formulario.controls['dateHour'].value)
          .set({
            hour: this.formulario.controls['dateHour'].value.split('T')[0].split(':')[0],
            minute: this.formulario.controls['dateHour'].value.split(':')[1].split(':')[0]
          })
          .format('YYYY-MM-DDTHH:mm:ss');
      } else {
        this.dataSelecionada = moment(this.formulario.controls['date'].value).format('YYYY-MM-DD');
      }

      this.getConnecteds();
    } else {
      let date = moment(this.formulario.controls['dateHour'].value)
        .set({
          hour: this.formulario.controls['dateHour'].value.split('T')[0].split(':')[0],
          minute: this.formulario.controls['dateHour'].value.split(':')[1].split(':')[0]
        })
        .add(this.formulario.controls['period'].value, 'hours')
        .format('YYYY-MM-DDTHH:mm:ss');

      this.initialDate = moment(this.formulario.controls['dateHour'].value)
        .set({
          hour: this.formulario.controls['dateHour'].value.split('T')[0].split(':')[0],
          minute: this.formulario.controls['dateHour'].value.split(':')[1].split(':')[0]
        })
        .format('YYYY-MM-DDTHH:mm:ss');
      this.endDate = date;

      this.getConnecteds();
    }

    for (let i = 0; i < this.points.length; i++) {
      this.map.removeLayer(this.points[i]);
    }
    this.points = [];

    for (let i = 0; i < this.heatmap.length; i++) {
      this.map.removeLayer(this.heatmap[i]);
    }

    this.heatmap = [];

    for (let i = 0; i < this.stepsArrows.length; i++) {
      this.map.removeLayer(this.stepsArrows[i]);
    }

    this.stepsArrows = [];
  }

  changeDates(selectType) {
    let initialDate = moment(this.dateForm.controls['initialDate'].value);
    let endDate = moment(this.dateForm.controls['endDate'].value);

    if (selectType == 1) {
      this.dateForm.controls['endDate'].setValue('');
    }

    if (endDate.isSameOrAfter(initialDate) && this.dateForm.controls['endDate'].value != '') {
      this.initialDate = this.dateForm.controls['initialDate'].value;
      this.endDate = this.dateForm.controls['endDate'].value;

      this.getApConnections();
    }
  }

  updateMap(value) {
    if (value == 1) {
      this.validateConnecteds = true;
      this.validateDetecteds = false;
      this.validateCaminhada = false;
      this.validateAreas = false;
    } else if (value == 2) {
      this.validateDetecteds = true;
      this.validateConnecteds = false;
      this.validateCaminhada = false;
      this.validateAreas = false;
    } else if (value == 3) {
      this.validateCaminhada = true;
      this.formulario.controls['dataInicial'].setValue(
        moment()
          .subtract(7, 'day')
          .format()
          .split('T')[0]
      );
      this.validateConnecteds = false;
      this.validateDetecteds = false;
      this.validateAreas = false;
    } else if (value == 4) {
      this.validateAreas = true;
      this.validateConnecteds = false;
      this.validateDetecteds = false;
      this.validateCaminhada = false;
    }

    this.floorAtual = this.floors[0];
    this.changeImage(this.floorAtual.name, false);
  }

  getConnectedsDetectedByHour() {
    this.trocarData(2);
  }

  getMacs() {
    this.mapsService.getMacs(this.client.controller_id).subscribe(data => {
      if (data.length > 0) {
        this.macs = data;
        // this.macs.push('2c:d0:66:56:af:1d')
        this.formulario.controls['mac'].setValue(this.macs[0].mac);
        this.getStepsByMac();
      }
    });
  }
}
