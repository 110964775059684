import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'channelGHz' })
export class ChannelGHzPipe implements PipeTransform {
  transform(value) {
    if (value === undefined) {
      return null;
    } else {
      if (value == 1) {
        return '1 (2.412GHz)';
      } else if (value == 2) {
        return '2 (2.417GHz)';
      } else if (value == 3) {
        return '3 (2.422GHz)';
      } else if (value == 4) {
        return '4 (2.427GHz)';
      } else if (value == 5) {
        return '5 (2.432GHz)';
      } else if (value == 6) {
        return '6 (2.437GHz)';
      } else if (value == 7) {
        return '7 (2.442GHz)';
      } else if (value == 8) {
        return '8 (2.447GHz)';
      } else if (value == 9) {
        return '9 (2.452GHz)';
      } else if (value == 10) {
        return '10 (2.457GHz)';
      } else if (value == 11) {
        return '11 (2.462GHz)';
      } else if (value == 34) {
        return '34 (5.170GHz)';
      } else if (value == 36) {
        return '36 (5.180GHz)';
      } else if (value == 38) {
        return '38 (5.190GHz)';
      } else if (value == 40) {
        return '40 (5.200GHz)';
      } else if (value == 44) {
        return '44 (5.220GHz)';
      } else if (value == 46) {
        return '46 (5.230GHz)';
      } else if (value == 48) {
        return '48 (5.240Hz)';
      } else if (value == 52) {
        return '52 (5.260GHz)';
      } else if (value == 54) {
        return '54 (5.270GHz)';
      } else if (value == 56) {
        return '56 (5.280GHz)';
      } else if (value == 60) {
        return '60 (5.300GHz)';
      } else if (value == 62) {
        return '62 (5.310GHz)';
      } else if (value == 64) {
        return '64 (5.320GHz)';
      } else if (value == 140) {
        return '140 (5.700GHz)';
      }else if (value == 142) {
        return '142 (5.710GHz)';
      }else if (value == 144) {
        return '144 (5.720GHz)';
      }else if (value == 149) {
        return '149 (5.745GHz)';
      } else if (value == 151) {
        return '151 (5.755GHz)';
      } else if (value == 153) {
        return '153 (5.765GHz)';
      } else if (value == 155) {
        return '155 (5.775GHz)';
      } else if (value == 157) {
        return '157 (5.785GHz)';
      } else if (value == 159) {
        return '159 (5.795GHz)';
      } else if (value == 161) {
        return '161 (5.805GHz)';
      } else if (value == 165) {
        return '165 (5.825GHz)';
      }
    }
  }
}
