import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';

// const users: User[] = [{ id: 1, username: 'capybateste', password: 'capybateste' }];

// const stations: Station[] = [
//   {
//     id: '59',
//     interface_id: 8,
//     mac: '98:22:ef:21:89:95',
//     hostname: null,
//     device_model: null,
//     signal: '-53 dBm',
//     noise: '-87 dBm',
//     snr: '20 dBm',
//     bandwidth: '10 MB'
//   },
//   {
//     id: '60',
//     interface_id: 8,
//     mac: '48:49:c7:19:4d:40',
//     hostname: null,
//     device_model: null,
//     signal: '-67 dBm',
//     noise: '-87 dBm',
//     snr: '20 dBm',
//     bandwidth: '10 MB'
//   },
//   {
//     id: '61',
//     interface_id: 8,
//     mac: '48:49:c7:df:07:d8',
//     hostname: null,
//     device_model: null,
//     signal: '-62 dBm',
//     noise: '-87 dBm',
//     snr: '20 dBm',
//     bandwidth: '10 MB'
//   },
//   {
//     id: '62',
//     interface_id: 8,
//     mac: '38:9a:f6:38:ba:d7',
//     hostname: null,
//     device_model: null,
//     signal: '-70 dBm',
//     noise: '-87 dBm',
//     snr: '20 dBm',
//     bandwidth: '10 MB'
//   },
//   {
//     id: '63',
//     interface_id: 8,
//     mac: '48:49:c7:d0:36:fe',
//     hostname: null,
//     device_model: null,
//     signal: '-67 dBm',
//     noise: '-87 dBm',
//     snr: '20 dBm',
//     bandwidth: '10 MB'
//   },
//   {
//     id: '64',
//     interface_id: 8,
//     mac: '3c:2e:ff:2f:e6:cd',
//     hostname: null,
//     device_model: null,
//     signal: '-71 dBm',
//     noise: '-87 dBm',
//     snr: '20 dBm',
//     bandwidth: '10 MB'
//   },
//   {
//     id: '65',
//     interface_id: 8,
//     mac: 'd0:77:14:76:02:1c',
//     hostname: null,
//     device_model: null,
//     signal: '-66 dBm',
//     noise: '-87 dBm',
//     snr: '20 dBm',
//     bandwidth: '10 MB'
//   },
//   {
//     id: '66',
//     interface_id: 8,
//     mac: '80:65:6d:58:be:7f',
//     hostname: null,
//     device_model: null,
//     signal: '-73 dBm',
//     noise: '-87 dBm',
//     snr: '20 dBm',
//     bandwidth: '10 MB'
//   },
//   {
//     id: '67',
//     interface_id: 8,
//     mac: '28:83:35:ab:3b:c5',
//     hostname: null,
//     device_model: null,
//     signal: '-65 dBm',
//     noise: '-87 dBm',
//     snr: '20 dBm',
//     bandwidth: '10 MB'
//   },
//   {
//     id: '68',
//     interface_id: 8,
//     mac: 'fc:64:3a:0c:63:08',
//     hostname: null,
//     device_model: null,
//     signal: '-75 dBm',
//     noise: '-87 dBm',
//     snr: '20 dBm',
//     bandwidth: '10 MB'
//   },
//   {
//     id: '69',
//     interface_id: 8,
//     mac: '38:80:df:03:2e:aa',
//     hostname: null,
//     device_model: null,
//     signal: '0 dBm',
//     noise: '-87 dBm',
//     snr: '20 dBm',
//     bandwidth: '10 MB'
//   }
// ];

// const interfaces: { count: number; result:  Iface[] } = {
//   count: 50,
//   result: [
//     {
//       id: '0',
//       radio_id: '0',
//       ap_id: '0',
//       name: 'Whan0',
//       mac: '98:22:ef:21:89:95',
//       ssid: 'Paço Alfândega',
//       signal: -53,
//       noise: -87,
//       stations: stations
//     },
//     {
//       id: '1',
//       radio_id: '0',
//       ap_id: '0',
//       name: 'Whan0-1',
//       mac: '09:29:f7:29:6r:1f',
//       ssid: 'Quiosque Caixa',
//       signal: -67,
//       noise: -87,
//       stations: stations
//     },
//     {
//       id: '2',
//       radio_id: '1',
//       ap_id: '0',
//       name: 'Whan1',
//       mac: '48:49:c7:df:07:d8',
//       ssid: 'Paço Alfândega',
//       signal: -62,
//       noise: -87,
//       stations: stations
//     },
//     {
//       id: '3',
//       radio_id: '1',
//       ap_id: '0',
//       name: 'Whan1-1',
//       mac: '38:9a:f6:38:ba:d7',
//       ssid: 'Quiosque Caixa',
//       signal: -70,
//       noise: -87,
//       stations: stations
//     },
//     {
//       id: '4',
//       radio_id: '2',
//       ap_id: '1',
//       name: 'Whan2',
//       mac: '98:22:ef:21:89:95',
//       ssid: 'Paço Alfândega',
//       signal: -53,
//       noise: -87,
//       stations: stations
//     },
//     {
//       id: '5',
//       radio_id: '2',
//       ap_id: '1',
//       name: 'Whan2-1',
//       mac: '09:29:f7:29:6r:1f',
//       ssid: 'Quiosque Caixa',
//       signal: -67,
//       noise: -87,
//       stations: stations
//     },
//     {
//       id: '6',
//       radio_id: '3',
//       ap_id: '1',
//       name: 'Whan3',
//       mac: '48:49:c7:df:07:d8',
//       ssid: 'Paço Alfândega',
//       signal: -62,
//       noise: -87,
//       stations: stations
//     },
//     {
//       id: '7',
//       radio_id: '3',
//       ap_id: '1',
//       name: 'Whan3-1',
//       mac: '38:9a:f6:38:ba:d7',
//       ssid: 'Quiosque Caixa',
//       signal: -70,
//       noise: -87,
//       stations: stations
//     },
//     {
//       id: '8',
//       radio_id: '4',
//       ap_id: '2',
//       name: 'Whan4',
//       mac: '48:49:c7:df:07:d8',
//       ssid: 'Paço Alfândega',
//       signal: -62,
//       noise: -87,
//       stations: stations
//     },
//     {
//       id: '9',
//       radio_id: '4',
//       ap_id: '2',
//       name: 'Whan4-1',
//       mac: '38:9a:f6:38:ba:d7',
//       ssid: 'Quiosque Caixa',
//       signal: -70,
//       noise: -87,
//       stations: stations
//     },
//     {
//       id: '10',
//       radio_id: '5',
//       ap_id: '2',
//       name: 'Whan5',
//       mac: '48:49:c7:df:07:d8',
//       ssid: 'Paço Alfândega',
//       signal: -62,
//       noise: -87,
//       stations: stations
//     },
//     {
//       id: '11',
//       radio_id: '5',
//       ap_id: '2',
//       name: 'Whan5-1',
//       mac: '38:9a:f6:38:ba:d7',
//       ssid: 'Quiosque Caixa',
//       signal: -70,
//       noise: -87,
//       stations: stations
//     },
//     {
//       id: '12',
//       radio_id: '6',
//       ap_id: '3',
//       name: 'Whan6',
//       mac: '48:49:c7:df:07:d8',
//       ssid: 'Paço Alfândega',
//       signal: -62,
//       noise: -87,
//       stations: stations
//     },
//     {
//       id: '13',
//       radio_id: '6',
//       ap_id: '3',
//       name: 'Whan6-1',
//       mac: '38:9a:f6:38:ba:d7',
//       ssid: 'Quiosque Caixa',
//       signal: -70,
//       noise: -87,
//       stations: stations
//     },
//     {
//       id: '14',
//       radio_id: '7',
//       ap_id: '3',
//       name: 'Whan7',
//       mac: '48:49:c7:df:07:d8',
//       ssid: 'Paço Alfândega',
//       signal: -62,
//       noise: -87,
//       stations: stations
//     },
//     {
//       id: '15',
//       radio_id: '7',
//       ap_id: '3',
//       name: 'Whan7-1',
//       mac: '38:9a:f6:38:ba:d7',
//       ssid: 'Quiosque Caixa',
//       signal: -70,
//       noise: -87,
//       stations: stations
//     }
//   ]
// };

// const radios: { count: number; result: Radio[] } = {
//   count: 8,
//   result: [
//     {
//       id: '0',
//       ap_id: '0',
//       name: 'Whan0',
//       device_name: 'Vagalume Fire 802.11nac',
//       channel: Channel.GHz12437,
//       ht: HT.HT10,
//       txPower: TXPower.dbM10,
//       station: 0,
//       interfaces: null
//     },
//     {
//       id: '1',
//       ap_id: '0',
//       name: 'Whan1',
//       device_name: 'Vagalume Fire 802.11nac',
//       channel: Channel.GHz12437,
//       ht: HT.HT10,
//       txPower: TXPower.dbM10,
//       station: 0,
//       interfaces: null
//     },
//     {
//       id: '2',
//       ap_id: '1',
//       name: 'Whan2',
//       device_name: 'Vagalume Fire 802.11nac',
//       channel: Channel.GHz12437,
//       ht: HT.HT10,
//       txPower: TXPower.dbM10,
//       station: 0,
//       interfaces: null
//     },
//     {
//       id: '3',
//       ap_id: '1',
//       name: 'Whan3',
//       device_name: 'Vagalume Fire 802.11nac',
//       channel: Channel.GHz12437,
//       ht: HT.HT10,
//       txPower: TXPower.dbM10,
//       station: 0,
//       interfaces: null
//     },
//     {
//       id: '4',
//       ap_id: '2',
//       name: 'Whan4',
//       device_name: 'Vagalume Fire 802.11nac',
//       channel: Channel.GHz12437,
//       ht: HT.HT10,
//       txPower: TXPower.dbM10,
//       station: 0,
//       interfaces: null
//     },
//     {
//       id: '5',
//       ap_id: '2',
//       name: 'Whan5',
//       device_name: 'Vagalume Fire 802.11nac',
//       channel: Channel.GHz12437,
//       ht: HT.HT10,
//       txPower: TXPower.dbM10,
//       station: 0,
//       interfaces: null
//     },
//     {
//       id: '6',
//       ap_id: '3',
//       name: 'Whan6',
//       device_name: 'Vagalume Fire 802.11nac',
//       channel: Channel.GHz12437,
//       ht: HT.HT10,
//       txPower: TXPower.dbM10,
//       station: 0,
//       interfaces: null
//     },
//     {
//       id: '7',
//       ap_id: '3',
//       name: 'Whan7',
//       device_name: 'Vagalume Fire 802.11nac',
//       channel: Channel.GHz12437,
//       ht: HT.HT10,
//       txPower: TXPower.dbM10,
//       station: 0,
//       interfaces: null
//     }
//   ]
// };

// const aps: { count: number; result: AP[] } = {
//   count: 4,
//   result: [
//     {
//       id: '0',
//       name: 'AP-0',
//       ip: '172.16.254.2',
//       la1: '0,16',
//       uptime: '21 days',
//       version: '0.0.1',
//       historic: null,
//       online: null,
//       radios: null
//     },
//     {
//       id: '1',
//       name: 'AP-1',
//       ip: '172.16.254.2',
//       la1: '0,16',
//       uptime: '3 days',
//       version: '00.0.1',
//       historic: null,
//       online: null,
//       radios: null
//     },
//     {
//       id: '2',
//       name: 'AP-2',
//       ip: '172.16.254.2',
//       la1: '0,16',
//       uptime: '55 days',
//       version: '0.0.1',
//       historic: null,
//       online: null,
//       radios: null
//     },
//     {
//       id: '3',
//       name: 'AP-3',
//       ip: '172.16.254.2',
//       la1: '0,16',
//       uptime: '21 days',
//       version: '0.0.1',
//       historic: null,
//       online: null,
//       radios: null
//     }
//   ]
// };

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;

    // wrap in delayed observable to simulate server api call
    return of(null)
      .pipe(mergeMap(handleRoute))
      .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
      .pipe(delay(500))
      .pipe(dematerialize());

    function handleRoute() {
      switch (true) {
        // case url.endsWith('/users/authenticate') && method === 'POST':
        //   return authenticate();
        // case url.endsWith('/users') && method === 'GET':
        //   return getUsers();
        // case url.endsWith('/aps') && method === 'GET':
        //   return getAllAps();
        // case url.includes('/aps/') && !isNaN(+url.split('/aps/')[1]) && method === 'GET':
        //   return getAp();
        // case url.endsWith('/interfaces') && method === 'GET':
        //   return getAllInterfaces();
        // case url.includes('/interfaces/ap/') && !isNaN(+url.split('/interfaces/ap/')[1]) && method === 'GET':
        //   return getInterfacesByAp(url.split('/interfaces/ap/')[1]);
        // case url.includes('/interfaces/') && !isNaN(+url.split('/interfaces/')[1]) && method === 'GET':
        //   return getInterface();
        // case url.endsWith('/radios') && method === 'GET':
        //   return getAllRadios();
        // case url.includes('/radios/ap/') && !isNaN(+url.split('/radios/ap/')[1]) && method === 'GET':
        //   return getRadiosByAp(url.split('/radios/ap/')[1]);
        // case url.includes('/radios/') && !isNaN(+url.split('/radios/')[1]) && method === 'GET':
        //   return getRadio();
        default:
          // pass through any requests not handled above
          return next.handle(request);
      }
    }

    // route functions

    // function authenticate() {
    //   const { username, password } = body;
    //   const user = users.find(x => x.username === username && x.password === password);
    //   if (!user) return error('Username or password is incorrect');
    //   return ok({
    //     id: user.id,
    //     username: user.username,
    //     token: 'fake-jwt-token'
    //   });
    // }

    // function getUsers() {
    //   return ok(users);
    // }

    // function interfacesByRadio(radioId) {
    //   return interfaces.result.filter(iface => iface.radio_id === radioId);
    // }

    // function radiosByAp(apId) {
    //   let radioList: Radio[] = radios.result.filter(radio => radio.ap_id === apId);

    //   let radiosWithInterfaces = radioList.map((radio: Radio) => {
    //     radio.interfaces = interfacesByRadio(radio.id);
    //     return radio;
    //   });

    //   return radiosWithInterfaces;
    // }

    // function getAllAps() {
    //   if (!isLoggedIn()) return unauthorized();

    //   let apsWithRadios = aps.result.map((ap: AP) => {
    //     ap.radios = radiosByAp(ap.id);
    //     return ap;
    //   });

    //   aps.result = apsWithRadios;
    //   return ok(aps);
    // }

    // function getAp() {
    //   const apId = url.split('/aps/')[1];
    //   const ap = findAp(apId);
    //   ap.radios = radiosByAp(ap.id);
    //   return ok(ap);
    // }

    // function findAp(id: string) {
    //   return aps.result.find(ap => ap.id === id);
    // }

    // function getAllRadios() {
    //   if (!isLoggedIn()) return unauthorized();
    //   return ok(radios);
    // }

    // function getRadiosByAp(apId) {
    //   if (!isLoggedIn()) return unauthorized();
    //   let filteredRadios = radios.result.filter(radio => radio.ap_id === apId);
    //   let radiosByAp: { count: number; result: Iface[] } = {
    //     count: filteredRadios.length,
    //     result: filteredRadios
    //   };

    //   return ok(radiosByAp);
    // }

    // function getRadio() {
    //   const radioId = url.split('/radios/')[1];
    //   const radio = findRadio(radioId);
    //   return ok(radio);
    // }

    // function findRadio(id: string) {
    //   return radios.result.find(radio => radio.id === id);
    // }

    // function getAllInterfaces() {
    //   if (!isLoggedIn()) return unauthorized();
    //   return ok(interfaces);
    // }

    // function getInterfacesByAp(apId) {
    //   if (!isLoggedIn()) return unauthorized();
    //   let filteredInterfaces = interfaces.result.filter(iface => iface.ap_id === apId);
    //   let interfacesByAp: { count: number; result: Iface[] } = {
    //     count: filteredInterfaces.length,
    //     result: filteredInterfaces
    //   };

    //   return ok(interfacesByAp);
    // }

    // function getInterface() {
    //   const interfaceId = url.split('/interfaces/')[1];
    //   const iface = findInterface(interfaceId);
    //   return ok(iface);
    // }

    // function findInterface(id: string) {
    //   return interfaces.result.find(iface => iface.id === id);
    // }

    // helper functions

    function ok(body?) {
      return of(new HttpResponse({ status: 200, body }));
    }

    function error(message) {
      return throwError({ error: { message } });
    }

    function unauthorized() {
      return throwError({ status: 401, error: { message: 'Unauthorised' } });
    }

    function isLoggedIn() {
      return headers.get('Authorization') === 'Bearer fake-jwt-token';
    }
  }
}

export let fakeBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true
};
