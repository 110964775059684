import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import { ApMaps } from 'src/assets/data/ap-maps';

@Injectable({
  providedIn: 'root'
})
export class MarkerService {

  // capitals: ApMaps = new ApMaps();
  capitals: string = '/assets/data/usa-capitals.geojson';

  constructor(private http: HttpClient) { }

  // makeCapitalMarkers(map: L.map): void {
  //   this.http.get(this.capitals).subscribe((res: any) => {
  //     for (const c of res.features) {
  //       const lon = c.geometry.coordinates[0];
  //       const lat = c.geometry.coordinates[1];
  //       const marker = L.marker([lat, lon]);
  //       console.log(marker);
        
  //       marker.addTo(map);
  //     }
  //   });
  //  }
}
