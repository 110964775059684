<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ modalOpts.title }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="pl-4 pr-4">
  <ng-content select="[modal-body]"></ng-content>
</div>
<div class="modal-footer">
  <ng-content select="[modal-footer]"></ng-content>
</div>
