import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DiagramaFluxoService } from '../services/diagrama-fluxo.service';
import { forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
const moment = require('moment');


@Component({
  selector: 'app-diagrama-fluxo',
  templateUrl: './diagrama-fluxo.component.html',
  styleUrls: ['./diagrama-fluxo.component.scss']
})
export class DiagramaFluxoComponent implements OnInit {

  title = '';
  type = 'Sankey';
  data = [];
  data2 = [];

  dataAreas = [];
columnNames = ['From', 'To', 'Quantidade'];
  options = {
    height: 500,
    width: 1000,
    responsive: true,
    sankey: {
      node: {
        interactivity: true
      }
    }
  };

  validaGrafico: boolean = false;

  form: FormGroup;
  initialDate: string = moment().subtract(7, "day").format().split('T')[0];
  endDate: string = moment().format().split('T')[0];

  areasList: any[] = JSON.parse(localStorage.getItem('currentClient')).area_names;

  validateAllDay: boolean = false;

  @ViewChild("allDayInput") customCheck1;

   
  constructor(private diagramaFluxoService: DiagramaFluxoService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.getFlowData(1);

    this.initializeForm();
  }

  initializeForm(){
    this.form = this.formBuilder.group({
      initialDate: [moment().subtract(7,"day").format().split('T')[0]],
      endDate: [moment().format().split('T')[0]],
      columnSelect: ['']
    })
  }

  getFlowData(columns){
    this.validaGrafico = false;
    this.diagramaFluxoService.getTotalAreas('caruarushopping', this.initialDate, this.endDate)
    .subscribe(
      (data) => {
        this.areasList.push({
          area: 7,
          name: 'Praça de Alimentação 3'
        })

        this.areasList.push({
          area: 8,
          name: 'Praça de Alimentação 4'
        })

        

        data.totalSteps.forEach((element, index) => {
          
          // console.log(index)
          let area = this.areasList.filter((obj) => obj.area == index + 1);
          // console.log(this.areasList);

          this.data.push(['Total', `${area[0].name}`, element.sum_mac_count]);
          if (index != this.areasList.length){
            this.data2.push(['Total', `1_${index + 1}`, element.sum_mac_count]);
          }
        });
    }).add(() => {
      this.data = Object.assign([], this.data);
      this.dataAreas = Object.assign([], this.data);

      if (columns == 3 || columns == 4){
        this.getAreas(columns);
      } else {
        this.validaGrafico = true;
      }
      
    })
  }
  
  changeDate(){
    this.areasList = JSON.parse(localStorage.getItem('currentClient')).area_names;
    let initialDate = moment(this.form.controls['initialDate'].value);
    let endDate = moment(this.form.controls['endDate'].value);
    
    if (endDate.isSameOrAfter(initialDate)){
      this.initialDate = this.form.controls['initialDate'].value;
      this.endDate = this.form.controls['endDate'].value;

      this.data = Object.assign([], []);
      this.data2 = [];
      this.form.controls['columnSelect'].setValue('');
      this.getFlowData(1);
    }
  }

  onSelect(evt){
    let area = this.areasList.filter((obj) => obj.name == evt[0].name);
    let { outIndex, inIndex } = this.getIndex(evt[0].name, this.data);

    let dataGraph = this.data;
    let count = 0;
    
    for (let i = 1; i <= 5; i++){
      this.diagramaFluxoService.getDiagramData('tacaruna', `${this.data2[outIndex][0]}_${i}`, this.initialDate, this.endDate)
    .subscribe(
      (data) => {
        if (data){
          dataGraph.push([`${evt[0].name}`, `${this.areasList[i].name}  `, data.mac_count]);
        } else {
          dataGraph.push([`${evt[0].name}`, `${this.areasList[i].name}  `, 1]);
        }
    },
    error => {
    }).add(() => {
      count++;

      if (count == 5){
        this.data = Object.assign([], dataGraph);
        this.validaGrafico = true;
      }
    })
    }
    
  }

  getAreas(columns){
    this.areasList.forEach(element => {
      let area = this.areasList.filter((obj) => obj.name == element.name);
      let { outIndex, inIndex } = this.getIndex(element.name, this.data);

      let dataGraph = this.data;
      let count = 0;
      
      for (let i = 1; i <= 5; i++){
        this.data2.push([`${this.data2[outIndex][1]}_${i}`, '', 0]);
        this.diagramaFluxoService.getDiagramData('tacaruna', `${this.data2[outIndex][1]}_${i}`, this.initialDate, this.endDate)
      .subscribe(
        (data) => {
          if (data[0].mac_count > 0){
            dataGraph.push([`${element.name}`, `${this.areasList[i].name} `, data[0].mac_count]);
          } else {
            dataGraph.push([`${element.name}`, `${this.areasList[i].name} `, 1]);
          }
      },
      error => {
      }).add(() => {
        count++;

        if (count == 5){
          this.data = Object.assign([], dataGraph);
          this.validaGrafico = true;

          if (columns == 4){
            this.getFourColumns();
          }
        }
      })
      }
      });
    
      this.data = Object.assign([], this.data);
      this.data2.splice(0, 6);
  }

  getAllDay(){
    let columns = 1;

    if (this.customCheck1.nativeElement.checked){
      this.validateAllDay = true;

      this.diagramaFluxoService.getFlowAllDay('tacaruna').subscribe({
        next: data => {
          this.areasList.push({
            area: 7,
            name: 'Praça de Alimentação 3'
          })

          this.areasList.push({
            area: 8,
            name: 'Praça de Alimentação 4'
          })

          data.totalSteps.forEach((element, index) => {
            let area = this.areasList.filter((obj) => obj.area == index + 1);

            this.data.push(['Total', `${area[0].name}`, element.sum_mac_count]);
            if (index != this.areasList.length){
              this.data2.push(['Total', `1_${index + 1}`, element.sum_mac_count]);
            }
          });
        }, 
        complete: () => {
          this.data = Object.assign([], this.data);
          this.dataAreas = Object.assign([], this.data);

          if (columns == 3 || columns == 4){
            this.getAreas(columns);
          } else {
            this.validaGrafico = true;
          }
        }
      });
    } else {
      this.validateAllDay = false;
      this.data = Object.assign([], []);
      this.data2 = [];
      this.getFlowData(1);
    }
  }
  
  updateColumns(evt){
    let columns = Number(evt.target.value);

    this.data = Object.assign([], []);
    this.data2 = [];
    this.getFlowData(columns);
  }

  getFourColumns(){
    this.validaGrafico = false;
    this.areasList.forEach(element => {
      let area = this.areasList.filter((obj) => obj.name == element.name);
      let { outIndex, inIndex } = this.getIndex(element.name, this.data);

      let dataGraph = this.data;
      let count = 0;
      
      for (let i = 1; i <= 5; i++){
        this.diagramaFluxoService.getDiagramData('tacaruna', `${this.data2[outIndex][0]}_${i}`, this.initialDate, this.endDate)
      .subscribe(
        (data) => {
          if (data){
            dataGraph.push([`${element.name} `, `${this.areasList[i].name}  `, data.mac_count]);
          } else {
            dataGraph.push([`${element.name} `, `${this.areasList[i].name}  `, 100]);
          }
      },
      error => {
      }).add(() => {
        count++;

        if (count == 5){
          this.data = Object.assign([], dataGraph);
          this.validaGrafico = true;
        }
      })
      }
      });
    
      this.data = Object.assign([], this.data);
  }

  getIndex(element, searchArray) {
    for (let i = 0; i < searchArray.length; i++) {
        const array = searchArray[i];
        for (let j = 0; j < array.length; j++) {
            if (array[j] === element) {
                return { outIndex: i, inIndex: j };
            }
        }
    }
    return { outIndex: -1, inIndex: -1 };
}


}
