import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CardComponent } from './card/card.component';
import { APDetailComponent } from './components/ap-detail-component/ap-detail.component';
import { HistoryGlobalActionsComponent } from './components/history-global-actions/history-global-actions.component';
import { ListViewItemComponent } from './components/list-view-item/list-view-item.component';
import { ListViewComponent } from './components/list-view/list-view.component';
import { NotificationsDropdownComponent } from './components/notifications-dropdown/notifications-dropdown.component';
import { SearchBarClientComponent } from './components/search-bar-clients/search-bar-clients.component';
import { UserBadgeComponent } from './components/user-badge/user-badge.component';
import { ModalComponent } from './modal/modal.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SliderComponent } from './slider/slider.component';
import { TabsComponent } from './tabs/tabs.component';
import { TogglerComponent } from './toggler/toggler.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CollapseModule } from '../util/collapse';
import { DecibelMiliWattPipe } from './pipes/dbm.pipe';
import { ChannelGHzPipe } from './pipes/channel-ghz.pipe';
import { SecondsToDhmsPipe } from './pipes/sec-to-date.pipe';
import { InterfaceModePipe } from './pipes/interface-mode.pipe';
import { EncryptionPipe } from './pipes/encryption.pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SelectDropDownModule, SelectDropDownComponent } from 'ngx-select-dropdown';
import { ModalChangeClientsComponent } from './components/modal-change-clients/modal-change-clients.component';

@NgModule({
  imports: [
    SelectDropDownModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  declarations: [
    CardComponent,
    TogglerComponent,
    ModalComponent,
    TabsComponent,
    SearchBarComponent,
    SliderComponent,
    UserBadgeComponent,
    NotificationsDropdownComponent,
    ListViewComponent,
    APDetailComponent,
    HistoryGlobalActionsComponent,
    SearchBarClientComponent,
    ListViewItemComponent,
    HeaderComponent,
    SidebarComponent,
    DecibelMiliWattPipe,
    ChannelGHzPipe,
    SecondsToDhmsPipe,
    InterfaceModePipe,
    EncryptionPipe,
    ModalChangeClientsComponent,
  ],
  exports: [
    CardComponent,
    NgbModule,
    ReactiveFormsModule,
    TogglerComponent,
    ModalComponent,
    TabsComponent,
    SearchBarComponent,
    SliderComponent,
    UserBadgeComponent,
    NotificationsDropdownComponent,
    ListViewComponent,
    APDetailComponent,
    HistoryGlobalActionsComponent,
    SearchBarClientComponent,
    FormsModule,
    ListViewItemComponent,
    HeaderComponent,
    SidebarComponent,
    DecibelMiliWattPipe,
    ChannelGHzPipe,
    SecondsToDhmsPipe,
    InterfaceModePipe,
    EncryptionPipe,
    NgMultiSelectDropDownModule,
    SelectDropDownModule
  ]
})
export class SharedModule {}
