import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
declare function showMenu(): any;
declare function fechar(): any;
import { HostListener } from "@angular/core";
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {
  collapsedSidebar = false;

  screenHeight: number;
  screenWidth: number;

  constructor(private router: Router, public sharedService: SharedService) {
    this.getScreenSize();
  }

  ngOnInit() {
    setInterval( () => {
      var client = localStorage.getItem("currentClient");
      if(this.router.url == "/" && client != "undefined" && client){
        this.router.navigate(['/dashboard']);
      }
    },1500)

    // showMenu();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    if (this.screenWidth > 1120) {
      this.sharedService.desktop = true;
    } else {
      this.sharedService.desktop = false;
    }
  }

  onSidebarClick() {
    this.collapsedSidebar = false;
  }
}
