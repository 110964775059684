<div class="card" style="width: 100%">
  <div class="card-header pt-2 pb-2 bg-white border-bottom">
    <div class="row align-items-center">
      <div [ngClass]="{ col:cardOpts && cardOpts.hasToggler ? '':'', 'col-4': !cardOpts || !cardOpts.hasToggler }">
        <div class="row justify-content-start">
          <div class="icon-wrapper ml-3 mr-3">
            <img [src]="cardOpts ? cardOpts.iconSrc: ''" />
          </div>
          <span class="title">{{ cardOpts ? cardOpts.title : '' }}</span>
        </div>
      </div>
      <div *ngIf="cardOpts && cardOpts.hasToggler" class="col-30-percent text-center">
        <ng-content select="[toggler]"></ng-content>
      </div>
      <div *ngIf="cardOpts && cardOpts.hasActions" class="col">
        <ng-content select="[actions]"></ng-content>
      </div>
    </div>
  </div>
  <div class="card-body">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
