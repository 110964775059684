import { Component,  ViewChild,ElementRef, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { AP } from 'src/app/data/models/ap.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ChannelOpts2,ChannelOpts5, HT24Opts, HT5Opts, TxOpts } from 'src/app/data/models/lists.model';

//LA
import { chart, SolidGaugeChart } from 'highcharts';
import {Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import * as solidGauge from 'highcharts/modules/solid-gauge.src';
import { SharedService } from 'src/app/services/shared.service';
@Component({
  selector: 'app-ap-detail-component',
  templateUrl: './ap-detail.component.html',
  styleUrls: ['./ap-detail.component.scss']
})
export class APDetailComponent implements OnInit, AfterViewInit {
  @Input() ap: AP;
  @Output() visualizarAP = new EventEmitter();
  aps_update: {};
  atual_date: Date;
  totalOnliner :number = 0;
  channelOpts2 = ChannelOpts2;
  channelOpts5 = ChannelOpts5;
  ht24Opts = HT24Opts;
  ht5Opts = HT5Opts;
  txPowerOpts = TxOpts;
  loadAverage:any = [0.3,1];
  group : any;
  current_reference : any;
  different: boolean = false;
  main_ip: string = "";

  apForm: FormGroup;

  constructor(private formBuilder: FormBuilder, public sharedService: SharedService) {
    this.apForm = this.formBuilder.group({
      radios: this.formBuilder.array([])
    });
  }
  @ViewChild('chartTarget')
  chartTarget: ElementRef;
  options: any;
  chart: Highcharts.ChartObject;


  ngOnInit(): void {
    this.group = JSON.parse(localStorage.getItem("currentClient"));
    this.current_reference = JSON.parse(localStorage.getItem("currentReference"));
    let count_radio:number = 0;
    let count = 0;


    if(this.ap && this.ap.vagalumeRadios){
      this.ap.vagalumeRadios.map(radios => {
        radios.vagalumeInterfaces.forEach((element, index) => {
          if (!('name' in element)){
            count++;
            this.ap.interfaces = count;
            radios.vagalumeInterfaces.splice(index,1);
          }
        });
        
        this.ap.vagalumeRadios[count_radio].vagalumeInterfaces.sort(function(a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        radios.vagalumeInterfaces.map(interfaces => {
          if(this.current_reference.length){
            let interface_find = this.current_reference.find(element => {
              return element.ssid == interfaces.ssid && element.radio == radios.name && element.channel == radios.channel && element.htmode == radios.htmode && (interfaces.mac == element.mac || (interfaces.mac != "Desativada" && element.mac != "Desativada" ) )
            })
            if(interface_find){
              //alert("achou")
            }else{
              this.different = true;
            }
          }
        })
        count_radio++;
      })
    }
    

    // if(this.ap && this.ap.ip){
    //   console.log(this.ap.ip)
    // }else{
    //   console.log("nao tem ip...")
    // }


    if(localStorage.getItem("aps_update")){
      this.atual_date = new Date((new Date()).getTime() - 300000)
      this.aps_update = JSON.parse(localStorage.getItem("aps_update"))
      //this.aps_update['E8:B4:70:60:0A:AC'] = new Date()
    }
    var totalOnline =  this.ap.vagalumeRadios.map(radio => {
        return radio.vagalumeInterfaces.map(interface_ => {
          return parseInt(interface_.stations_count)

        })
    } )
    // for(let radio1 of totalOnline[0]){
    //   this.totalOnliner += radio1;
    // } for(let radio2 of totalOnline[1]){
    //   this.totalOnliner += radio2;
    // }
    this.apForm = this.formBuilder.group({
      radios: this.formBuilder.array(
        this.ap.vagalumeRadios.map(radio =>
          this.formBuilder.group({
            channel: radio.channel,
            ht: radio.htmode,
            tx: radio.txpower
          })
        )
      )
    });
  }
  ngAfterViewInit() {
    // var la_value = this.chartTarget.nativeElement.id.split("-")[1]
    // this.initOptions(parseFloat(la_value) );
    // this.chart = chart(this.chartTarget.nativeElement, this.options as any);
  }
  initOptions(chartValue = 0) {
    this.options = {
      credits:{
        enabled:false
      },
      chart: {
        spacing: [1,1,1,1],
        backgroundColor:'#f4f4f4',
        height: 40,
        width: 90,
        type: 'solidgauge'
      },
      title: {
        style:{
          color:'#f4f4f4'
        },
        text: undefined
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: {
          borderWidth: 1,
          backgroundColor: 'white',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc'
        }
      },
      tooltip: {
        enabled: false
      },
      // the value axis
      yAxis: {
        stops: [
          [0.5, 'green'], // green
          [0.75, 'yellow'], // yellow
          [0.9, '#DF5353'] // red
        ],
        length: 0,
        lineWidth: 1,
        minorTickInterval: null,
        tickAmount: 0,
        title: {
          y: 30
        },
        labels: {
          y: -50
        },
        min: 0,
        max: 1,
        plotBands: [
          { from: 0, to: 0.6, color: 'white' },
          { from: 0.5, to: 0.75, color: 'white' },
          { from: 0.75, to: 1, color: 'white' }
        ]
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: -81,
            borderWidth: 0,
            useHTML: true
          }
        }
      },
      series: [
        {
          data: [chartValue]
        }
      ]
    };
  }

  incrementeLA(){
  }
  get channelValue() {
    return this.apForm.get('channel').value;
  }

  getHtOpts(channelString) {
    let channel = channelString.split(' ')[0];

    if (channel > 11) {
      return HT5Opts;
    } else {
      return HT24Opts;
    }
  }

  enumSelector(def) {
    return Object.keys(def).map(key => ({ value: def[key], title: key }));
  }

  onVisualizarAPClick() {
    this.visualizarAP.emit();
  }

  typeof(value){
    return typeof(value)
  }
  getTime(value){
    let new_date = new Date(value);
    if(new_date){
      return new_date.getTime();
    }else{
      return 0;
    }
  }
  getAPIP(){ 
    return this.ap['ip']?this.ap['ip'][0]['ip'] : "IP não disponível"
  }

  async onFilterSubmit(modal){
  }

}
