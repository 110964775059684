import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { User } from '../data/models/user.model';
import { Task, AppError } from '../data/api/task.model';

import { environment } from '../../environments/environment';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get canEdit(): boolean {
    if (this.currentUserSubject.value.user.rules && this.currentUserSubject.value.user.rules.swarmcloud) {
      return this.currentUserSubject.value.user.rules.swarmcloud.edit;
    } else {
      return false;
    }
  }
  public get isRoot(): boolean {
    if (this.currentUserSubject.value.user.group && this.currentUserSubject.value.user.group.text) {
      return this.currentUserSubject.value.user.group.text == 'ROOT';
    } else {
      return false;
    }
  }

  async login(username: string, password: string) {
    try {
      const response = await this.http
        .post<any>(`${environment.configApiUrl}/users/login`, { username: username, password: password })
        .toPromise();
      let currentUser = { username: username, ...response };
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      localStorage.setItem('aps_update', JSON.stringify({}));
      this.currentUserSubject.next(currentUser);

      return Task.success(currentUser);
    } catch (e) {
      return Task.error(new AppError());
    }
  }

  getClients(userId) {
    return this.http.get<any>(`${environment.configApiUrlCaptive}/Users/${userId}`);
  }

  logout() {
    localStorage.clear();
    this.currentUserSubject.next(null);
  }
}
