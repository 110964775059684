import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecurrenceService {

  constructor(private http: HttpClient) { }

  getDataByRecurrence(controller_id, startDate, endDate): Observable<any> {
    return this.http.get(`${environment.configApiUrlCaptiveStaging}/connections/getTotalRecurrencebyPeriod/${controller_id}/${startDate}/${endDate}`);
  }
}
