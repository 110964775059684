import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { Connections } from './shared/connections';
import { GraphData } from './shared/graphData';
import { ConnectionsData } from './shared/connectionsData';
import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { Associacoes } from './shared/associacoes';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import {
  ApexAnnotations,
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexLegend,
  ApexResponsive,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent
} from 'ng-apexcharts';
import { SharedService } from '../services/shared.service';
const moment = require('moment');
declare var $: any;
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis?: ApexYAxis[];
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  colors?: string[];
  fill?: ApexFill;
  tooltip?: ApexTooltip;
  annotations?: ApexAnnotations;
  labels?: any;
  responsive?: ApexResponsive[];
  legend: ApexLegend;
};
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  // Etc
  controllerId = JSON.parse(localStorage.getItem('currentClient')).controller_id;
  groupZabbixName =
    'groupZabbix' in JSON.parse(localStorage.getItem('currentClient'))
      ? JSON.parse(localStorage.getItem('currentClient')).groupZabbix.name
      : '';
  radioPrefix = JSON.parse(localStorage.getItem('currentClient')).radio_prefix;
  client = JSON.parse(localStorage.getItem('currentClient')).name;
  groupZabbixId =
    'groupZabbix' in JSON.parse(localStorage.getItem('currentClient'))
      ? JSON.parse(localStorage.getItem('currentClient')).groupZabbix.id
      : '';
  validatePortal = JSON.parse(localStorage.getItem('currentClient')).vagamais;
  currentClient = JSON.parse(localStorage.getItem('currentClient'));

  // Top Vagalumes
  topVagalumesData: any[] = [];
  loadingTopVagalumes: boolean = false;

  // Histórico de APs
  apStatusHistory: any = [];
  loadingApHistory: boolean = false;

  // Status Autorização
  statusAuthData: SingleDataSet = [];
  statusAuthLabels = [];
  statusAuthOptions = {
    responsive: false,
    legend: {
      position: 'bottom',
      labels: {
        fontSize: 10,
        usePointStyle: true
      }
    }
  };
  loadingStatusAuth: boolean = false;

  // Autenticações
  authenticationsData: ChartDataSets[] = [];
  authenticationsLabels: any[] = [];
  loadingAuthentications: boolean = false;
  validateAuthenticationsData: boolean = false;
  authenticationsOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            suggestedMin: 0
          }
        }
      ]
    },
    elements: {
      line: {
        tension: 0.5,
        fill: true,
        borderWidth: 1
      },
      plugins: {
        legend: { display: true },
        annotation: {
          annotations: [
            {
              type: 'line',
              scaleID: 'x',
              value: 'March',
              borderColor: 'orange',
              borderWidth: 0.5,
              label: {
                display: true,
                position: 'center',
                color: 'orange',
                content: 'LineAnno',
                font: {
                  weight: 'bold'
                }
              }
            }
          ]
        }
      }
    }
  };

  // Conexões
  connections: Connections[] = [];
  connectionsData: ConnectionsData[] = [];
  graphData: GraphData[] = [];
  hostnames = [];
  loadingConnections: boolean = false;
  validateConnections: boolean = false;
  connectionsLabels = [];
  connectionsOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            suggestedMin: 0
          },
          stacked: true
        }
      ]
    }
  };

  // Experiência do Usuário
  userExperienceData: ChartDataSets[] = [];
  userExperienceLabels = [];
  loadingUserExperience: boolean = false;
  userExperienceOptions = {
    legend: { display: false },
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            suggestedMin: 0,
            callback: function(value) {
              return value.toFixed(0) + '%'; // convert it to percentage
            }
            // max : 100,
          },
          gridLines: {
            display: false
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false
          }
        }
      ]
    },
    elements: {
      line: {
        tension: 0.5,
        fill: true,
        borderWidth: 1
      },
      point: {
        radius: 0
      },
      plugins: {
        legend: { display: false },
        annotation: {
          annotations: [
            {
              type: 'line',
              scaleID: 'x',
              value: 'March',
              borderColor: 'orange',
              borderWidth: 0.5,
              label: {
                display: true,
                position: 'center',
                color: 'orange',
                content: 'LineAnno',
                font: {
                  weight: 'bold'
                }
              }
            }
          ]
        }
      }
    }
  };

  // Form período
  form: FormGroup;
  initialDate: string = moment()
    .subtract(7, 'day')
    .format()
    .split('T')[0];
  endDate: string = moment()
    .format()
    .split('T')[0];
  validatePeriod: boolean = false;

  // Dados em tempo real dashboard
  detecteds: any;
  connected: any;
  online: any;
  availableAps: any;
  ap: any;
  interval: any;
  loadingDeviceInfo: boolean = false;
  loadingDetecteds: boolean = false;

  radarChartData: ChartDataSets[] = [
    { data: [], label: 'Domingo' },
    { data: [], label: 'Segunda-feira' },
    { data: [], label: 'Terça-feira' },
    { data: [], label: 'Quarta-feira' },
    { data: [], label: 'Quinta-feira' },
    { data: [], label: 'Sexta-feira' },
    { data: [], label: 'Sábado' }
  ];

  radarChartOptions: RadialChartOptions = {
    responsive: true,
    legend: {
      position: 'right'
    }
  };

  public radarChartType: ChartType = 'radar';

  usersPerDayData: any = [];
  usersCount = 0;

  radarChartLabels = [
    '00:00 a 02:00',
    '02:00 a 04:00',
    '04:00 a 06:00',
    '06:00 a 08:00',
    '08:00 a 10:00',
    '10:00 a 12:00',
    '12:00 a 14:00',
    '14:00 a 16:00',
    '16:00 a 18:00',
    '18:00 a 20:00',
    '20:00 a 22:00',
    '22:00 a 00:00'
  ];
  validateRadarChart: boolean = false;
  validateExperienceData: boolean = false;

  chartOptions: Partial<ChartOptions>;
  chartOptionsLine: Partial<ChartOptions>;
  chartOptionsConnections: Partial<ChartOptions>;

  constructor(
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    public sharedService: SharedService
  ) {
    this.chartOptions = {
      series: [],
      chart: {
        height: 300,
        width: 400,
        type: 'heatmap'
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#008FFB', '#008FFB', '#008FFB', '#008FFB', '#008FFB', '#008FFB', '#008FFB']
    };

    (this.chartOptionsLine = {
      series: [],
      chart: {
        height: 290,
        type: 'area',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          export: {
            csv: {
              filename: 'authenticatednew',
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              }
            },
            svg: {
              filename: 'authenticatednew'
            },
            png: {
              filename: 'authenticatednew'
            }
          }
        }
      },
      annotations: {
        yaxis: [
          {
            y: 30,
            borderColor: '#999',
            label: {
              text: 'Support',
              style: {
                color: '#fff',
                background: '#00E396'
              }
            }
          }
        ],
        xaxis: [
          {
            x: new Date('14 Nov 2012').getTime(),
            borderColor: '#999',
            label: {
              text: 'Rally',
              style: {
                color: '#fff',
                background: '#775DD0'
              }
            }
          }
        ]
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#F26522', '#6d6e71', '#672c0f'],
      stroke: {
        curve: 'straight',
        width: 2
      },
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: 'vertical',
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        }
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 30,
        labels: {
          show: true,
          datetimeUTC: false,
          datetimeFormatter: {
            year: 'yyyy',
            month: "MMM 'yy",
            day: 'dd MMM',
            hour: 'HH:mm'
          }
        }
      },
      yaxis: [
        {
          seriesName: 'Experiência',
          show: true,
          labels: {
            formatter: function(val: number): string {
              return val.toFixed(0) + '%';
            }
          },
          title: {
            text: 'Experiência da conexão (%)'
          }
        },
        {
          seriesName: '2.4',
          show: false,
          labels: {
            formatter: function(val: number): string {
              return val.toFixed(0);
            }
          }
        },
        {
          seriesName: '2.4',
          show: true,
          opposite: true,
          labels: {
            formatter: function(val: number): string {
              return val.toFixed(0);
            }
          },
          title: {
            text: 'Número de estações associadas'
          }
        }
      ],
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: undefined,
        fillSeriesColor: false,
        style: {
          fontSize: '12px',
          fontFamily: undefined
        },
        onDatasetHover: {
          highlightDataSeries: false
        },
        x: {
          show: true,
          format: 'dd MMM HH:mm',
          formatter: undefined
        },
        y: {
          formatter: undefined,
          title: {
            formatter: seriesName => seriesName
          }
        },
        z: {
          formatter: undefined,
          title: 'Size: '
        },
        marker: {
          show: true
        },
        fixed: {
          enabled: false,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0
        }
      }
    }),
      (this.chartOptionsConnections = {
        series: [],
        chart: {
          height: 220,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            export: {
              csv: {
                filename: 'authenticatednew',
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                }
              },
              svg: {
                filename: 'authenticatednew'
              },
              png: {
                filename: 'authenticatednew'
              }
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#F26522', '#6d6e71', '#672c0f'],
        stroke: {
          curve: 'straight',
          width: 1.5
        },
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 10,
          labels: {
            show: true,
            datetimeUTC: false,
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'dd MMM',
              hour: 'HH:mm'
            }
          }
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: false,
          style: {
            fontSize: '12px',
            fontFamily: undefined
          },
          onDatasetHover: {
            highlightDataSeries: false
          },
          x: {
            show: true,
            format: 'dd MMM HH:mm',
            formatter: undefined
          },
          y: {
            formatter: undefined,
            title: {
              formatter: seriesName => seriesName
            }
          },
          z: {
            formatter: undefined,
            title: 'Size: '
          },
          marker: {
            show: true
          },
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0
          }
        }
      });
  }

  ngOnInit() {
    this.initializeForm();
    this.getApHistory();
    this.getUserExperience();
    this.getTopVagalumes();
    this.getApConnections();
    this.getAuthentications();
    this.getErrorsAuth();
    this.updateRealTimeData();
    this.getWeeklyConnections();

    $(document).ready(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.interval = setInterval(() => {
      this.getAp();
      this.getOnlineDeviceData();
      this.getUserExperience();
    }, 300000);
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      initialDate: [
        moment()
          .subtract(7, 'day')
          .format()
          .split('T')[0]
      ],
      endDate: [
        moment()
          .format()
          .split('T')[0]
      ]
    });
  }

  getApHistory() {
    this.loadingApHistory = true;

    this.dashboardService
      .getApStatusHistory(
        moment()
          .subtract(30, 'day')
          .format()
          .split('T')[0],
        this.endDate,
        this.groupZabbixId
      )
      .subscribe(data => {
        if (data.length > 0) {
          data.forEach((element, index) => {
            if (!element.hostname.includes('Swarm')) {
              if (element.hostname.includes('--')) {
                element.hostname = element.hostname.split('--')[1];
              } else if (element.hostname.includes('Youw')) {
                element.hostname = element.hostname.split('-')[1];
              } else if (element.hostname.includes('_')) {
                element.hostname = element.hostname.split('_')[1];
              }
            }
          });

          data.sort((a, b) => {
            a = moment(a.datetime)
              .format()
              .split('T')[0];
            a = moment(b.datetime)
              .format()
              .split('T')[0];

            if (moment(a.datetime).isAfter(moment(b.datetime))) {
              return -1;
            } else if (moment(a.datetime).isBefore(moment(b.datetime))) {
              return 1;
            } else {
              return 0;
            }
          });

          this.apStatusHistory = data;
        }
      })
      .add(() => {
        this.loadingApHistory = false;
      });
  }

  switchTabs(tab) {
    if (tab == 'Dashboard') {
      this.validatePeriod = true;
    } else {
      this.validatePeriod = false;

      if (!this.loadingConnections && !this.loadingUserExperience) {
        this.loadingConnections = true;
        this.loadingUserExperience = true;

        setTimeout(() => {
          this.loadingConnections = false;
          this.loadingUserExperience = false;
        }, 500);
      }
    }
  }

  getTopVagalumes() {
    this.loadingTopVagalumes = true;
    this.topVagalumesData = [];
    this.dashboardService
      .getApsByPeriod(this.controllerId, this.initialDate, this.endDate)
      .subscribe(data => {
        if (data) {
          if (data.length > 0) {
            this.topVagalumesData = data;
            this.topVagalumesData.sort(function(a, b) {
              return a.periodAverage > b.periodAverage ? -1 : a.periodAverage < b.periodAverage ? 1 : 0;
            });

            for (let i = 0; i < this.topVagalumesData.length; i++) {
              this.topVagalumesData[i].periodAverage = this.topVagalumesData[i].periodAverage.toFixed(2).split('.')[1];
            }
          }
        }
      })
      .add(() => {
        this.loadingTopVagalumes = false;
      });
  }

  getApConnections() {
    this.loadingConnections = true;
    this.validateConnections = false;
    this.dashboardService
      .getApsConnections(
        this.controllerId,
        moment()
          .subtract(1, 'day')
          .format(),
        moment().format()
      )
      .subscribe(data => {
        // if (data.length > 0){
        this.getConnectionsData(data);
        // }
      })
      .add(() => {
        this.loadingConnections = false;
      });
  }

  getErrorsAuth() {
    if (this.currentClient.cpaIp != undefined || this.currentClient.isMktk != undefined) {
      this.statusAuthLabels = ['Sucesso'];
    } else {
      this.statusAuthLabels = [
        'Sucesso',
        'Falha usuário',
        'Falha autenticados',
        'Falha login',
        'Falha senha',
        'Falha Token'
      ];
    }

    this.loadingStatusAuth = true;
    let errors: any[] = [];

    this.dashboardService
      .getConnections(this.controllerId, this.initialDate, this.endDate)
      .subscribe(data => {
        if (data) {
          this.statusAuthData = [];

          errors.push(data.total - data.totalError);
          errors.push(data.totalUserFail);
          errors.push(data.totalUserFailAuthenticate);
          errors.push(data.totalUserFailLogin);
          errors.push(data.totalUserFailSenha);
          errors.push(data.totalUserFailToken);

          this.statusAuthData.push(errors);
        }
      })
      .add(() => {
        this.loadingStatusAuth = false;
      });
  }

  getUserExperience() {
    this.loadingUserExperience = true;
    let obj = {
      name: '',
      type: 'area',
      data: []
    };

    this.chartOptionsLine.series = [];
    this.chartOptionsLine.xaxis.categories = [];

    this.userExperienceLabels = [];
    this.userExperienceData = [];

    forkJoin([
      this.dashboardService.getUserExperienceDataAllClients(this.groupZabbixName, this.get24h(1), this.get24h(2)),
      this.dashboardService.getAuthenticatedByGroup(this.get24h(1), this.get24h(2), this.groupZabbixId)
    ])
      .subscribe(data => {
        if (data) {
          for (let i = 0; i < data[0].length; i++) {
            let valor = Number(data[0][i].experience.split('%')[0]);
            if (Number(valor) < 50) {
              obj.data.push(Number(valor) + 50.0);
            } else {
              let resto = 100 - Number(valor);
              obj.data.push(Number(valor) + resto);
            }

            data[0][i].datetime = moment(data[0][i].datetime).format();
            this.chartOptionsLine.xaxis.categories.push(data[0][i].datetime);
          }

          obj.name = 'Experiência de conexão';
          this.chartOptionsLine.series.push(obj);

          if (data[1]) {
            this.chartOptionsLine.series.push({ name: 'Conectados 2,4GHz', type: 'line', data: data[1].ghz2_4 });
            this.chartOptionsLine.series.push({ name: 'Conectados 5GHz', type: 'line', data: data[1].ghz5 });

            this.userExperienceData.push(obj);
            this.validateExperienceData = true;
          } else {
            this.chartOptionsLine.series = [];
            this.validateExperienceData = false;
          }
        }
      })
      .add(() => {
        this.loadingUserExperience = false;
      });
  }

  randomIntBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  get24h(tipo) {
    let dataAnterior = moment()
      .subtract(23.9, 'hours')
      .format();
    let dataAtual = moment().format();

    if (tipo == 1) {
      return dataAnterior;
    } else {
      return dataAtual;
    }
  }

  getAuthentications() {
    this.authenticationsData = [];
    this.authenticationsLabels = [];
    this.loadingAuthentications = true;
    this.validateAuthenticationsData = false;

    forkJoin([
      this.dashboardService.getSummary(this.controllerId, this.initialDate, this.endDate),
      this.dashboardService.getNews(this.controllerId, this.initialDate, this.endDate)
    ])
      .subscribe(data => {
        if (data) {
          this.getAuthenticationsData(data[0]);
          this.getNewsData(data[1]);

          this.getDates();
          this.validateAuthenticationsData = true;
        }
      })
      .add(() => {
        this.loadingAuthentications = false;
      });
  }

  getAuthenticationsData(data) {
    let object = {
      label: '',
      data: []
    };

    let authentications: any = [];

    if (data) {
      data.forEach(dados => {
        authentications.push(dados.total);
      });
    }

    object.label = 'Autenticados';
    object.data = authentications;

    this.authenticationsData.push(object);
  }

  getNewsData(data) {
    let object = {
      label: '',
      data: []
    };

    let novos: any[] = [];

    data.forEach(dados => {
      novos.push(dados.total);
    });

    object.label = 'Novos';
    object.data = novos;

    this.authenticationsData.push(object);
  }

  getDates() {
    const startDate = moment(this.initialDate);
    const endDate = moment(this.endDate);

    let currentDate = startDate;
    while (currentDate <= endDate) {
      this.authenticationsLabels.push(moment(currentDate).format('DD/MM/YYYY'));
      currentDate = moment(currentDate).add(1, 'days');
    }
  }

  updateRealTimeData() {
    this.getAp();
    this.getOnlineDeviceData();
    this.getTotalDevices();
  }

  getAp() {
    this.dashboardService.getTotalAp(this.radioPrefix).subscribe(data => {
      this.ap = data.length;
      let availableAps = [];
      data.forEach(element => {
        if (element.offline === 0) {
          availableAps.push(element);
        }
      });

      this.availableAps = availableAps.length;
    });
  }

  getOnlineDeviceData() {
    this.online = '';
    this.connected = '';
    this.loadingDeviceInfo = true;
    this.dashboardService
      .getTotalOnline(this.radioPrefix)
      .subscribe(data => {
        if (data.length > 0) {
          this.online = data[0].totalonline;
          this.connected = data[0].totaldevices;
        }
      })
      .add(() => {
        this.loadingDeviceInfo = false;
      });
  }

  getTotalDevices() {
    let currentHour = moment()
      .subtract(1, 'hour')
      .format();
    this.loadingDetecteds = true;

    let getRadioPrefix;

    if (this.radioPrefix != undefined) {
      getRadioPrefix = this.radioPrefix.replace(/-/gi, '_');
    } else {
      getRadioPrefix = JSON.parse(localStorage.getItem('currentClient')).clientname_radioPrefix.replace(/-/gi, '_');
    }

    this.dashboardService
      .getTotalDevices(currentHour, getRadioPrefix)
      .subscribe(data => {
        if (data.length > 0) {
          this.detecteds = data[0].Total_Stations;
        }
      })
      .add(() => {
        this.loadingDetecteds = false;
      });
  }

  getConnectionsData(data) {
    this.chartOptionsConnections.xaxis.categories = data.date;
    this.chartOptionsConnections.series.push({ name: 'Detectados', data: data.detected });
    this.chartOptionsConnections.series.push({ name: 'Conectados', data: data.device });
    // this.chartOptionsConnections.series.push({name: 'Online', data: data.online});

    this.validateConnections = true;
  }

  returnConnectionsData() {
    let graphData: ConnectionsData = new ConnectionsData();
    for (let i = 0; i < this.hostnames.length; i++) {
      graphData.label = this.connections[i].label;
      graphData.data = this.connections[i].data;

      this.connectionsData.push(graphData);
      graphData = new ConnectionsData();
    }
  }

  getConnectionsLabels() {
    for (let i = 6; i > 0; i--) {
      this.connectionsLabels.push(
        moment()
          .subtract(i, 'day')
          .format('DD/MM/YYYY')
          .split('T')[0]
      );
    }

    this.connectionsLabels.push(
      moment()
        .format('DD/MM/YYYY')
        .split('T')[0]
    );
  }

  formatDate(date) {
    let dateFormated = date.slice(8, 10) + '/' + date.slice(5, 7) + '/' + date.slice(0, 4);
    return dateFormated;
  }

  getHostnames(data) {
    let hostnameAtual = data[0].hostname;

    for (let i = 0; i < data.length; i++) {
      if (data[i].hostname === hostnameAtual) {
        if (this.hostnames.includes(hostnameAtual)) {
          if (i != data.length - 1) {
            hostnameAtual = data[i + 1].hostname;
          }
        } else {
          this.hostnames.push(hostnameAtual);
        }
      }
    }
  }

  changeDate(selectType) {
    let initialDate = moment(this.form.controls['initialDate'].value);
    let endDate = moment(this.form.controls['endDate'].value);

    if (selectType == 1) {
      this.form.controls['endDate'].setValue('');
    }

    if (endDate.isSameOrAfter(initialDate) && this.form.controls['endDate'].value != '') {
      this.initialDate = this.form.controls['initialDate'].value;
      this.endDate = this.form.controls['endDate'].value;

      this.getAuthentications();
      this.getErrorsAuth();
      this.getTopVagalumes();
      this.getWeeklyConnections();
    }
  }

  getWeeklyConnections() {
    this.usersCount = 0;

    this.dashboardService.getWeeklyConnections(this.initialDate, this.endDate, this.controllerId).subscribe(data => {
      data.forEach(element => {
        if (element.connections.length != 0) {
          this.usersCount++;
        }
      });
      this.getClientsPerDayData(data);
    });
  }

  getClientsPerDayData(data) {
    this.usersPerDayData = [];
    let i = 1;
    let arrayPerDay = [];
    let arrayPerDay_aux = [];

    let weekDays = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];

    data.forEach((element, index) => {
      const sumDays = element.connections.reduce((acc, curr, index) => {
        if (index % 2 === 0) {
          let sumDay = {
            x: this.radarChartLabels[i - 1],
            y: acc + curr + element.connections[index + 1]
          };

          i++;

          arrayPerDay.push(sumDay);
          return (acc = 0);
        } else {
          return acc;
        }
      }, 0);
      i = 1;

      arrayPerDay_aux = arrayPerDay.splice(0, 3);

      arrayPerDay.push(...arrayPerDay_aux);

      let usersPerDay = {
        name: weekDays[index],
        data: arrayPerDay
      };

      this.usersPerDayData.push(usersPerDay);
      arrayPerDay = [];
    });

    this.chartOptions.series = this.usersPerDayData;
  }
}
