import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MapsService {
  constructor(private http: HttpClient) {}

  getRadiosByRadioPrefix(radio_prefix): Observable<any> {
    if (radio_prefix.length < 4) {
      return this.http.get<any>(
        `${
          environment.configApiUrlCaptiveStaging
        }/radios?filter={"where":{"or":[{"clientname":"${radio_prefix.toUpperCase()}"},{"clientname":"${radio_prefix.toLowerCase()}"}]}}`
      );
    } else {
      return this.http.get<any>(
        `${environment.configApiUrlCaptiveStaging}/radios?filter={"where":{"clientname":{"regexp":"${radio_prefix}/gi"}}}`
      );
    }
  }

  getRadiosConnect(controller_id, datetime): Observable<any> {
    return this.http.get(
      `${environment.configApiUrlCaptiveStaging}/ap_connecteds/map_data/${controller_id}/${datetime}`
    );
  }

  getRadiosSensor(radio_prefix, datetime): Observable<any> {
    return this.http.get(`${environment.configApiUrlCaptiveStaging}/ap_sensors/map_data/${radio_prefix}/${datetime}`);
  }

  getStepsByMac(controllerId, initialDate, endDate, mac): Observable<any>{
    return this.http.get(`${environment.configApiUrl}/macTracker/getStepsByMac/${controllerId}/${initialDate}/${endDate}/${mac}`)
  }

  getMacs(controllerId): Observable<any>{
    return this.http.get(`${environment.configApiUrl}/macTracker/getMacByClient/${controllerId}`)
  }

  getApsConnections(radioPrefix: string, start_date: string, end_date: string): Observable<any> {
    return this.http.get<any>(
      `${environment.configApiUrlCaptiveStaging}/statuses/dayData/${radioPrefix}/${start_date}/${end_date}
    `
    );
  }
}
