<div ngbDropdown class="nav-item mr-5">
  <img class="icon pointer" ngbDropdownToggle src="assets/icons/notifications-header.svg" />
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu-right shadow-sm">
    <div class="notifications-card">
      <div class="notifications-header font-weight-semi-bold row no-gutters justify-content-center">
        <div class="col-4 align-self-center">
          <div>Notifications</div>
        </div>
        <div class="col-3 align-self-center">
          <div class="text-primary text-left">
            <span class="notification-count pl-2 pr-2 pt-1 pb-1">
              {{ notificationsTotal ? notificationsTotal : 0 }} new</span
            >
          </div>
        </div>
      </div>
      <app-tabs [tabsOpts]="tabsOpts" class="notifications-dropdown-tabs"></app-tabs>
    </div>
  </div>
</div>

<ng-template #captivePortalTabTitle>
  <div class="row no-gutters">
    <span>Captive Portal</span>
    <div class="text-primary pl-1">
      <span class="notification-count pl-1 pr-1">
        {{
          captivePortalNotificationsCount
            ? captivePortalNotificationsCount > 99
              ? '99+'
              : captivePortalNotificationsCount
            : 0
        }}
      </span>
    </div>
  </div>
</ng-template>
<ng-template #captivePortalTab>
  <ng-content select="[captivePortalNotificationsList]"></ng-content>
</ng-template>

<ng-template #serviceStatusTabTitle
  ><div class="row no-gutters">
    <span>Service Status</span>
    <div class="text-primary pl-1">
      <span class="notification-count pl-1 pr-1">
        {{
          serviceStatusNotificationsCount
            ? serviceStatusNotificationsCount > 99
              ? '99+'
              : serviceStatusNotificationsCount
            : 0
        }}
      </span>
    </div>
  </div></ng-template
>
<ng-template #serviceStatusTab>
  <ng-content select="[serviceStatusNotificationsList]"></ng-content>
</ng-template>

<ng-template #networkTabTitle
  ><div class="row no-gutters">
    <span>Network</span>
    <div class="text-primary pl-1">
      <span class="notification-count pl-1 pr-1">
        {{ networkNotificationsCount ? (networkNotificationsCount > 99 ? '99+' : networkNotificationsCount) : 0 }}
      </span>
    </div>
  </div></ng-template
>
<ng-template #networkTab>
  <ng-content select="[networkNotificationsList]"></ng-content>
</ng-template>
