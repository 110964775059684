<ol class="breadcrumb">
  <div class="pr-4 d-flex">
    <img src="assets/icons/home-breadcrumbs.svg" />
  </div>
  <li *ngFor="let breadcrumb of breadcrumbs">
    <span [routerLink]="breadcrumb.url" routerLinkActive="router-link-active">
      {{ breadcrumb.label }}
    </span>
  </li>
</ol>
