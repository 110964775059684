import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecurrenceRoutingModule } from './recurrence-routing.module';
import { RecurrenceComponent } from './recurrence.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';


@NgModule({
  declarations: [RecurrenceComponent],
  imports: [
    CommonModule,
    RecurrenceRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule
  ]
})
export class RecurrenceModule { }
