import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidateMenuService {

  validateDiagram: boolean = false;
  validateMap: boolean = false;

  constructor() { }
}
