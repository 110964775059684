import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RelatoriosService {

  constructor(private http: HttpClient) { }

  getCampaigns(client_id, start_date, end_date): Observable<any>{
    return this.http.get<any>(`${environment.configApiUrlCaptiveStaging}/summaries/getPeriodSummary/${client_id}/${start_date}/${end_date}`);
  }

  getReportByPeriodAuthenticated(controllerId, start_date, end_date): Observable<any>{
    return this.http.get<any>(`${environment.configApiUrlCaptiveStaging}/connections/reportByPeriod/${controllerId}/${start_date}/${end_date}`);
  }
  
  getReportByPeriodNew(controllerId, start_date, end_date): Observable<any>{
    return this.http.get<any>(`${environment.configApiUrlCaptiveStaging}/connection_count_2s/reportByPeriodNew/${controllerId}/${start_date}/${end_date}`);
  }

  getVideos(company_id, endDateReport, startDateReport): Observable<any>{
    return this.http.get(`${environment.configApiUrlCaptiveStaging}/videoManifests?filter={ "where" : { "client_id" : "${company_id}"}, "order":"timeCreated DESC"}`)
  }

  getDailyMediaReport(videoId, startDate, endDate): Observable<any> {
    return this.http.get(
      `https://api-controller.vagalumewifi.com.br/daily-campaign-view/${videoId}/${startDate}/${endDate}`
    );
  }
  
  getTotalMediaCount(videoId): Observable<any>{
    return this.http.get(`https://api-controller.vagalumewifi.com.br/daily-campaign-view/totalViews/${videoId}`
    );
  }

  getReportByDayTotal(controllerId, start_date, end_date): Observable<any> {
    return this.http.get<any>(`${environment.configApiUrlCaptiveStaging}/connections/reportByDayTotal/${controllerId}/${start_date}/${end_date}`)
  }
}
