import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { RecurrenceService } from '../services/recurrence.service';
const moment = require('moment');
declare var $: any;

@Component({
  selector: 'app-recurrence',
  templateUrl: './recurrence.component.html',
  styleUrls: ['./recurrence.component.scss']
})
export class RecurrenceComponent implements OnInit {

  controllerId = JSON.parse(localStorage.getItem('currentClient')).controller_id;
  
  formulario: FormGroup;

  recurrenceData: ChartDataSets[] = [];
  barChartOptions: ChartOptions = {
    responsive: true,
  };

  loading: boolean = false;
  recurrenceLabels: Label[] = ['1x na semana', '2x na semana', '3x na semana', '4x na semana', '5x na semana', '6x na semana', '7x na semana'];
  // barChartOptions = {
  //   scaleShowVerticalLines: false,
  //   responsive: true,
  //   scales: {
  //     yAxes: [
  //       {
  //         display: true,
  //         ticks: {
  //           suggestedMin: 0
  //         }
  //       }
  //     ]
  //   }
  // };

  yearList: any[] = [];
  weekList: any[] = [];

  chosenYear = "";
  chosenWeek = "";
  
  startDate = "";
  endDate = "";

  constructor(private formBuilder: FormBuilder, private recurrenceService: RecurrenceService) { }

  ngOnInit() {
    this.initializeForm();
    this.getYearList();
    this.chosenCurrentWeek();

    if (this.chosenYear) {
      this.selectYear();
    }
    if (this.chosenWeek) {
      this.selectWeek(true);
    }
    
    $(document).ready(function() {
      $('[data-toggle="tooltip"]').tooltip();
    })
  }

  initializeForm(){
    this.formulario = this.formBuilder.group({
      ano: [''],
      semana: ['']
    })
  }

  getYearList(){
    let auxYear = moment().get("year") - 2021;

    for (let aux = 0; aux <= auxYear; aux++) {
      this.yearList.push(`Ano de ${moment().get("year") - aux}`);
    }
    
    var endDate = moment()
      .isoWeekday(1)
      .subtract(1, "days");

    var startDate = moment(endDate)
      .subtract(6, "days")
      .format("DD/MM");

    endDate = moment(endDate).format("DD/MM");
    this.chosenYear = `Ano de ${moment().format("YYYY")}`;
    this.chosenWeek = `${startDate} - ${endDate}`;
  }

  chosenCurrentWeek() {
    let quantifyWeek = moment(moment()).isoWeek();
    var year = this.chosenYear.replace("Ano de", "");
    var startDate = "";
    var endDate = "";
    for (let aux = 1; aux <= quantifyWeek; aux++) {
      var week = aux.toString();

      startDate = moment()
        .isoWeek(week)
        .isoWeekYear(year)
        .isoWeekday(1);

      endDate = moment(startDate)
        .add(6, "days")
        .format("DD/MM");

      startDate = moment(startDate).format("DD/MM");

      this.weekList[aux - 1] = `${startDate} - ${endDate}`;
    }
  }

  updateWeek(){
    // this.getYearList();
    this.chosenYear = this.formulario.controls['ano'].value;
    
    this.selectYear();
    this.chosenWeek = this.weekList[0];
    this.selectWeek();
    
  }

  selectYear() {
    this.weekList = [];
    var year = this.chosenYear.replace("Ano de", "");
    var startDate = "";
    var endDate = "";

    if (moment(year).format("yyyy") == moment().format("yyyy")) {
      this.chosenCurrentWeek();
    } else {
      let quantifyWeek = moment(moment(year)).isoWeeksInYear();

      for (let aux = 1; aux <= quantifyWeek; aux++) {
        var week = aux.toString();

        startDate = moment()
          .isoWeek(week)
          .isoWeekYear(year)
          .isoWeekday(1);

        endDate = moment(startDate)
          .add(6, "days")
          .format("DD/MM");

        startDate = moment(startDate).format("DD/MM");

        this.weekList[aux - 1] = `${startDate} - ${endDate}`;
      }
    }
    if (this.chosenWeek) {
      this.dateWeekGenerate(this.chosenYear);
    }
  }
  
  dateWeekGenerate(year) {
    this.endDate = moment()
      .isoWeek(this.chosenWeek)
      .isoWeekYear(year)
      .isoWeekday(1)
      .toDate();
    this.startDate = moment(this.endDate).subtract(6, "days");
  }

  selectWeek(firstLoad?: boolean) {
    this.chosenWeek = this.formulario.controls['semana'].value;
      
      this.startDate = this.formatDate(this.chosenWeek.split(' - ')[0] + "/" + moment().year());
      this.endDate = this.formatDate(this.chosenWeek.split(' - ')[1] + "/" + moment().year());
      
      if (firstLoad){
        let weekLength = this.weekList.length;
        this.formulario.controls['semana'].setValue(this.weekList[this.weekList.length - 2]);
        let initialDate = this.formatDate(this.weekList[weekLength - 2].split(' - ')[0] + "/" + moment().year());
        let endDate = this.formatDate(this.weekList[weekLength - 2].split(' - ')[1] + "/" + moment().year());

        this.startDate = initialDate;
        this.endDate = endDate;
      }

      let recurrenceData = [];
      this.loading = true;
      this.recurrenceData = [];

      this.recurrenceService.getDataByRecurrence(this.controllerId, this.startDate, this.endDate)
      .subscribe(
        (data) => {
          if (data.length > 0){
            for (let i = 0; i < data.length; i++){
              recurrenceData.push(data[i].total);
            }
          }

          let obj = {
            data: recurrenceData,
            label: 'Recorrência por dia'
          }
          
          this.recurrenceData.push(obj);
      }).add(() => {
        this.loading = false;
      })
    // await ws
    //   .getDataByRecurrence(this.getControllerId, this.startDate, this.endDate)
    //   .then(response => {
    //     let count = 1;
    //     console.log(response.data[0]);

    //     for (let aux = 0; aux < 7; aux++) {
    //       let countString = count;
    //       this.dataGraph.recurrence[aux] = countString.toString();

    //       this.dataGraph.totalRecurrence[aux] =
    //         response.data[0].analysedData[count].length;
    //       count++;
    //     }
    //   });
    // this.componentReRender++;
  }

  formatDate(date){
    let dateFormated = date.slice(6,10) + "-" + date.slice(3,5) +  "-" + date.slice(0,2);
    return dateFormated;
  }

}
