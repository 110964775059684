import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { forkJoin } from 'rxjs';
import { DashboardService } from '../services/dashboard.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import html2canvas from 'html2canvas';
import * as printJS from 'print-js';  
import { RelatoriosService } from '../services/relatorios.service';
import { SingleDataSet } from 'ng2-charts';
import { ChartOptions } from '../relatorios-zabbix/shared/chartOptions';
import { ChartComponent } from 'ng-apexcharts';
import { ChartGraphOptions } from '../videos/graph-medias/graph-medias.component';
import { ChartOptionsGender } from '../relatorios-zabbix/shared/chartOptionsGender';
import { ChartNonAxisOptions } from '../relatorios-zabbix/shared/chartNonAxisOptions';
declare const DM: any;

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.scss']
})
export class RelatoriosComponent implements OnInit {

  controllerId = JSON.parse(localStorage.getItem('currentClient')).controller_id;
  clientName = localStorage.getItem('clientName');
  

  chartOptions: Partial<ChartOptions>;

  authenticationsData: ChartDataSets[] = [];
  authenticationsLabels: any[] = [];
  authenticatedTotal: number;
  loadingAuthenticatedTotal: boolean = false;
  newTotal: number;
  loadingNewTotal: boolean = false;
  loadingAuthentications: boolean = false;
  validateAuthenticationsData: boolean = false;
  authenticationByMonth: any[] = [];
  daysDiff: number = 0;
  validateData: boolean = false;
  authenticationsDataBox: any;
  maxAuthenticationsDay: any;
  maxAuthenticationsTotal: any;
  validateDataByDay: boolean = false;
  newsDataBox: any;
  maxNewsDay: any;
  maxNewsTotal: any;
  authenticationsOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            suggestedMin: 0
          }
        }
      ]
    }
  };

  form: FormGroup;
  initialDate: string = moment().subtract(7,"day").format().split('T')[0];
  endDate: string = moment().format().split('T')[0];
  initialDateFormated: string = moment().subtract(7, "day").format('DD-MM-YYYY');
  endDateFormated: string = moment().format('DD-MM-YYYY');

  validatePeriod: boolean = false;

  chartGenderData: Partial<ChartOptions>[] = [];

  // Faixa Etária
  ageGroupLabels: any[] = [];
  index: number = 0;
  graphObject: any = {
    data: [],
    name: ''
  }
  loading: boolean = false;
  validateAgeGroupData: boolean = false;
  ageGroupOptions = {
    scaleShowVerticalLines: false,
    legend: {
      display: false,
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            suggestedMin: 0
          }
        }
      ]
    }
  };

  // Gênero
  // genderData: SingleDataSet = [];
  genderLabels = [];
  genderOptions = {
    responsive: false,
    legend: { 
      position: 'right'
      }
  };
  validateGenderPieChart: boolean = false;

  // Mídias

  loadingDailyMedias: boolean = false;
  
  @ViewChild("chart") chart: ChartComponent;
  chartOptionsMedia: Partial<ChartOptions>;
  chartBarOptions: Partial<ChartOptions>;
  genderData: Partial<ChartNonAxisOptions>;
  ageGroupData: Partial<ChartOptions>;

  mediasTotalCount: any[] = [];
  mediasPeak: any[] = [];
  datesMediasPeak: any[] = [];
  loadingDailyMediaData: boolean = false;

  maleTotal: any[] = [];
  femaleTotal: any[] = [];
  otherTotal: any[] = [];

  dailyMedias: any;
  dailyMediasGenderAge: any;
  medias: any[] = [];
  sumAgeGroup = 0;
  sumAgeGroupGender = 0;

  genderGraphValid: boolean = false;

  chartImg: string = "";
  chartGenderImg: string = "";
  validateChart: boolean = false;
  validateGenderChart: boolean = false;
  
  // Faixa Etária e Gênero
  ageGroupGender: Partial<ChartOptions>;
  indexAgeGroupGender: number = 0;
  validateAgeGroupGenderData: boolean = false;
  ageGroupGenderLabels = [];
  
  arrFem = [];

  arrMasc = [];

  arrNotInf = [];

  @ViewChild('chartContainer') chartContainer: ElementRef;
  @ViewChild('chartGenderContainer') chartGenderContainer: ElementRef;
  
  constructor(private dashboardService: DashboardService, private formBuilder: FormBuilder, private relatoriosService: RelatoriosService) {
    this.chartOptions = {
      series: [],
      chart: {
        height: 300,
        type: "line",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          export: {
            csv: {
              filename: 'authenticatednew',
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            },
            svg: {
              filename: 'authenticatednew',
            },
            png: {
              filename: 'authenticatednew',
            }
          },
        },
        animations: {
          enabled: false,
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: [],
        
      },
      tooltip: {
        enabled: true
      }
      
    },
    this.chartOptionsMedia = {
      series: [],
      chart: {
        height: 200,
        type: "line",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          export: {
            csv: {
              filename: 'authenticatednew',
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            },
            svg: {
              filename: 'authenticatednew',
            },
            png: {
              filename: 'authenticatednew',
            }
          },
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: []
      },
      tooltip: {
        enabled: true
      }
    };

    this.ageGroupGender = {
      series: [],
      chart: {
        height: 200,
        width: 1000,
        type: "bar",
        events: {
          click: function(chart, w, e) {
            // console.log(chart, w, e)
          }
        },
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
        },
        animations: {
          enabled: false,
        }
      },
      colors: [
        "#008FFB",
        "#00E396",
        "#FEB019"
      ],
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: false
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      grid: {
        show: false
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: "12px"
          }
        }
      },
      tooltip: {
        enabled: true
      }
    };
    this.genderData = {
      series: [],
      chart: {
        width: 380,
        type: 'pie',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        }
      },
      labels: ['Feminino', 'Masculino', 'Não informado/outros'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          },
        }
      }],
      tooltip: {
        enabled: true
      }
    },
    this.ageGroupData = {
      series: [],
      chart: {
        type: "bar",
        height: 200,
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {

      },
      xaxis: {
        categories: []
      },
      yaxis: {
        
      },
      tooltip: {
        enabled: true
      }
    }
   }

  ngOnInit(): void {
    this.getAuthentications();
    this.initializeForm();
    this.getSummaries();
    this.getAuthenticatedTotal();
    this.getNewTotal();
    this.getMediasByPeriod();

    this.getReportByDayTotal();
  }

  initializeForm(){
    this.form = this.formBuilder.group({
      initialDate: [moment().subtract(7,"day").format().split('T')[0]],
      endDate: [moment().format().split('T')[0]],
      dadosDetalhados: [false]
    })
  }

  changeDate(){
    let initialDate = moment(this.form.controls['initialDate'].value);
    let endDate = moment(this.form.controls['endDate'].value);
    
    if (endDate.isSameOrAfter(initialDate)){
      this.initialDate = this.form.controls['initialDate'].value;
      this.endDate = this.form.controls['endDate'].value;

      this.initialDateFormated = moment(this.initialDate).format('DD-MM-YYYY');
      this.endDateFormated = moment(this.endDate).format('DD-MM-YYYY');

      this.getAuthentications();

      this.index = 0;
      this.indexAgeGroupGender = 0;
      this.graphObject.data = [];
      this.arrFem = [];
      this.arrMasc = [];
      this.arrNotInf = [];
      this.ageGroupData.series = [];
      this.ageGroupGender.series = [];
      this.genderData.series = [];
      this.chartOptions.series = [];
      this.chartOptions.xaxis.categories = [];
      this.mediasTotalCount = [];
      this.mediasPeak = [];
      this.datesMediasPeak = [];
      this.genderGraphValid = false;
      
      this.getSummaries();
      this.getAuthenticatedTotal();
      this.getNewTotal();
      this.getAuthenticationsPeak();
      this.getMediasByPeriod();
    }
  }

  printArea(divName) {
    this.chartOptions.tooltip.enabled = false;
    
    this.chartGenderData.forEach(element => {
      element.tooltip.enabled = false;
    });

    this.genderData.tooltip.enabled = false;
    this.ageGroupData.tooltip.enabled = false;
    
    this.validateAuthenticationsData = false;
    this.genderGraphValid = false;
    this.validateAgeGroupGenderData = false;
    this.validateGenderPieChart = false;
    this.validateAgeGroupData = false;

    setTimeout(() => {
      this.validateAuthenticationsData = true;
      this.genderGraphValid = true;
      this.validateAgeGroupGenderData = true;
      this.validateGenderPieChart = true;
      this.validateAgeGroupData = true;
    }, 500);

    setTimeout(() => {
      const css = `<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css"
      integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">`;

      const printContents = document.getElementById(divName).innerHTML;
      const pageContent = `<!DOCTYPE html><html><head>${css}</head><body onload="window.print()">${printContents}</html>`;

      let popupWindow: Window;

      if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
        popupWindow = window.open(
          '',
          '_blank',
          'width=600,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no'
        );
        popupWindow.window.focus();
        popupWindow.document.write(pageContent);
        popupWindow.document.close();
        popupWindow.onbeforeunload = event => {
          popupWindow.close();
        };
        popupWindow.onabort = event => {
          popupWindow.document.close();
          popupWindow.close();
        };
      } else {
        popupWindow = window.open('', '_blank', 'width=600,height=600');
        popupWindow.document.open();
        popupWindow.document.write(pageContent);
        popupWindow.document.close();
      }

      this.chartOptions.tooltip.enabled = true;
      this.chartGenderData.forEach(element => {
        element.tooltip.enabled = true;
      });
      this.ageGroupGender.tooltip.enabled = true;
      this.genderData.tooltip.enabled = true;
      this.ageGroupData.tooltip.enabled = true;

      this.validateAuthenticationsData = false;
      this.validateAgeGroupGenderData = false;
      this.genderGraphValid = false;
      this.validateAgeGroupData = false;
    }, 1000);

    setTimeout(() => {
      this.validateAuthenticationsData = true;
      this.genderGraphValid = true;
      this.validateAgeGroupGenderData = true;
      this.validateGenderPieChart = true;
      this.validateAgeGroupData = true;
    }, 1500);

  }

  removerTooltip(){
    

    
    
  }

  updateReport(){
    this.validateDataByDay = this.form.controls['dadosDetalhados'].value;
  }

  getAuthentications(){
    this.authenticationsData = [];
    this.authenticationsLabels = [];
    this.loadingAuthentications = true;
    this.validateAuthenticationsData = false;
    this.validateChart = false;
    this.validateGenderChart = false;

    forkJoin([
      this.dashboardService.getSummary(this.controllerId, this.initialDate, this.endDate), 
      this.dashboardService.getNews(this.controllerId, this.initialDate, this.endDate)])
      .subscribe(
        (data) => {
          if (data){
            this.getAuthenticationsData(data[0]);
            this.getNewsData(data[1]);

            if (this.chartOptions.series[0].data.length > 0 && this.chartOptions.series[0].data.length > 0){
              this.getDates();
            
              this.validateAuthenticationsData = true;
              this.validateData = true;
            } else {
              this.validateAuthenticationsData = false;
              this.validateData = false;
            }
            
            
          }
    }).add(() => {
      this.loadingAuthentications = false;
    })
  }

  async getImgFromChart(){
    const chartElement = this.chartContainer.nativeElement;

    try {
      const canvas = await html2canvas(chartElement);

      this.chartImg = canvas.toDataURL('image/png');

      this.validateChart = true;
    } catch {
    }
  }

  getAuthenticationsData(data){
    let object = {
      name: '',
      data: []
    }

    let authentications: any = [];

    data.forEach(dados => {
      authentications.push(dados.total);
    });

    object.name = "Autenticados";
    object.data = authentications;

    this.authenticationsDataBox = authentications;

    this.chartOptions.series.push(object);
    this.authenticationsData.push(object);
  }

  getNewsData(data){
    let object = {
      name: '',
      data: []
    }

    let novos: any[] = [];

    data.forEach(dados => {
      novos.push(dados.total);
    });

    object.name = "Novos";
    object.data = novos;

    this.newsDataBox = novos;
    
    this.chartOptions.series.push(object);
    this.authenticationsData.push(object);
  }

  getDates(){
    const startDate = moment(this.initialDate);
    const endDate = moment(this.endDate);

    this.daysDiff = endDate.diff(startDate, 'days');

    let currentDate = startDate;
    while (currentDate <= endDate) {
      this.authenticationsLabels.push(moment(currentDate).format('DD/MM/YYYY'));
      this.chartOptions.xaxis.categories.push(moment(currentDate).format('DD/MM/YYYY'));
      currentDate = moment(currentDate).add(1, 'days');
    }

    this.getAuthenticationsPeak();
  }

  getAuthenticationsPeak(){
    let maxAuthentications = Math.max(...this.authenticationsDataBox);
    let indexAuthentications = this.authenticationsDataBox.indexOf(maxAuthentications);

    this.maxAuthenticationsDay = this.chartOptions.xaxis.categories[indexAuthentications];
    this.maxAuthenticationsTotal = maxAuthentications;

    let maxNewsDay = Math.max(...this.newsDataBox);
    let indexNews = this.newsDataBox.indexOf(maxNewsDay);

    this.maxNewsDay = this.chartOptions.xaxis.categories[indexNews];
    this.maxNewsTotal = maxNewsDay;
  }
  
  getMediasByPeriod(){
    
    
    this.relatoriosService.getVideos(this.controllerId, this.initialDate, this.endDate).subscribe(
      (data) => {
        this.medias = data.filter((obj) => {
          let date = moment(obj.start_date);
          if (date.isBetween(this.initialDate, this.endDate)){
            return obj;
          }
        });
        
        this.medias.forEach(element => {
          this.getTotalMediaCount(element.id);
          this.getDailyMediaReport(element.id, element.start_date, element.end_date);
          

        });
    })
  }

  getTotalMediaCount(videoId){
    this.relatoriosService.getTotalMediaCount(videoId).subscribe(
      (data) => {
      this.mediasTotalCount.push(data.total);
    })
  }

  getDailyMediaReport(mediaId, mediaStartDate, mediaEndDate){
    this.loadingDailyMediaData = true;
    this.chartGenderData = [];
    this.relatoriosService.getDailyMediaReport(mediaId, mediaStartDate, mediaEndDate)
    .subscribe(
      (data) => {
        this.getDailyMediaData(data);
        this.getGenderGraphObject(data);
    }).add(() => {
      
    })
  }

  
  getDailyMediaData(data){
    
    let totals = [];
    let dates = [];

    data.forEach((item) => {
      const viewData = item.viewData;
      const totalData = viewData.reduce((total, view) => {
        const sumData = view.data.reduce((sum, value) => sum + value, 0);
        return total + sumData;
      }, 0);

      totals.push(totalData);
      dates.push(moment(item.datetime.split("T")[0]).format("DD/MM/YYYY"));
    });
    
    let totalMediasPeak = Math.max(...totals);
    this.mediasPeak.push(Math.max(...totals));
    
    let indexMediasPeak = totals.indexOf(totalMediasPeak);
    this.datesMediasPeak.push(dates[indexMediasPeak]);

    this.loadingDailyMediaData = false;
  }

  formatDate(date){
    let dateFormated = date.slice(0,2) + "/" + date.slice(3,5) + "/" + date.slice(6,10);
    return dateFormated;
  }
  
  getGenderGraphObject(data){
    const labels = ["0 a 14", "15 a 20", "21 a 30", "31 a 40", "41 a 50", "51 a 60", "61 ou mais", "Não informado"];
    const datasets = [
      { name: "Masculino", data: Array(labels.length).fill(0) },
      { name: "Feminino", data: Array(labels.length).fill(0) },
      { name: "Não Informado", data: Array(labels.length).fill(0) },
    ];

    data.forEach((item) => {
      const viewData = item.viewData;

      this.chartBarOptions = {
        series: [],
        chart: {
          height: 200,
          type: "bar",
          events: {
            click: function(chart, w, e) {
              // console.log(chart, w, e)
            }
          },
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            export: {
              csv: {
                filename: 'authenticatednew',
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: 'authenticatednew',
              },
              png: {
                filename: 'authenticatednew',
              }
            },
          },
          animations: {
            enabled: false,
          }
        },
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019"
        ],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: false
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        grid: {
          show: false
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: "12px"
            }
          }
        },
        tooltip: {
          enabled: true
        }
      };

      viewData.forEach((view) => {
        const ageGroup = view.ageGroup;
        const data = view.data;

        const maleTotal = data[0];
        const femaleTotal = data[1];
        const otherTotal = data[2];

        const ageGroupIndex = labels.indexOf(ageGroup);

        if (ageGroupIndex !== -1) {
          datasets[0].data[ageGroupIndex] += maleTotal;
          datasets[1].data[ageGroupIndex] += femaleTotal;
          datasets[2].data[ageGroupIndex] += otherTotal;
        }
      });
    });

    let obj = {
      labels: labels,
      data: datasets
    }

    this.dailyMediasGenderAge = obj;
    

    this.chartBarOptions.series.push(...obj.data);
    this.chartBarOptions.xaxis.categories = obj.labels;
    
    this.chartGenderData.push(this.chartBarOptions);

    if (this.chartGenderData.length == this.medias.length) {
      this.genderGraphValid = true;
    }
  }

  getSummaries(){
    this.validateAgeGroupData = false;
    this.loading = true;

    this.ageGroupLabels = ['0 a 14', '15 a 20', '21 a 30', '31 a 40', '41 a 50', '51 a 60', '61 ou mais', 'Não informado'];
    this.ageGroupData.xaxis.categories = this.ageGroupLabels;
    this.genderLabels = ['Feminino', 'Masculino', 'Não informado'];
    this.ageGroupGenderLabels = ['0 a 14', '15 a 20', '21 a 30', '31 a 40', '41 a 50', '51 a 60', '61 ou mais', 'Não informado']

    this.dashboardService.getSummaries(this.controllerId, this.initialDate, this.endDate).subscribe((data) => {
      if (data){
        this.getAgeGroup(data);
        this.getGenderData(data);
        this.getAgeGroupGender(data);
        this.validateAgeGroupData = true;
      }
    }).add(() => {
      this.loading = false;
    })
  }

  getAgeGroup(data){
    let sum = 0;

    if (this.index < 8){
      data.forEach(element => {
        element.age_ranges[this.index].forEach(element => {
          
          sum += element;
        });
      });

      this.graphObject.name = this.ageGroupLabels[this.index];
      this.graphObject.data.push(sum);
    }

    this.sumAgeGroup = this.sumAgeGroup + sum;
    
    this.index++;

    if (this.index == 8){
      if (this.sumAgeGroup > 0){
        this.ageGroupData.series.push(this.graphObject);
      } else {
        this.ageGroupData.series = [];
      }
    }

    if (this.index < 8){
      this.getAgeGroup(data);
    }
  }

  getGenderData(data){
    let sumMasc = 0;
    let sumFem = 0;
    let sumNotInf = 0;

    let total = 0;

    this.validateGenderPieChart = false;

    data.forEach(element => {
      element.age_ranges.forEach(element => {
        sumMasc += element[0];
        sumFem += element[1];
        sumNotInf += element[2];
      });
    });

    total = sumMasc + sumFem + sumNotInf;

    if (total > 0){
      this.genderData.series.push(Number((sumFem / total * 100).toFixed(1)));
      this.genderData.series.push(Number((sumMasc / total * 100).toFixed(1)));
      this.genderData.series.push(Number((sumNotInf / total * 100).toFixed(1)));

      this.validateGenderPieChart = true;
    } else {
      this.genderData.series = [];
      this.validateGenderPieChart = false;
    }
    
    // this.genderData.series.push(obj);
  }

  getAgeGroupGender(data){
    let sumMasc = 0;
    let sumFem = 0;
    let sumNotInf = 0;

    if (this.indexAgeGroupGender < 8){
      data.forEach(element => {
        sumMasc += element.age_ranges[this.indexAgeGroupGender][0];
        sumFem += element.age_ranges[this.indexAgeGroupGender][1];
        sumNotInf += element.age_ranges[this.indexAgeGroupGender][2];
      })

      this.sumAgeGroupGender = this.sumAgeGroupGender + sumMasc + sumFem + sumNotInf;
      
      this.arrFem.push(sumFem);
      this.arrMasc.push(sumMasc);
      this.arrNotInf.push(sumNotInf);
    }
    
    this.indexAgeGroupGender++;

    if (this.indexAgeGroupGender == 8){
      if (this.sumAgeGroupGender > 0){
        this.ageGroupGender.series.push({name: 'Feminino', data: this.arrFem});
        this.ageGroupGender.series.push({name: 'Masculino', data: this.arrMasc});
        this.ageGroupGender.series.push({name: 'Não Informado/Outros', data: this.arrNotInf});

        this.ageGroupGender.xaxis.categories = ["0 a 14", "15 a 20", "21 a 30", "31 a 40", "41 a 50", "51 a 60", "61 ou mais", "Não informado"];

        this.validateAgeGroupGenderData = true;
      } else {
        this.ageGroupGender.series = [];
        this.validateAgeGroupGenderData = false;
      }
      
    }

    if (this.indexAgeGroupGender < 8){
      this.getAgeGroupGender(data);
    }
  }

  getAuthenticatedTotal(){
    this.loadingAuthenticatedTotal = true;

    this.relatoriosService.getReportByPeriodAuthenticated(this.controllerId, this.initialDate, this.endDate)
    .subscribe(
      (data) => {
        if (data){
          this.authenticatedTotal = data.total;
        }
    }).add(() => {
      this.loadingAuthenticatedTotal = false;
    })
  }

  getNewTotal(){
    this.loadingNewTotal = true;

    this.relatoriosService.getReportByPeriodNew(this.controllerId, this.initialDate, this.endDate)
    .subscribe(
      (data) => {
        if (data){
          this.newTotal = data.total;
        }
        
    }).add(() => {
      this.loadingNewTotal = false;
    })
  }

  getReportByDayTotal(){
    this.relatoriosService.getReportByDayTotal(this.controllerId, this.initialDate, this.endDate)
    .subscribe(
      (data) => {
        // console.log(data);
    })
  }

}


