import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'encryptionPipe' })
export class EncryptionPipe implements PipeTransform {
  transform(value: string): string {
    if (value === undefined) {
      return null;
    } else {
      switch (value) {
        case 'wpa2_personal':
          return 'WPA2-PSK';
        case 'none':
          // return 'No Encryption';
          return 'Sem encriptação';
        default:
          break;
      }
    }
  }
}
