<form [formGroup]="radioGroupForm">
  <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" formControlName="model">
    <label ngbButtonLabel class="btn-light">
      <ng-container
        *ngIf="radioGroupForm.get('model').value === 'left'; then selected; else notSelected"
      ></ng-container>
      <ng-template #notSelected>
        <img class="pl-1 pr-1" *ngIf="togglerOpts.icons" [src]="togglerOpts.icons.left.notSelected" />
      </ng-template>
      <ng-template #selected>
        <img class="pl-1 pr-1" *ngIf="togglerOpts.icons" [src]="togglerOpts.icons.left.selected" />
      </ng-template>

      <input ngbButton type="radio" value="left" /> {{ togglerOpts.titles.left }}
    </label>

    <label ngbButtonLabel class="btn-light">
      <ng-container
        *ngIf="radioGroupForm.get('model').value === 'left'; then notSelected; else selected"
      ></ng-container>
      <ng-template #notSelected>
        <img class="pl-1 pr-1" *ngIf="togglerOpts.icons" [src]="togglerOpts.icons.right.notSelected" />
      </ng-template>
      <ng-template #selected>
        <img class="pl-1 pr-1" *ngIf="togglerOpts.icons" [src]="togglerOpts.icons.right.selected" />
      </ng-template>

      <input ngbButton type="radio" value="middle" /> {{ togglerOpts.titles.right }}
    </label>
  </div>
</form>
