import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicoService {

  constructor(private http: HttpClient) { }

  getClientFields(client_id): Observable<any> {
    return this.http.get<any>(`${environment.configApiUrlCaptiveStaging}/clients/${client_id}?filter={"fields":["fields"]}`);
  }
}
