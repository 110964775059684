<div class="container-fluid">
    <div class="dateInputs">
        <form [formGroup]="formulario">
            <div class="d-flex">
                <div>
                    <label style="font-size: 12px;" class=" mb-0">Selecione o ano</label>
                    <select formControlName="ano" class="form-control" (change)="updateWeek()">
                        <option *ngFor="let col of yearList">{{col}}</option>
                    </select>
                </div>
                <div class="ml-4">
                    <label style="font-size: 12px;" class="mb-0">Selecione a semana</label>
                    <select formControlName="semana" class="form-control" (change)="selectWeek()">
                        <option *ngFor="let col of weekList" value="{{col}}">{{col}}</option>
                    </select>
                </div>
            </div>
        </form>
    </div>

    <div class="card" style="margin-top: 70px">
        <div class="card-header">
            Recorrência <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="bottom" title="Quantidade de usuários conectados de forma recorrente no período de uma semana."></i> 
        </div>
        <div class="card-body">
            <ng-container *ngIf="!loading else carregando">
                <canvas *ngIf="recurrenceData[0].data.length > 0 else noData" baseChart [datasets]="recurrenceData" [labels]="recurrenceLabels"
                    [options]="barChartOptions" legend="true" chartType="bar" width="100%" height="40">
                </canvas>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #carregando>
    <div class="text-center">
        <i class="fas fa-spinner fa-spin text-primary" style="font-size: 20px;"></i>
    </div>
    
</ng-template>

<ng-template #noData>
    <div class="text-center">
        Sem dados para o período escolhido.
    </div>
    
</ng-template>