import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/data/models/user.model';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-user-badge',
  templateUrl: './user-badge.component.html',
  styleUrls: ['./user-badge.component.scss']
})
export class UserBadgeComponent implements OnInit {
  userNameInitial: string;
  loading = false;
  user: User;
  root:boolean = this.authenticationService.isRoot;
  show: boolean = true;
  title: string = (this.configService.getMode_url() == 'sedu' ? 'Escolas' : this.configService.getMode_url() == 'elcoma' ? "Organizações": 'Terminais' );
  

  constructor(
    private userService: UserService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private configService: ConfigurationService
  ) {}

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.user = this.authenticationService.currentUserValue;
    this.userNameInitial = this.getFirstLetterUserName(this.user);
  }

  getFirstLetterUserName(user: User) {
    return user.username.charAt(0);
  }
  openDrop(){
    this.show = true;
  }
  goToUsers(){
    this.router.navigate(['/user']);
    this.show = false;
  }
  goToOrganizations(){
    this.router.navigate(['/organization']);
    this.show = false;
  }
  goToBulkConfigs(){
    this.router.navigate(['/bulkconfig']);
    this.show = false;
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
