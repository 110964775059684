<ng-container *ngIf="validatePortal">
    <div class="container-fluid pt-2 pb-5" [ngClass]="sharedService.desktop ? 'dashboard-background' : 'dashboard-overflow'">
        <div class="justify-content-center align-items-center mb-2" [ngClass]="sharedService.desktop ? 'd-flex' : 'row'">
            <nav style="width: 255px;" [ngClass]="sharedService.desktop ? 'tabs-desktop' : 'mb-2'">
                <div class="nav nav-tabs text-center" id="nav-tab" role="tablist">
                    <button class="nav-link active" id="nav-home-tab" data-toggle="tab" data-target="#nav-home" type="button"
                        role="tab" aria-controls="nav-home" aria-selected="true" (click)="switchTabs('Tempo Real')">Tempo Real</button>
                    <button class="nav-link" id="nav-profile-tab" data-toggle="tab" data-target="#nav-profile" type="button"
                        role="tab" aria-controls="nav-profile" aria-selected="false" (click)="switchTabs('Dashboard')">Dashboard</button>
                </div>
            </nav>
        
            <ng-container *ngIf="validatePeriod">
                <div [ngClass]="sharedService.desktop ? 'dates-desktop' : 'ml-4'">
                    <form [formGroup]="form">
                        <div class="d-flex">
                            <div style="font-size: 12px;">
                                <label class=" mb-0">Data Inicial</label>
                                <input style="font-size: 12px;" formControlName="initialDate" type="date" class="form-control" (change)="changeDate(1)">
                            </div>
                            <div class="ml-2" style="font-size: 12px;">
                                <label class=" mb-0">Data Final</label>
                                <input style="font-size: 12px;" formControlName="endDate" type="date" class="form-control" (change)="changeDate(2)">
                            </div>
                        </div>
                    </form>
                </div>
            </ng-container>
            
        </div>
        
        <div class="tab-content mt-4" [ngClass]="sharedService.desktop ? 'mt-4' : ''" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                <div class="row mr-2 ml-2">
                    <div class="col-md-3">
                        <div class="card p-3 mb-2">
                            <div>
                                <p style="color: #9F9F9F; font-weight: 500; font-size: 14px">Detectados</p>
                                <i class="fas fa-spinner fa-spin text-primary" [hidden]="!loadingDetecteds"></i>
                                <h4 class="text-primary font-weight-bold">
                                    <ng-container *ngIf="detecteds else noData">
                                        {{detecteds}}
                                    </ng-container>
                                </h4>
                            </div>
        
                            <div style="position: absolute; right: 20px">
                                <i class="fas fa-users text-primary icons mt-4" style="font-size: 30px;"></i>
                            </div>
                        </div>
        
                        <div class="card p-3 mb-2">
                            <div>
                                <p style="color: #9F9F9F; font-weight: 500; font-size: 14px">Conectados</p>
                                <i class="fas fa-spinner fa-spin text-primary" [hidden]="!loadingDeviceInfo"></i>
                                <h4 class="text-primary font-weight-bold">
                                    <ng-container *ngIf="connected else noData">
                                        {{connected}}
                                    </ng-container>
                                </h4>
                            </div>
        
                            <div style="position: absolute; right: 20px">
                                <i class="fas fa-wifi text-primary icons mt-4" style="font-size: 30px;"></i>
                            </div>
                        </div>
        
                        <!-- <div class="card p-3">
                            <div>
                                <p style="color: #9F9F9F; font-weight: 500; font-size: 14px">Online</p>
                                <i class="fas fa-spinner fa-spin text-primary" [hidden]="!loadingDeviceInfo"></i>
                                <h4 class="text-primary font-weight-bold">
                                    <ng-container *ngIf="connected else noData">
                                        {{online}}
                                    </ng-container>
                                </h4>
                            </div>
        
                            <div style="position: absolute; right: 20px">
                                <i class="fas fa-network-wired text-primary icons mt-4" style="font-size: 30px;"></i>
                            </div>
                        </div> -->
                    </div>
        
                    <div class="col-md-9">
                        <div class="card mb-0" [ngClass]="sharedService.desktop ? '' : 'mt-2'" style="height: 100%;">
                            <div class="card-header" style="background-color: white; font-weight: 500;">
                                Dispositivos <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="bottom" title="Quantidade de conexões de usuários por AP"></i>
                            </div>
                            <div class="card-body" >
                                <ng-container *ngIf="!loadingConnections else loading">
                                    <ng-container *ngIf="validateConnections else noRealtimeData">
                                        <apx-chart
                                        [series]="chartOptionsConnections.series"
                                        [chart]="chartOptionsConnections.chart"
                                        [xaxis]="chartOptionsConnections.xaxis"
                                        [colors]="chartOptionsConnections.colors"
                                        [dataLabels]="chartOptionsConnections.dataLabels"
                                        [grid]="chartOptionsConnections.grid"
                                        [stroke]="chartOptionsConnections.stroke"
                                        [title]="chartOptionsConnections.title"
                                        [tooltip]="chartOptionsLine.tooltip"
                                        ></apx-chart>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="row mr-2 ml-2 mb-5 pb-5" [ngClass]="sharedService.desktop ? 'mt-4' : 'mt-3'">
                    <div class="col-md-12">
                        <div class="card" style="height: 400px;">
                            <div class="card-header" style="background-color: white; font-weight: 500;">
                                Experiência de Conexão <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="bottom" title="Qualidade de conexão dos usuários em tempo real"></i>
                            </div>
                            <div class="card-body" style="min-height: 300px; max-height: 300px;">
                                <ng-container *ngIf="!loadingUserExperience else loading">
                                    <ng-container *ngIf="validateExperienceData else noRealtimeData">
                                        <!-- <canvas baseChart [datasets]="userExperienceData" [labels]="userExperienceLabels" [options]="userExperienceOptions"
                                        legend="false" chartType="line" width="100" height="20">
                                        </canvas> -->
                                        <apx-chart
                                        [series]="chartOptionsLine.series"
                                        [chart]="chartOptionsLine.chart"
                                        [xaxis]="chartOptionsLine.xaxis"
                                        [yaxis]="chartOptionsLine.yaxis"
                                        [colors]="chartOptionsLine.colors"
                                        [dataLabels]="chartOptionsLine.dataLabels"
                                        [grid]="chartOptionsLine.grid"
                                        [stroke]="chartOptionsLine.stroke"
                                        [title]="chartOptionsLine.title"
                                        [tooltip]="chartOptionsLine.tooltip"
                                        ></apx-chart>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div *ngIf="validatePeriod" class=" fade mt-4" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card" style="height: 82vh; overflow-y: auto;">
                            <div class="card-header" style="background-color: white; font-weight: 500;">
                                Histórico de APs <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="bottom" title="Registra tempos de inatividade e atividade de cada AP"></i>
                            </div>
                            <div class="card-body" style=" overflow-y: auto;">
                                <ng-container>
                                    <ng-container *ngIf="!loadingApHistory else loading">
                                        <ng-container *ngIf="apStatusHistory.length > 0 else noGraphData">
                                            <div *ngFor="let col of apStatusHistory; let i = index" class="d-flex" style="font-size: 12px;;">
                                                <ng-container *ngIf="!col.hostname.includes('Ninguem')">
                                                    <div class="pr-2">
                                                        <ng-container *ngIf="col.status === 'online' && !col.hostname.includes('Swarm')">
                                                           <i style="color: #88C06E;" class="fas fa-circle" data-toggle="tooltip" data-placement="top" title="Online"></i>
                                                       </ng-container>
                                                       
                                                       <ng-container *ngIf="col.status === 'offline' && !col.hostname.includes('Swarm')">
                                                           <i style="color: #E25B5B;" class="fas fa-circle" data-toggle="tooltip" data-placement="top" title="Offline"></i>
                                                       </ng-container>
                   
                                                       <ng-container *ngIf="col.hostname.includes('Swarm')">
                                                           <i style="color: #EABE4F;" class="fas fa-circle"></i>
                                                       </ng-container>
           
                                                       <!-- <div style="width: 3px; height: 22px; background-color: rgb(233, 233, 233); margin-left: 4px;"></div> -->
                   
                                                   </div>
                                                   <div class="pr-2 mb-4 text-left">
                                                       <span [ngStyle]="(col.status === 'online' && !col.hostname.includes('Swarm')) && {'backgroundColor': '#88C06E', 'color': 'white', 'borderRadius':'2px', 'padding':'5px'} || (col.status === 'offline' && !col.hostname.includes('Swarm')) && {'backgroundColor': '#E25B5B', 'color': 'white', 'borderRadius':'2px', 'padding':'5px'}  || col.hostname.includes('Swarm') && {'backgroundColor': '#EABE4F', 'color': 'white', 'borderRadius':'2px', 'padding':'5px'}">
                                                           {{col.hostname}}
                                                       </span>
                                                       
                                                   </div>
                                                   <div class="pl-0 text-right">
                                                       {{formatDate(col.datetime.split('T')[0]) }} às {{col.datetime.split('T')[1].split('+')[0].split('.000')[0]}}
                                                   </div>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8 pl-0">
                        <div class="row ml-2" style="min-height: 30vh;">
                            <div class="col-md-6 pl-0">
                                <div class="card" style="height: 100%;">
                                    <div class="card-header" style="background-color: white; font-weight: 500;" >
                                        Usuários por dia <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="bottom" title="Exibe um mapa de calor com informações de conexões por dia e hora"></i>
                                    </div>
                                    <div class="card-body" style="overflow: auto;">
                                        <div id="chart" *ngIf="usersCount > 0 else noGraphData">
                                            <apx-chart
                                              [series]="chartOptions.series"
                                              [chart]="chartOptions.chart"
                                              [dataLabels]="chartOptions.dataLabels"
                                              [title]="chartOptions.title"
                                              [colors]="chartOptions.colors"
                                            ></apx-chart>
                                          </div>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- <div class="col-md-4">
                                <div class="card" style="height: 100%; width: 100%;">
                                    <div class="card-header" style="background-color: white; font-weight: 500;">
                                        Status Autorização <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="bottom" title="Apresenta informações sobre sucesso e falha de login de usuários"></i>
                                    </div>
                                    <div class="card-body" style="font-size: 12px; overflow: auto;">
                                        <ng-container *ngIf="!loadingStatusAuth else loading">
                                            <div class="d-flex justify-content-center">
                                                <canvas baseChart *ngIf="statusAuthData.length > 0 else noGraphData" [data]="statusAuthData" [labels]="statusAuthLabels"
                                                    [options]="statusAuthOptions" legend="true" chartType="pie" width="400">
                                                </canvas>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div> -->
                
                            <div class="col-md-6">
                                <div class="card">
                                    <div class="card-header" style="background-color: white; font-weight: 500;">
                                        Top Vagalumes Acessados <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="bottom" title="Lista em ordem crescente os APs mais frequentemente acessados pelos usuários"></i>
                                    </div>
                                    <div class="card-body pt-0" style="height: 40vh; overflow-y: auto">
                                        <ng-container *ngIf="!loadingTopVagalumes else loading">
                                            <table *ngIf="topVagalumesData.length > 0 else noGraphData" class="table table-sm" style="font-size: 12px;">
                                                <thead>
                                                    <tr>
                                                        <th style="border-top: none;">Nome do AP</th>
                                                        <th style="border-top: none;">Contribuição</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let col of topVagalumesData">
                                                        <td>{{col.name.split('--')[1]}}</td>
                                                        <td>{{col.periodAverage | number: '1.0-2'}}%</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ml-2 mt-4" style="min-height: 40vh;">
                                <div class="col-md-12 pl-0">
                                    <div class="card" style="height: 85%;">
                                        <div class="card-header" style="background-color: white; font-weight: 500;">
                                            Autenticações <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="bottom" title="Apresenta o número de novos usuários e usuários autenticados por dia"></i>
                                        </div>
                                        <div class="card-body">
                                            <div class="text-center">
                                                <i class="fas fa-spinner fa-spin text-primary mt-5" [hidden]="!loadingAuthentications" style="font-size: 30px;"></i>
                                            </div>
                    
                                            <canvas baseChart *ngIf="validateAuthenticationsData" [datasets]="authenticationsData" [labels]="authenticationsLabels"
                                                [options]="authenticationsOptions" legend="true" chartType="line" width="100%" height="20">
                                            </canvas>
                                        </div>
                                    </div>
                                </div>
                        </div>
    
                    </div>
                    
    
                </div>
                </div>
    
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!validatePortal">
    <div class="container-fluid pt-2 pb-2" style="max-height: 100vh; overflow-y: auto; width: 100%;">
                <div class="row mr-2 ml-2">
                    <div class="col-md-3">
                        <!-- <div class="card p-3 mb-2">
                            <div>
                                <p style="color: #9F9F9F; font-weight: 500; font-size: 14px">Detectados</p>
                                <i class="fas fa-spinner fa-spin text-primary" [hidden]="!loadingDetecteds"></i>
                                <h4 class="text-primary font-weight-bold">
                                    <ng-container *ngIf="detecteds else noData">
                                        {{detecteds}}
                                    </ng-container>
                                </h4>
                            </div>
        
                            <div style="position: absolute; right: 20px">
                                <i class="fas fa-users text-primary icons mt-4" style="font-size: 30px;"></i>
                            </div>
                        </div> -->
        
                        <div class="card p-3 mb-2">
                            <div>
                                <p style="color: #9F9F9F; font-weight: 500; font-size: 14px">Conectados</p>
                                <i class="fas fa-spinner fa-spin text-primary" [hidden]="!loadingDeviceInfo"></i>
                                <h4 class="text-primary font-weight-bold">
                                    <ng-container *ngIf="connected else noData">
                                        {{connected}}
                                    </ng-container>
                                </h4>
                            </div>
        
                            <div style="position: absolute; right: 20px">
                                <i class="fas fa-wifi text-primary icons mt-4" style="font-size: 30px;"></i>
                            </div>
                        </div>
        
                        <!-- <div class="col-md-4"> -->
                            <div class="card" style="height: 72vh; overflow-y: auto;">
                                <div class="card-header" style="background-color: white; font-weight: 500;">
                                    Histórico de APs <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="bottom" title="Registra tempos de inatividade e atividade de cada AP"></i>
                                </div>
                                <div class="card-body" style=" overflow-y: auto;">
                                    <ng-container>
                                        <ng-container *ngIf="!loadingApHistory else loading">
                                            <ng-container *ngIf="apStatusHistory.length > 0 else noGraphData">
                                                <div *ngFor="let col of apStatusHistory; let i = index" class="d-flex" style="font-size: 12px;;">
                                                    <div class="pr-2">
                                                         <ng-container *ngIf="col.status === 'online' && !col.hostname.includes('Swarm')">
                                                            <i style="color: #88C06E;" class="fas fa-circle" data-toggle="tooltip" data-placement="top" title="Online"></i>
                                                        </ng-container>
                                                        
                                                        <ng-container *ngIf="col.status === 'offline' && !col.hostname.includes('Swarm')">
                                                            <i style="color: #E25B5B;" class="fas fa-circle" data-toggle="tooltip" data-placement="top" title="Offline"></i>
                                                        </ng-container>
                    
                                                        <ng-container *ngIf="col.hostname.includes('Swarm')">
                                                            <i style="color: #EABE4F;" class="fas fa-circle"></i>
                                                        </ng-container>
            
                                                        <!-- <div style="width: 3px; height: 22px; background-color: rgb(233, 233, 233); margin-left: 4px;"></div> -->
                    
                                                    </div>
                                                    <div class="pr-2 mb-4 text-left">
                                                        <span [ngStyle]="(col.status === 'online' && !col.hostname.includes('Swarm')) && {'backgroundColor': '#88C06E', 'color': 'white', 'borderRadius':'2px', 'padding':'5px'} || (col.status === 'offline' && !col.hostname.includes('Swarm')) && {'backgroundColor': '#E25B5B', 'color': 'white', 'borderRadius':'2px', 'padding':'5px'}  || col.hostname.includes('Swarm') && {'backgroundColor': '#EABE4F', 'color': 'white', 'borderRadius':'2px', 'padding':'5px'}">
                                                            {{col.hostname}}
                                                        </span>
                                                        
                                                    </div>
                                                    <div class="pl-0 text-right">
                                                        {{formatDate(col.datetime.split('T')[0]) }} às {{col.datetime.split('T')[1].split('+')[0]}}
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        <!-- </div> -->
                    </div>
        
                    <div class="col-md-9">
                        <div class="row mb-2">
                            <div class="col-md-8">
                                <div class="card mb-0" style="height: 100%;">
                                    <div class="card-header" style="background-color: white; font-weight: 500;">
                                        Dispositivos <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="bottom" title="Quantidade de conexões de usuários por AP"></i>
                                    </div>
                                    <div class="card-body" >
                                        <ng-container *ngIf="!loadingConnections else loading">
                                            <ng-container *ngIf="validateConnections else noRealtimeData">
                                                <apx-chart
                                                [series]="chartOptionsConnections.series"
                                                [chart]="chartOptionsConnections.chart"
                                                [xaxis]="chartOptionsConnections.xaxis"
                                                [colors]="chartOptionsConnections.colors"
                                                [dataLabels]="chartOptionsConnections.dataLabels"
                                                [grid]="chartOptionsConnections.grid"
                                                [stroke]="chartOptionsConnections.stroke"
                                                [title]="chartOptionsConnections.title"
                                                [tooltip]="chartOptionsLine.tooltip"
                                                ></apx-chart>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card">
                                    <div class="card-header" style="background-color: white; font-weight: 500;">
                                        Top Vagalumes Acessados <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="bottom" title="Lista em ordem crescente os APs mais frequentemente acessados pelos usuários"></i>
                                    </div>
                                    <div class="card-body pt-0" style="height: 35vh; overflow-y: auto">
                                        <ng-container *ngIf="!loadingTopVagalumes else loading">
                                            <table *ngIf="topVagalumesData.length > 0 else noGraphData" class="table table-sm" style="font-size: 12px;">
                                                <thead>
                                                    <tr>
                                                        <th style="border-top: none;">IP</th>
                                                        <th style="border-top: none;">Média de acessos</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let col of topVagalumesData">
                                                        <td>{{col.name.split('--')[1]}}</td>
                                                        <td>{{col.periodAverage | number: '1.0-2'}}%</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
    
                        <div class="card" style="height: 400px;">
                            <div class="card-header" style="background-color: white; font-weight: 500;">
                                Experiência de Conexão <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="bottom" title="Qualidade de conexão dos usuários em tempo real"></i>
                            </div>
                            <div class="card-body" style="min-height: 300px; max-height: 300px;">
                                <ng-container *ngIf="!loadingUserExperience else loading">
                                    <ng-container *ngIf="validateExperienceData else noRealtimeData">
                                        <!-- <canvas baseChart [datasets]="userExperienceData" [labels]="userExperienceLabels" [options]="userExperienceOptions"
                                        legend="false" chartType="line" width="100" height="20">
                                        </canvas> -->
                                        <apx-chart
                                        [series]="chartOptionsLine.series"
                                        [chart]="chartOptionsLine.chart"
                                        [xaxis]="chartOptionsLine.xaxis"
                                        [yaxis]="chartOptionsLine.yaxis"
                                        [colors]="chartOptionsLine.colors"
                                        [dataLabels]="chartOptionsLine.dataLabels"
                                        [grid]="chartOptionsLine.grid"
                                        [stroke]="chartOptionsLine.stroke"
                                        [title]="chartOptionsLine.title"
                                        [tooltip]="chartOptionsLine.tooltip"
                                        ></apx-chart>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
</ng-container>

<ng-template #noData>
    <ng-container *ngIf="!loadingDetecteds">
        -
    </ng-container>
</ng-template>

<ng-template #loading>
    <div class="text-center mt-4">
        <i class="fas fa-spinner fa-spin text-primary" style="width: 20px;"></i>
    </div>
</ng-template>

<ng-template #noGraphData>
    <ng-container *ngIf="!loadingAuthentications">
        <div class="text-center mt-4 ml-4 mr-4" style="font-size: 12px;">
            Sem dados disponíveis neste período.
        </div>
    </ng-container>
</ng-template>

<ng-template #noRealtimeData>
    <div class="text-center mt-5">
        Sem dados disponíveis.
    </div>
</ng-template>