<nav class="navbar navbar-expand-md navbar-light bg-light border-right border-bottom sticky-top pb-0 pt-0">
  <div class="logo-wrapper d-flex justify-content-center align-items-center">
    <img
      (click)="goToDashboard()"
      class="logo"
      style="cursor: pointer; width: 50px; margin-top: 5px; margin-bottom: 5px;"
      src="assets/icons/logo.png"
    />
  </div>

  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <ng-container *ngIf="desktop; else mobilemenu">
    <div class="container navbar-collapse">
      <!-- <div class="icon-col"> -->
      <!-- <div class="row justify-content-center">
            </div> -->
      <!-- </div> -->

      <ul class="nav navbar-nav mx-auto">
        <li class="nav-item">
          <div class="container">
            <ng-container
              *ngIf="
                currentRoute == 'videos' ||
                  currentRoute == 'perguntas' ||
                  currentRoute == 'publico' ||
                  currentRoute == 'recurrence' ||
                  currentRoute == 'relatorios' ||
                  userText == 'ROOT';
                else selectOn
              "
            >
              <div style="cursor: pointer" data-toggle="modal" data-target="#exampleModal">
                <i class="fas fa-users"></i> {{ clientName.split('-')[0] }}
                <i style="font-size: 12px;" class="fas fa-chevron-down"></i>
              </div>
            </ng-container>

            <ng-template #selectOn>
              <div><i class="fas fa-users"></i> {{ clientName.split('-')[0] }}</div>
            </ng-template>
          </div>

          <app-search-bar-clients></app-search-bar-clients>
        </li>
      </ul>
    </div>

    <!-- <ng-container
      *ngIf="currentRoute == 'videos' || currentRoute == 'perguntas' || currentRoute == 'publico' || currentRoute == 'recurrence' || currentRoute == 'relatorios'">
      <form [formGroup]="form">
        <select class="form-control" style="width: 300px; position: absolute; right: 20px; top: 9px; font-size: 12px;"
          formControlName="organization" (change)="getClientData()">
          <option value="">Selecione uma opção</option>
          <option value="{{col.id}}" *ngFor="let col of groupsByClient">
            {{col.name}}
          </option>
        </select>
      </form>
    </ng-container> -->
  </ng-container>

  <ng-template #mobilemenu>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" (click)="goToHome()">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="goToVagalumeStatus()">Vagalume Status</a>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Marketing
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="cursor: pointer;">
            <a class="dropdown-item" (click)="redirect(1)">Campanhas</a>
            <a class="dropdown-item" (click)="redirect(2)">Enquetes</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Insight
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" (click)="redirect(7)">Mapas</a>
            <a class="dropdown-item" (click)="redirect(9)">Público</a>
            <a class="dropdown-item" (click)="redirect(11)">Recorrência</a>
            <a class="dropdown-item" (click)="redirect(12)">Relatórios</a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="redirect(13)">Usuários</a>
        </li>
        <li class="nav-item">
          <br />
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="modal" data-target="#exampleModal" style="cursor: pointer;"
            >Trocar Cliente</a
          >

          <app-search-bar-clients></app-search-bar-clients>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="logout()">Sair</a>
        </li>
      </ul>
    </div>
  </ng-template>
</nav>

<app-modal-change-clients (groupsByClient)="getGroupsByClient($event)"></app-modal-change-clients>
