import { Component, OnInit, Input } from '@angular/core';

export interface ModalOptions {
  title: string;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() modal;
  @Input() modalOpts;
  constructor() {}

  ngOnInit() {}
}
