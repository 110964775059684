import { Component, OnInit, Input } from '@angular/core';
import { ListItem } from '../list-view-item/list-view-item.component';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit {
  @Input() items: ListItem[];

  constructor() {}

  ngOnInit() {}
}
