import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiagramaFluxoService {

  constructor(private http: HttpClient) { }

  getDiagramData(client, steps, start_date, end_date): Observable<any> {
    return this.http.get<any>(`${environment.configApiUrlCaptive}/flow/get_count_by_client_consolidated/${client}/${steps}/${start_date}/${end_date}`)
  }

  getTotalAreas(client, start_date, end_date): Observable<any> {
    return this.http.get<any>(`${environment.configApiUrlCaptive}/flow/total-steps-mac-count-consolidated/tsloja/${start_date}/${end_date}`)
  }

  getFlowData(controllerId, start_date, end_date): Observable<any>{
    return this.http.get<any>(`${environment.configApiUrlCaptiveStaging}/flow/total-steps-mac-count-consolidated/tsloja/${start_date}/${end_date}`)
  }
  
  getFlowDataByPeriod(controllerId, steps, start_date, end_date): Observable<any>{
    return this.http.get<any>(`${environment.configApiUrlCaptiveStaging}/flow/get_count_by_client_consolidated/${controllerId}/${steps}/${start_date}/${end_date}`)
  }

  getFlowAllDay(client): Observable<any>{
    return this.http.get<any>(`${environment.configApiUrlCaptiveStaging}/flow/total-steps-mac-count/${client}`)
  }
}