import { Component, OnInit, Input, TemplateRef } from '@angular/core';

export interface Tab {
  id: string;
  title: TemplateRef<any>;
  content: TemplateRef<any>;
}

export interface TabsOptions {
  defaultTabId?: string;
  tabs: Array<Tab>;
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @Input() tabsOpts: TabsOptions;
  constructor() {}

  ngOnInit() {}
}
