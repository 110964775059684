<div ngbDropdown class="nav-item pointer">
  <div (click)="openDrop()">
    <div ngbDropdownToggle class="user-badge bg-primary">
      <div class=" row no-gutters align-items-center justify-content-center">
        <div class="col align-self-center">
          <div *ngIf="loading" class="spinner-border text-white loading-spinner">
            <span class="sr-only">Loading...</span>
          </div>
          <h2 *ngIf="!loading" class="text-white text-capitalize text text-center"><img src="assets/icons/menu.svg" class="menu_align" height="25" width="16"
            /></h2>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="show" ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu-right shadow">
    <div class="text-center mt-3 mb-3">
      <button type="button" class="btn btn-light" (click)="goToUsers()"> Usuários </button>
    </div>
    <div class="text-center mt-3 mb-3" *ngIf="root">
      <button type="button" class="btn btn-light" (click)="goToOrganizations()"> {{title}} </button>
    </div>
    <div class="text-center mt-3 mb-3" *ngIf="root">
      <button type="button" class="btn btn-light" (click)="goToBulkConfigs()"> Configurações em massa </button>
    </div>
    <div class="text-center mt-3 mb-3">
      <button type="button" class="btn btn-primary" (click)="logout()">Sair</button>
    </div>
  </div>
</div>
