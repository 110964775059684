<app-header [desktop]="sharedService.desktop"></app-header>

<div class="d-flex" style="height: 100vh;">
    <div *ngIf="sharedService.desktop" style="height: 100%;" (click)="onSidebarClick()" class="border-right sidebar">
      <app-sidebar [collapsed]="collapsedSidebar"></app-sidebar>
    </div>

  <div class="p-2 mt-2" style="width: 100%; height: 90%;">
    
    <app-breadcrumbs></app-breadcrumbs>
    <router-outlet></router-outlet>
  </div>
</div>


<!-- <div style="width: 100%;">
  <div class="d-flex" style="width: 100%;">
    <div style="display: inline;" style="width: 250px;">
      <div
        (click)="onSidebarClick()"
        
        class="border-right sidebar"
        >
        <app-sidebar [collapsed]="collapsedSidebar"></app-sidebar>
      </div>
    </div>


    <div style="display: block;">
      <app-header></app-header>
          <app-breadcrumbs></app-breadcrumbs>
          <router-outlet></router-outlet>
    </div>
  </div>
  

</div> -->
