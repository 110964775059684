import { Component, OnInit } from '@angular/core';
import { CardOptions } from 'src/app/shared/card/card.component';

@Component({
  selector: 'app-history-global-actions-component',
  templateUrl: './history-global-actions.component.html',
  styleUrls: ['./history-global-actions.component.scss']
})
export class HistoryGlobalActionsComponent implements OnInit {
  cardOpts: CardOptions;
  show : boolean;
  ngOnInit(): void {
    this.setTemplateOptions();
    this.show = false
  }

  showHide(){
    if(this.show){
      this.show = false;
    }else{
      this.show = true;
    }
  }

  setTemplateOptions() {
    this.cardOpts = {
      iconSrc: 'assets/icons/vagalume-status-selected.svg',
      title: 'Histórico', // TODO: Get title from router.,
      hasToggler: true,
      hasActions: true
    };
  }
}
