import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Task, AppError } from '../data/api/task.model';
import { AP, ApQuery } from '../data/models/ap.model';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ClientService {
  constructor(private http: HttpClient, private authenticationService: AuthenticationService, private router: Router) {}

  async getClients(params?): Promise<any> {
    try {
      let headers = new HttpHeaders();
      let currentUser = this.authenticationService.currentUserValue;
      headers.set('Authentication', `Bearer ${currentUser.token}`);
      let httpOptions = {
        headers: headers
      };

      return currentUser;
      // if (currentUser.user.group && currentUser.user.group.id) {
      //   var response = await this.http
      //     .get(`${environment.configApiUrl}/groups_client/${currentUser.user.group.id}`, httpOptions)
      //     .toPromise();
      // } else {
      //   var response = await this.http
      //     .get(
      //       `${environment.configApiUrl}/clients/?filter={"where":{"controller_id":"${currentUser.user.client.controller_id}"},"include":[{"relation":"openwisp"}],"fields":{"controller_id":1,"id":1,"name":1}}`,
      //       httpOptions
      //     )
      //     .toPromise();
      // }
      
      // return response;
    } catch (e) {
      //return Task.error(new AppError());
    }
  }
  setClient(client) {
    // this.router.navigate(['/home'], { skipLocationChange: true }).then(() => {
    //   this.router.navigate(['/dashboard']);
    // });

    location.reload();
  }

  getClientsByGroupId(group_id): Observable<any>{
    return this.http.get<any>(`${environment.configApiUrlCaptive}` + '/clients?filter={"where":{"group.id":{"in":["' +
    group_id +
    '"]}},' +
    '"order":"name ASC"' +
    "}")
  }

  public get currentClient() {
    if (localStorage.getItem('currentClient') && localStorage.getItem('currentClient') != 'undefined') {
      return JSON.parse(localStorage.getItem('currentClient'));
    }
  }
  public get currentGroupClients() {
    if (localStorage.getItem('currentGroupClients') && localStorage.getItem('currentGroupClients') != 'undefined') {
      return JSON.parse(localStorage.getItem('currentGroupClients'));
    }
  }

  async getAp(apId): Promise<Task<AP>> {
    try {
      const response = await this.http.get(`${environment.apiUrl}/aps/${apId}`).toPromise();
      return Task.success(response);
    } catch (e) {
      return Task.error(new AppError());
    }
  }
}
