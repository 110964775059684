import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicoRoutingModule } from './publico-routing.module';
import { PublicoComponent } from './publico.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';


@NgModule({
  declarations: [PublicoComponent],
  imports: [
    CommonModule,
    PublicoRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot(),
  ]
})
export class PublicoModule { }
