<div class="input-group mb-3">
  <input
    id="typeahead-focus"
    type="text"
    class="form-control"
    [placeholder]="placeholder"
    [ngbTypeahead]="search"
    #instance="ngbTypeahead"
    aria-describedby="basic-addon2"
  />
  <div class="input-group-append">
    <span class="input-group-text" [ngClass]="!disable ? 'bg-primary' : 'bg-light'" id="basic-addon2"
      ><img src="assets/icons/magnifier.svg" width="16"
    /></span>
  </div>
</div>
