<div class="container-fluid">
    <form [formGroup]="form">
        <div class="d-flex">
            <div class="d-flex mt-2">
                <select formControlName="selectReport" class="form-control mb-2" style="width: 350px; font-size: 14px;" (change)="getClients()">
                    <option value="" disabled>Selecione o relatório</option>
                    <option value="dataWlanNetOut">Trafego de saída na interface</option>
                    <option value="dataWlanNetIn">Trafego de entrada na interface</option>
                    <option value="getDataUsage">Estações associadas na Interface por AP</option>
                    <option value="getDataUsageByTotalGroup">Total de Estações Associadas</option>
                    <option value="getDataUsageSsid">Estações associadas no SSID por ap</option>
                    <option value="getDatNetOutGroupBySsid">Trafego de saída por SSID</option>
                    <option value="getDataNetinGroupBySsid">Trafego de entrada por SSID</option>
                    <option value="getDataUsageByHostname">Estações Associadas por AP</option>
                </select>
    
                <ng-container *ngIf="form.controls['selectReport'].value != ''">
                    <i class="fas fa-arrow-right ml-2 mr-2" style="margin-top: 11px;"></i>

                    <ng-container *ngIf="filterOn && !showOnlyGroup">
                        <select formControlName="selectClient" class="form-control mb-2" style="width: 200px; font-size: 14px">
                            <option value="" disabled>Selecione o cliente</option>
                            <option *ngFor="let col of clients;" value="{{col.groupZabbix.name}}">{{col.groupZabbix.name}}</option>
                        </select>
                    </ng-container>

                    <ng-container *ngIf="!filterOn && !showOnlyGroup && !showHostNames">
                        <select formControlName="selectClient" class="form-control mb-2" style="width: 200px; font-size: 14px" (change)="getInterfacesSsid()">
                            <option value="" disabled>Selecione o cliente</option>
                            <option *ngFor="let col of clients;" value="{{col.groupZabbix.name}}">{{col.groupZabbix.name}}</option>
                        </select>
                    </ng-container>

                    <ng-container *ngIf="showOnlyGroup">
                        <select formControlName="selectClient" class="form-control mb-2" style="width: 200px; font-size: 14px" (change)="getSelectedReport()">
                            <option value="" disabled>Selecione o cliente</option>
                            <option *ngFor="let col of clients;" value="{{col.groupZabbix.name}}">{{col.groupZabbix.name}}</option>
                        </select>
                    </ng-container>

                    <ng-container *ngIf="showHostNames">
                        <select formControlName="selectClient" class="form-control mb-2" style="width: 200px; font-size: 14px" (change)="getInterfacesSsid()">
                            <option value="" disabled>Selecione o cliente</option>
                            <option *ngFor="let col of clients;" value="{{col.groupZabbix.name}}">{{col.groupZabbix.name}}</option>
                        </select>
                    </ng-container>
                </ng-container>
                
                <ng-container *ngIf="filterOn && form.controls['selectClient'].value != ''">
                    <i class="fas fa-arrow-right ml-2 mr-2" style="margin-top: 11px;"></i>

                    <select class="form-control mb-2" style="width: 200px; font-size: 14px" formControlName="selectFilter" (change)="getInterfacesSsid()">
                        <option value="" disabled>Selecione o filtro</option>
                        <option value="ap">AP</option>
                        <option value="swarm">Swarm</option>
                    </select>
                </ng-container>
    
                <ng-container *ngIf="filterOn && form.controls['selectFilter'].value != '' || !filterOn && form.controls['selectClient'].value != '' || showHostNames && form.controls['selectClient'].value != ''">
                    <ng-container *ngIf="!showOnlyGroup || showHostNames">
                        <i class="fas fa-arrow-right ml-2 mr-2" style="margin-top: 11px;"></i>

                        <ng-container *ngIf="!showHostNames">
                            <select [hidden]="loadingInterfacesSsid" formControlName="selectInterfaceSsid" class="form-control mb-2" style="width: 200px; font-size: 14px;" (change)="getSelectedReport()">
                                <option *ngIf="!ssidOn && !showHostNames" value="" disabled>Selecione a interface</option>
                                <option *ngIf="ssidOn" value="" disabled>Selecione o SSID</option>
                                <option *ngIf="showHostNames" value="" disabled>Selecione o hostname</option>
                                <option *ngFor="let col of interfacesSsid" value="{{col}}">{{col}}</option>
                            </select>
                        </ng-container >
                        
                        <ng-container *ngIf="showHostNames">
                            <select [hidden]="loadingHostname" formControlName="selectInterfaceSsid" class="form-control mb-2" style="width: 200px; font-size: 14px;" (change)="getSelectedReport()">
                                <option *ngIf="!ssidOn && !showHostNames" value="" disabled>Selecione a interface</option>
                                <option *ngIf="ssidOn" value="" disabled>Selecione o SSID</option>
                                <option *ngIf="showHostNames" value="" disabled>Selecione o hostname</option>
                                <option *ngFor="let col of hostnamesList" value="{{col.hostid}}">{{col.name}}</option>
                            </select>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
            <div class="ml-auto dateInputs">
                <form [formGroup]="formPeriod">
                    <div class="d-flex">
                        <div style="font-size: 12px;">
                            <label class=" mb-0">Data Inicial</label>
                            <input style="font-size: 12px;" formControlName="initialDate" type="date" class="form-control" (change)="changeDate(1)">
                        </div>
                        <div class="ml-2" style="font-size: 12px;">
                            <label class=" mb-0">Data Final</label>
                            <input style="font-size: 12px;" formControlName="endDate" type="date" class="form-control" (change)="changeDate(2)">
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </form>
    
    <div class="card mt-2">
        <div class="card-header" *ngIf="zabbixReport">
            <div class="d-flex">
                <div>{{getReportInfo(reportType).name}}</div>
                <div class="ml-auto">
                    <i *ngIf="!maxValueOn" class="fas fa-filter" style="cursor: pointer;" (click)="maxValueOn = true"></i>
                    <div *ngIf="maxValueOn" class="d-flex">
                        <input #maxYaxis  class="form-control" style="font-size: 12px; width: 315px;" mask="separator" thousandSeparator="." (keyup.enter)="onSelected()" placeholder="Selecione o máximo eixo Y em bps e tecle enter">
                    </div>
                </div>
            </div>
            
        </div>
        <div class="card-body">
            <ng-container *ngIf="zabbixReport else selectReport">
                <apx-chart *ngIf="!loadingZabbixReport else loadingReport"
                      [series]="chartOptions.series"
                      [chart]="chartOptions.chart"
                      [xaxis]="chartOptions.xaxis"
                      [yaxis]="chartOptions.yaxis"
                      [dataLabels]="chartOptions.dataLabels"
                      [grid]="chartOptions.grid"
                      [stroke]="chartOptions.stroke"
                      [title]="chartOptions.title"
                    ></apx-chart>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #selectReport>
    <div class="text-center">
        Selecione um relatório.
    </div>
</ng-template>

<ng-template #loadingReport>
    <div class="text-center">
        <i class="fas fa-spinner fa-spin text-primary" style="width: 20px;"></i>
    </div>
</ng-template>

