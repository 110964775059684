import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'interfaceMode' })
export class InterfaceModePipe implements PipeTransform {
  transform(value: string): string {
    if (value === undefined) {
      return null;
    } else {
      switch (value) {
        case 'access_point':
          return 'Access Point';
        case 'monitor':
          return 'Monitor';
        default:
          break;
      }
    }
  }
}
