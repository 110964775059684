import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModalChangeClientsService {

  constructor(private http: HttpClient) { }

  // getGroups(): Observable<any>{
  //   return this.http.get<any>(`${environment.configApiUrlCaptiveStaging}/groups`);
  // }

  getFatherGroup(): Observable<any>{
    return this.http.get<any>(`${environment.configApiUrl}/groups/findFatherGroup`);
  }
  
  getGroups(fatherId: string): Observable<any>{
    return this.http.get<any>(`${environment.configApiUrl}/groups/findGroupByFather/${fatherId}`);
  }

  getClients(groupId: string): Observable<any>{
    return this.http.get<any>(`${environment.configApiUrl}/clients/getClientByGroupFather/${groupId}`);
  }

  getGroupId(clientId): Observable<any>{
    return this.http.get<any>(`${environment.configApiUrlCaptiveStaging}/clients?filter={"fields":["group"],"where":{"id":"${clientId}"}}`)
  }
  
  getClientsMaceio(): Observable<any>{
    return this.http.get<any>(`${environment.configApiUrlCaptiveStaging}/clients/5c0575c8257d3d14ed22de77`)
  }

  getClientsByFatherId(groupId, text): Observable<any>{
    return this.http.get<any>(`${environment.configApiUrl}/clients/getClientByGroupFather/${groupId}/${text}`)
  }

  getClientsByControllerId(controller_id): Observable<any>{
    return this.http.get<any>(`${environment.configApiUrl}/clients/getClientByClient/${controller_id}`)
  }

  // getClients(clientId): Observable<any>{
    // return this.http.get<any>(`${environment.configApiUrlCaptiveStaging}/clients?filter={"fields":["group"],"where":{"id":${clientId}}`)
  // }
}
