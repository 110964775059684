export class Task<T = any> {
  private constructor(private status: TaskStatus, private _error: AppError, private _data: T) {}

  public static idle<T = any>() {
    return new Task(TaskStatus.IDLE, null, null);
  }

  public static inProgress<T = any>(): Task<T> {
    return new Task(TaskStatus.IN_PROGRESS, null, null);
  }

  public static success<T = any>(data: T): Task<T> {
    return new Task<T>(TaskStatus.SUCCESS, null, data);
  }

  public static error<T = any>(error: AppError): Task<T> {
    return new Task(TaskStatus.FAILED, error, null);
  }

  get data() {
    return this._data;
  }

  get error() {
    return this._error;
  }

  get isInProgress() {
    return this.status === TaskStatus.IN_PROGRESS;
  }

  get isSuccessful() {
    return this.status === TaskStatus.SUCCESS;
  }

  get isFailure() {
    return this.status === TaskStatus.FAILED;
  }
}

enum TaskStatus {
  IDLE,
  IN_PROGRESS,
  SUCCESS,
  FAILED
}

export class AppError {}
