import { Component, OnInit, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ListItem } from '../list-view-item/list-view-item.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ModalChangeClientsService } from 'src/app/services/modal-change-clients.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() desktop: boolean;

  groupsByClient: any[] = [];

  clientName = localStorage.getItem('clientName');
  // swarm = JSON.parse(localStorage.getItem('currentClient')).swarm;
  user = JSON.parse(localStorage.getItem('currentUser')).user;
  userText =
    'group' in JSON.parse(localStorage.getItem('currentUser')).user
      ? JSON.parse(localStorage.getItem('currentUser')).user.group.text
      : '';

  currentRoute = this.router.url.split('/')[1];
  groupsFather: any;

  form: FormGroup;

  /*
  collapsed = true;

  captivePortalNotificationsList: ListItem[] = [
    {
      icon: 'assets/icons/atom.svg',
      description: 'Lorem ipsum',
      subDescription: '14 de Julho, 15:30',
      cssClass: 'notifications-on-toolbar'
    }
  ];
  serviceStatusNotificationsList: ListItem[] = [];
  networkNotificationsList: ListItem[] = [];

  
  */
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalChangeClientsService: ModalChangeClientsService,
    private formBuilder: FormBuilder,
    public client: ClientService
  ) {}
  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = this.router.url.split('/')[1];
      }
    });

    this.initializeForm();
    this.getGroups();
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      groupsFather: [''],
      organization: ['']
    });
  }

  getClientData() {
    let client = this.groupsByClient.filter(obj => obj.id == this.form.controls['organization'].value);

    if (client.length > 0) {
      localStorage.setItem('currentClient', JSON.stringify(client[0]));
      localStorage.setItem('clientName', client[0].name);

      // if (client[0].main_controller_id == 'tacaruna'){
      //   this.validateMenuService.validateDiagram = true;
      // } else {
      //   this.validateMenuService.validateDiagram = false;
      // }

      if (client[0].vagamais) {
        localStorage.setItem('vagamais', 'true');
      } else {
        localStorage.setItem('vagamais', 'false');
      }

      this.client.setClient(1);
    } else {
      this.client.setClient(1);
    }
  }

  getGroups() {
    this.modalChangeClientsService.getFatherGroup().subscribe(data => {
      if ('group' in this.user) {
        if (this.user.group.text == 'ROOT') {
          this.groupsFather = data.filter(obj => obj.father == 'ROOT' || obj.father == '');
          this.getOrganizationByFatherId(this.groupsFather[0].id, this.groupsFather[0].text);
        } else {
          this.groupsFather = data.filter(obj => obj.text == this.user.group.text);
          this.getOrganizationByFatherId(this.groupsFather[0].id, this.groupsFather[0].text);
        }
      }
    });
  }

  getOrganizationByFatherId(fatherId, text) {
    this.groupsByClient = [];
    this.modalChangeClientsService.getClientsByFatherId(fatherId, text).subscribe(data => {
      if (data) {
        this.groupsByClient = data;
      }
    });
  }

  goToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  goToVagalumeStatus() {
    this.router.navigate(['/vagalume-status']);
  }

  redirect(tipo) {
    if (tipo == 1) {
      this.router.navigate(['/videos']);
    } else if (tipo == 2) {
      this.router.navigate(['/perguntas']);
    } else if (tipo == 3) {
      this.router.navigate(['/sms']);
    } else if (tipo == 4) {
      this.router.navigate(['/emails']);
    } else if (tipo == 5) {
      this.router.navigate(['/radios']);
    } else if (tipo == 6) {
      this.router.navigate(['/voucher']);
    } else if (tipo == 7) {
      this.router.navigate(['/maps']);
    } else if (tipo == 8) {
      this.router.navigate(['/diagrama-fluxo']);
    } else if (tipo == 9) {
      this.router.navigate(['/publico']);
    } else if (tipo == 10) {
      this.router.navigate(['/acessos']);
    } else if (tipo == 11) {
      this.router.navigate(['/recurrence']);
    } else if (tipo == 12) {
      this.router.navigate(['/relatorios']);
    } else if (tipo == 13) {
      this.router.navigate(['/user']);
    }
  }

  getGroupsByClient(groupsByClient) {
    this.groupsByClient = groupsByClient;
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
