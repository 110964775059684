<div class="modal-header">
    <h6 class="modal-title mb-0">{{ title }}</h6>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    {{ message }}
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-danger" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-sm btn-primary" (click)="accept()">{{ btnOkText }}</button>
</div>