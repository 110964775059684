import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

export interface TogglerOptions {
  icons?: {
    left: {
      selected: string;
      notSelected: string;
    };
    right: {
      selected: string;
      notSelected: string;
    };
  };
  titles: {
    left: string;
    right: string;
  };
}

@Component({
  selector: 'app-toggler',
  templateUrl: './toggler.component.html',
  styleUrls: ['./toggler.component.scss']
})
export class TogglerComponent implements OnInit {
  public radioGroupForm: FormGroup;
  @Input() togglerOpts: TogglerOptions;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.radioGroupForm = this.formBuilder.group({
      model: 'left'
    });
  }
}
