import { Component, OnInit, TemplateRef, Input } from '@angular/core';

export interface ListItem {
  icon: string;
  description: string;
  subDescription: string;
  content?: string;
  cssClass?: string;
  actionsColumn?: boolean;
}

@Component({
  selector: 'app-list-view-item',
  templateUrl: './list-view-item.component.html',
  styleUrls: ['./list-view-item.component.scss']
})
export class ListViewItemComponent implements OnInit {
  @Input() listItem: ListItem;
  isCollapsed = true;

  constructor() {}

  ngOnInit() {}
}
