<div class="container login-centered">
  <div class="row">
    <div class="col align-self-center">
      <div class="col-md-5 mx-auto">
        <div class="card">
          <h4 class="card-header text-center py-4">
            <img class="logo" src="assets/images/vagalume.png" />
          </h4>
          <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="loginService.onSubmit(f.username.value, f.password.value)">
              <div class="form-group">
                <label for="username">Username</label>
                <input
                autofocus
                required
                  type="text"
                  formControlName="username"
                  class="form-control"
                  [ngClass]="{ 'is-invalid':f.username.touched && f.username.errors }"
                />
                <div *ngIf="f.username.invalid && f.username.errors" class="invalid-feedback">
                  <div *ngIf="f.username.errors.required">Username is required</div>
                </div>
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input
                  type="password"
                  required
                  formControlName="password"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': f.password.touched && f.password.errors }"
                />
                <div *ngIf="f.password.invalid && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
              </div>
              <button
                [disabled]="
                  (f.password.errors) ||
                  (f.username.errors) ||
                  loginService.loginTask.isInProgress
                "
                class="btn btn-primary"
              >
                <span
                  *ngIf="loginService.loginTask.isInProgress"
                  class="spinner-border spinner-border-sm mr-1 mb-1"
                ></span>
                Login
              </button>
              <div *ngIf="loginService.loginTask.isFailure" class="alert alert-danger mt-3 mb-0">
                Invalid user or password
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
