<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="exampleModalLabel">Adicionar Cliente</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="cancelarModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-4">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Geral</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Profile</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Contact</a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active p-2 mt-2" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <form [formGroup]="formulario">
                        <div class="row">
                            <div class="col-md-4">
                                <label style="font-size: 14px;" for="name">Nome do cliente:</label>
                                <input class="form-control" type="text" id="name" formControlName="name">
                            </div>

                            <div class="col-md-4">
                                <label style="font-size: 14px;" for="id">ID do cliente:</label>
                                <input class="form-control" type="text" id="id" formControlName="id">
                            </div>

                            <div class="col-md-4">
                                <label style="font-size: 14px;" for="group">Grupo:</label>
                                <select class="form-control" id="group" formControlName="group">
                                <option value="group1">Grupo 1</option>
                                <option value="group2">Grupo 2</option>
                                <option value="group3">Grupo 3</option>
                                </select>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <label style="font-size: 14px;" for="url">URL:</label>
                                <input class="form-control" type="text" id="url" formControlName="url">
                            </div>

                            <div class="col-md-4">
                                <label style="font-size: 14px;" for="email">Email:</label>
                                <input class="form-control" type="text" id="email" formControlName="email">
                            </div>

                            <div class="col-md-4 mt-4">
                                <div class="col-md-6">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="detectUser" formControlName="detectUser">
                                        <label style="font-size: 14px; width: 150px;" class="custom-control-label mr-2 mb-2" for="detectUser">Detectar usuário</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="nps" formControlName="nps">
                                        <label style="font-size: 14px; width: 150px;" class="custom-control-label mr-2" for="detectUser">Pedir feedback NPS</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </form>
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...</div>
                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
              </div>
            
        </div>
        
      </div>
    </div>
  </div>