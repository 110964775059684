import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-cliente',
  templateUrl: './add-cliente.component.html',
  styleUrls: ['./add-cliente.component.scss']
})
export class AddClienteComponent implements OnInit {

  @Input() contModal: number = 0;

  formulario: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.contModal > 0){
      
    }
  }

  inicializarFormulario(){
    this.formulario = this.formBuilder.group({
      name: [''],
      id: [''],
      group: [''],
      detectUser: [false],
      nps: [false],
      url: [''],
      email: ['']
    });
  }

}
