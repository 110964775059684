import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dBm' })
export class DecibelMiliWattPipe implements PipeTransform {
  transform(value) {
    if (value == 20) {
      return '20 dBm';
    } else {
      return value === undefined || value === null ? null : `${value} dBm`;
    }
  }
}
