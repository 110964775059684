<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document" style="border-radius: 20px;">
    <div class="modal-content" style="border-radius: 20px;">
      <div class="modal-header">
        <h6 class="modal-title" id="exampleModalLabel">e</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="form">
          <ng-container *ngIf="user.group != undefined && this.user.group.text != ''">
            <select class="form-control" formControlName="groupsFather" (change)="getOrganization()">
              <option value="" disabled>Selecione um grupo</option>
              <option *ngFor="let col of groupsFather" value="{{ col.id }}">{{ col.text }}</option>
            </select>
          </ng-container>

          <ng-container *ngIf="groupsFather != '' || isClient == true">
            <select class="form-control mt-2" formControlName="organization">
              <option value="" disabled>Selecione o cliente</option>
              <option *ngFor="let col of groups" value="{{ col.id }}">{{ col.name }}</option>
            </select>
          </ng-container>

          <!-- <ng-container *ngIf="(user.group != undefined && this.user.group.text == 'ROOT' && form.controls['organization'].value !== '') || (user.group != undefined && this.user.group.text == '') || (user.group == undefined)">
              <ng-container>
                <select class="form-control mt-2" formControlName="clients" >
                  <option value="" disabled>Selecione um cliente</option>
                  <option *ngFor="let col of clients" value="{{col.id}}">{{col.radio_prefix}}</option>
                </select>
              </ng-container>
            </ng-container> -->
        </form>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
        <button
          *ngIf="form.controls['organization'].value !== ''"
          type="button"
          class="btn btn-primary"
          style="width: 100%;"
          (click)="getClientData()"
        >
          Confirmar
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #carregandoClientes>
  <div class="text-center">
    <i class="fas fa-spinner fa-spin text-primary mt-4" style="font-size: 20px;"></i>
  </div>
</ng-template>
