import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RelatoriosZabbixRoutingModule } from './relatorios-zabbix-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RelatoriosZabbixComponent } from './relatorios-zabbix.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    RelatoriosZabbixComponent
  ],
  imports: [
    CommonModule,
    RelatoriosZabbixRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    NgxMaskModule.forRoot(),
  ]
})
export class RelatoriosZabbixModule { }
