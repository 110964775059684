<div class="container-fluid">
    <div class="dateInputs">
        <form [formGroup]="form">
            <div class="d-flex flex-row-reverse">

                <div class="ml-2">
                    <label style="font-size: 12px;" class="mb-0">Data Final</label>
                    <input style="font-size: 12px;" formControlName="endDate" type="date" class="form-control"
                        (change)="changeDate()">
                </div>
                <div>
                    <label style="font-size: 12px;" class=" mb-0">Data Inicial</label>
                    <input style="font-size: 12px;" formControlName="initialDate" type="date" class="form-control"
                        (change)="changeDate()">
                </div>
                <div class="ml-2" *ngIf="sharedService.desktop && leadsOn">
                    <button style="margin-top: 25px; margin-right: 10px;" class="btn btn-sm btn-primary"
                        (click)="downloadCsv()">Exportar Leads
                    </button>
                </div>
            </div>
        </form>
    </div>

    <div class="text-right" *ngIf="!sharedService.desktop">
        <button style="margin-top: 25px; margin-right: 10px;" class="btn btn-sm btn-primary"
            (click)="downloadCsv()">Exportar Leads</button>
    </div>

    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" (click)="leadsOn = false">
            <a style="color: gray;" class="nav-link active" id="publico-tab" data-toggle="tab" href="#publico"
                role="tab" aria-controls="home" aria-selected="true">Público</a>
        </li>
        <li class="nav-item" (click)="leadsOn = true">
            <a style="color: gray;" class="nav-link" id="leads-tab" data-toggle="tab" href="#leads" role="tab"
                aria-controls="profile" aria-selected="false">Leads</a>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="publico" role="tabpanel" aria-labelledby="publico-tab">
            <div [ngStyle]="!sharedService.desktop && {'height': '50vh'} || sharedService.desktop && {'height': '85vh'}"
                style="overflow-y: auto; overflow-x: hidden;">
                <div class="row mt-2">
                    <div class="col-md-8">
                        <div class="card mb-2" style="height: 30vh;">
                            <div class="card-header"
                                style="background-color: white; font-size: 14px; font-weight: 500;">
                                Faixa Etária
                            </div>
                            <div class="card-body">
                                <ng-container *ngIf="!loading else carregando">
                                    <ng-container *ngIf="hasBirth_date else noData">
                                        <canvas *ngIf="validateAgeGroupData else noDataAvailable" baseChart
                                            [datasets]="ageGroupData" [labels]="ageGroupLabels"
                                            [options]="ageGroupOptions" legend="true" chartType="bar" width="100%"
                                            height="20">
                                        </canvas>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card" style="height: 30vh;">
                            <div class="card-header"
                                style="background-color: white; font-size: 14px; font-weight: 500;">
                                Gênero
                            </div>
                            <div class="card-body" style="font-size: 12px; overflow: auto;">
                                <ng-container *ngIf="!loading else carregando">
                                    <ng-container *ngIf="hasGender else noData">
                                        <div class="d-flex justify-content-center">
                                            <canvas baseChart *ngIf="genderData.length > 0 else noDataAvailable"
                                                [data]="genderData" [labels]="genderLabels" [options]="genderOptions"
                                                legend="true" chartType="pie">
                                            </canvas>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="card" style="margin-top: 20px; height: 40vh;">
                            <div class="card-header"
                                style="background-color: white; font-size: 14px; font-weight: 500;">
                                Faixa Etária e Gênero
                            </div>
                            <div class="card-body">
                                <ng-container *ngIf="!loading else carregando">
                                    <canvas *ngIf="validateAgeGroupGenderData else noDataAvailable" baseChart
                                        [datasets]="ageGroupGender" [labels]="ageGroupGenderLabels"
                                        [options]="ageGroupOptions" legend="true" chartType="bar" width="100"
                                        height="15">
                                    </canvas>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="leads" role="tabpanel" aria-labelledby="leads-tab">
            <ng-container *ngIf="!loadingLeads else carregando">
                <ng-container *ngIf="leads.length > 0 else noDataAvailable">
                    <div style="max-height: 70vh; overflow: auto;">
                        <table class="table table-md">
                            <thead>
                                <tr>
                                    <ng-container *ngFor="let key of keys">
                                        <th>
                                            {{key}}
                                        </th>
                                    </ng-container>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    *ngFor="let lead of leads | paginate: { itemsPerPage: 10, currentPage: p }; let i = index;">
                                    <ng-container *ngFor="let key of keys">
                                        <ng-container *ngIf="key == 'cpf'">
                                            <td>
                                                {{lead[key] | mask:'000.000.000-00'}}
                                            </td>
                                        </ng-container>

                                        <ng-container *ngIf="key != 'cpf'">
                                            <td>
                                                {{lead[key]}}
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="card-footer text-right pt-4" style="background-color: white;">
                        <pagination-controls previousLabel="Anterior" nextLabel="Próxima"
                            (pageChange)="p = $event"></pagination-controls>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #carregando>
    <div class="text-center">
        <i class="fas fa-spinner fa-spin text-primary mt-4" style="font-size: 20px;"></i>
    </div>

</ng-template>

<ng-template #noData>
    <div class="text-center mt-2 ml-4 mr-4" style="font-size: 12px;">
        O {{client}} não coleta dados para esse gráfico.
    </div>
</ng-template>

<ng-template #noDataAvailable>
    <div class="text-center mt-4 ml-4 mr-4" style="font-size: 12px;">
        Sem dados disponíveis.
    </div>
</ng-template>