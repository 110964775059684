import { Component, Input, OnInit } from '@angular/core';
import { Map, Control, DomUtil, ControlPosition, LatLng } from 'leaflet';

@Component({
  selector: 'app-custom-control',
  templateUrl: './custom-control.component.html',
  styleUrls: ['./custom-control.component.scss']
})
export class CustomControlComponent implements OnInit {

  private _map: Map;

  @Input() floors = [];
  @Input() position: ControlPosition;
  custom: Control;

  @Input() set map(map: Map) {
    if (map) {
      this._map = map;
      let Custom = Control.extend({
        onAdd(map: Map) {
          return DomUtil.get('custom');
        },
        onRemove(map: Map) {},
      });
      // this.custom = new Custom({
      //   position: this.position,
      // }).addTo(map);
      // map.on('click', this.onClick, this);
    }
  }
  

  constructor() { }

  ngOnInit() {
  }

}
