import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { UserExperience } from '../dashboard/shared/userExperience';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  headers: HttpHeaders = new HttpHeaders();
  currentUser = this.authenticationService.currentUserValue;
  headersCaptive: HttpHeaders = new HttpHeaders();

  constructor(private authenticationService: AuthenticationService, private http: HttpClient) {}

  getApStatusHistory(startDate, endDate, groupId): Observable<any> {
    return this.http.get<any>(
      `${environment.configApiUrl}/zabbixReport/getAvailabilitySummariesByGroup/${startDate}/${endDate}/${groupId}`
    );
  }

  getApStatusHistoryByHostId(startDate, endDate, hostId): Observable<any> {
    return this.http.get<any>(
      `${environment.configApiUrl}/zabbixReport/getAvailabilitySummariesByHostid/${startDate}/${endDate}/${hostId}`
    );
  }

  getUserExperienceData(): Observable<UserExperience[]> {
    return this.http.get<UserExperience[]>(
      `${environment.configApiUrl}/ap-network-histories?filter={"where":{"client":"Selfit"}}`
    );
  }

  getUserExperienceDataAllClients(group, startDate, endDate): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.configApiUrl}/ap-network-consolidateds/${group}/${startDate}/${endDate}`
    );
  }

  getSummary(client_id: string, start_date: string, end_date: string): Observable<any> {
    return this.http.get<any>(
      `${environment.configApiUrlCaptiveStaging}/connections/reportByDay/${client_id}/${start_date}/${end_date}`
    );
  }

  getSummaries(client_id: string, start_date: string, end_date: string): Observable<any> {
    return this.http.get<any>(
      `${environment.configApiUrlCaptiveStaging}/summaries/getPeriodSummary/${client_id}/${start_date}/${end_date}`
    );
  }

  getNews(client_id: string, start_date: string, end_date: string): Observable<any> {
    return this.http.get<any>(
      `${environment.configApiUrlCaptiveStaging}/connection_count_2s/reportByDayNew/${client_id}/${start_date}/${end_date}`
    );
  }

  getApsByPeriod(controller_id: string, start_date: string, end_date: string): Observable<any> {
    return this.http.get<any>(
      `${environment.configApiUrlCaptiveStaging}/ap_connecteds/map_data_period/${controller_id}/${start_date}/${end_date}
    `
    );
  }

  getApsConnections(radioPrefix: string, start_date: string, end_date: string): Observable<any> {
    return this.http.get<any>(
      `${environment.configApiUrlCaptiveStaging}/statuses/dayData/${radioPrefix}/${start_date}/${end_date}
    `
    );
  }

  getAuthenticatedByGroup(start_date: string, end_date: string, zabbixGroupId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.configApiUrl}/zabbixReport/authenticatedByGroup/${start_date}/${end_date}/${zabbixGroupId}
    `
    );
  }

  getLeads(controllerId: string, start_date: string, end_date: string): Observable<any> {
    return this.http.get<any>(
      `${environment.configApiUrlCaptiveStaging}/connections/getLeads/${controllerId}/${start_date}/${end_date}`
    );
  }

  getConnections(controller_id, startDate, endDate): Observable<any> {
    return this.http.get<any>(
      `${environment.configApiUrlCaptiveStaging}/externalUsers/userData/${controller_id}/${startDate}/${endDate}`
    );
  }

  getTotalAp(radioPrefix): Observable<any> {
    return this.http.get<any>(
      `${environment.configApiUrlCaptiveStaging}/radios?filter={"where":{"clientname":{"regexp":"${radioPrefix}/gi"}}}`
    );
  }

  getTotalOnline(radioPrefix): Observable<any> {
    return this.http.get<any>(
      `${environment.configApiUrlCaptiveStaging}/status_temps?filter={"where":{"clientname":"${radioPrefix}"},"limit":1,"order":"datetime  DESC"}`
    );
  }

  getTotalDevices(currentHour, radioPrefix): Observable<any> {
    return this.http.get<any>(
      `${environment.configApiUrlCaptiveStaging}/ap_sensors?filter={"where": {"client": "${radioPrefix}", "ts": {"gt": "${currentHour}"}},"order": "ts DESC","limit": 1 }`
    );
  }

  getWeeklyConnections(initialDate, endDate, controller_id): Observable<any> {
    return this.http.get<any>(
      `${environment.configApiUrl}/weekly-connections/month/${initialDate}/${endDate}/${controller_id}`
    );
  }
}
