<div class="ml-2 mr-2">
    <div class="card">
        <div class="card-header" style="background-color: white;">
            Diagrama de fluxo
        </div>

        <div class="pl-3 pr-3 pt-2">
            <form [formGroup]="form">
                <div class="d-flex">
                    <div>
                        <label style="font-size: 12px;" class=" mb-0">Selecione a quantidade de colunas</label><br>
                        <select formControlName="columnSelect" class="form-control" style="font-size: 12px;"
                            (change)="updateColumns($event)">
                            <option value="" disabled selected>Selecione uma opção</option>
                            <option valuer="2">2</option>
                            <option valuer="3">3</option>
                            <option valuer="4">4</option>
                        </select>
                    </div>

                    <div class="d-flex ml-auto">
                        <div class="custom-control custom-checkbox mt-4 ml-2">
                            <input type="checkbox" #allDayInput class="custom-control-input" id="customCheck1"
                                (change)="getAllDay()">
                            <label class="custom-control-label mr-2" for="customCheck1">Dia inteiro</label>
                        </div>
                        <ng-container *ngIf="!validateAllDay">
                            <div>
                                <label style="font-size: 12px;" class=" mb-0">Data Inicial</label>
                                <input style="font-size: 12px;" formControlName="initialDate" type="date"
                                    class="form-control" (change)="changeDate()">
                            </div>
                            <div class="ml-4">
                                <label style="font-size: 12px;" class="mb-0">Data Final</label>
                                <input style="font-size: 12px;" formControlName="endDate" type="date"
                                    class="form-control" (change)="changeDate()">
                            </div>
                        </ng-container>
                    </div>
                </div>
            </form>
        </div>

        <div class="card-body mt-5">
            <ng-container *ngIf="validaGrafico else loading">
                <ng-container *ngIf="data.length > 0 else noData">
                    <google-chart #chart [title]="title" [type]="type" [data]="data" [columnNames]="columnNames"
                        [options]="options" dynamicResize="true">
                    </google-chart>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <ng-template #loading>
        <div class="text-center mb-4">
            Carregando <i class="fas fa-spinner fa-spin text-primary" style="width: 20px;"></i>
        </div>
    </ng-template>

    <ng-template #noData>
        <div class="text-center mb-4">
            Sem dados disponíveis para este período.
        </div>
    </ng-template>